import React from 'react';

import { Text } from '@optra/kit';

import List from './list';
import ProfileOptionToastNotifications from './profile-option-toast-notifications-list-item';

export default function ProfileOptionsSection() {
  return (
    <div>
      <Text variant="label" size="xs" className="mr-2">
        User Preferences
      </Text>
      <List>
        <ProfileOptionToastNotifications />
      </List>
    </div>
  );
}
