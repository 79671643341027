import { last } from 'lodash';

import { PrimaryCTAButton, PageWrapper, SearchField, Dropdown, Icon } from '@optra/kit';

import AdminProvisioningList from 'components/admin-provisioning-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import PinPageButton from 'components/pin-page-button';
import { api, q } from 'config/api';
import modelNames from 'config/model-names';
import { useSearchParams } from 'hooks';
import { useProvisionedDevices } from 'queries';

function ProvisionDeviceButton(props) {
  const { model } = props;
  const qc = q.useQueryClient();
  const createProvisionedDevice = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createProvisionedDevice($form: createProvisionedDeviceForm!) {
          device: createProvisionedDevice(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['provisionedDevices'] });
    },
  });

  const label = modelNames[model];
  const provision = () => {
    if (createProvisionedDevice.isPending) return;

    if (window.confirm(`Provision a new ${label} device?`)) {
      createProvisionedDevice.mutate({
        noCert: true,
        model,
      });
    }
  };

  return (
    <Dropdown.Item
      text={label}
      hoverSubtle
      uppercase={false}
      className="!rounded-md"
      onClick={provision}
      loading={createProvisionedDevice.isPending}
    />
  );
}

function ExportProvisionedDevicesButton() {
  const exportDevices = q.useMutation({
    mutationFn: () =>
      api(
        `mutation exportProvisionedDevices {
          csv: exportProvisionedDevices {
            url
          }
        }`,
      ),
    onSuccess({ csv }) {
      const link = document.createElement('a');
      link.download = last(csv.url.split('/'));
      link.href = csv.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  });

  return (
    <PrimaryCTAButton
      onClick={async () => {
        if (exportDevices.isPending) return;
        exportDevices.mutate();
      }}
      loading={exportDevices.isPending}
      icon="DownloadSimple"
      text="Export"
    />
  );
}

export default function AdminProvisioningPage() {
  const [searchParams, , createSearchParamSetter] = useSearchParams({
    filter: null,
  });
  const { filter } = searchParams;
  const setFilter = createSearchParamSetter('filter');

  const {
    data,
    isLoading,
    isRefetching,
    isFetchedAfterMount,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    error,
  } = useProvisionedDevices({
    filter,
    sort: { direction: 'desc' },
  });

  return (
    <PageWrapper
      icon="GitFork"
      title="Provisioning | Admin"
      heading="Provisioning"
      inline
      loading={isLoading}
      error={error}
      components={{
        actions: (
          <>
            <PinPageButton />
            <ExportProvisionedDevicesButton />
          </>
        ),
        controls: (
          <div className="flex items-center justify-between space-x-2">
            <div className="w-full max-w-sm">
              <SearchField
                searching={isRefetching && !isFetchedAfterMount}
                value={filter?.$search || ''}
                onChange={$search => {
                  setFilter(prev => ({ ...prev, $search }));
                }}
                onClear={() => {
                  setFilter(prev => ({ ...prev, $search: '' }));
                }}
                placeholder="Search Devices…"
              />
            </div>

            <Dropdown divide={false} scrolling placement="BOTTOM_RIGHT">
              <Dropdown.Button>
                <Icon name="GitFork" size="sm" color="gray" className="mr-2" />
                Provision Virtual Device
              </Dropdown.Button>
              <Dropdown.Body>
                <div>
                  {Object.keys(modelNames)
                    .filter(model => model !== 'compute500')
                    ?.map?.(model => (
                      <ProvisionDeviceButton key={model} model={model} />
                    ))}
                </div>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      }}
    >
      <AdminProvisioningList devices={data} />
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={!hasNextPage}
        isLoading={isFetchingNextPage}
      />
    </PageWrapper>
  );
}
