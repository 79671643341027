import { useNavigate } from 'react-router-dom';

import { Avatar, Card, Button, Text } from '@optra/kit';

import { useFeature } from 'components/feature';
import ProfileCreditsSection from 'components/profile-credits-section';
import ProfileDataSection from 'components/profile-data-section';
import ProfileOptionsSection from 'components/profile-options-section';
import ProfileOrganizationSwitcher from 'components/profile-organization-switcher';
import ProfilePinnedPageSection from 'components/profile-pinned-page-section';
import ProfileThemeSection from 'components/profile-theme-section';
import ToggleMFA from 'components/toggle-mfa';
import { useCurrentOrganization, useProfile } from 'queries';

export default function Profile() {
  const navigate = useNavigate();
  const [currentOrganization] = useCurrentOrganization();
  const { data: profile } = useProfile();

  const accessSecretsEnabled = useFeature('accessSecrets');

  const handleLogOut = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      navigate('/logout');
    }
  };

  const currentUser = profile?.currentUser;
  const currentOrgIsPersonal = currentOrganization.id === '$$NONE';

  return (
    <>
      <Card variant="secondary">
        <div className="flex space-x-4">
          <div className="flex-shrink-0">
            <Avatar src={currentUser?.profile?.image} alt={currentUser?.profile?.name} />
          </div>
          <div className="flex-1 flex-shrink-0 space-y-2">
            <div className="flex flex-row items-center justify-between">
              <Text size="xl" className="block">
                {currentUser?.profile?.name}
              </Text>
              <Text size="xs" color="muted" className="block mb-2">
                {currentUser?.profile?.email}
              </Text>
              <ToggleMFA />
            </div>
            <div className="flex flex-row items-center justify-between">
              <ProfileOrganizationSwitcher />
              <div className="flex space-x-2 justify-end">
                <Button variant="tertiary" size="xs" onClick={handleLogOut}>
                  Log Out
                </Button>
                {accessSecretsEnabled && currentOrgIsPersonal && (
                  <Button to="./api-keys" variant="tertiary" size="xs">
                    API Keys
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>

      <ProfileCreditsSection />

      <ProfileDataSection />

      <ProfileOptionsSection />

      <ProfileThemeSection />

      <ProfilePinnedPageSection />
    </>
  );
}
