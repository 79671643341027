import { truncate } from 'lodash';
import { Link } from 'react-router-dom';

import { Badge, Card, Icon, Text, Heading } from '@optra/kit';

import ProSkillBadge from 'components/pro-skill-badge';
import SkillIcon from 'components/skill-icon';

export default function MarketplaceGridItem({ marketplaceSkill }) {
  return (
    <Card as={Link} to={`./${marketplaceSkill?.id}`} className="relative" size="lg">
      <div
        className="absolute top-0 left-0 right-0 h-40 rounded-t-lg overflow-hidden flex flex-col items-center justify-end bg-light-bg-tertiary dark:bg-dark-bg-tertiary"
        style={{
          clipPath: 'polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%)',
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            backgroundColor: marketplaceSkill?.color,
            backgroundImage: `url(${marketplaceSkill?.iconUrl})`,
            backgroundSize: '100%',
            filter: 'blur(50px)',
          }}
        />
      </div>

      <div className="mb-4 w-full flex flex-col items-center justify-center pt-12">
        <SkillIcon
          size="lg"
          color={marketplaceSkill?.color}
          icon={marketplaceSkill?.icon}
          iconUrl={marketplaceSkill?.iconUrl}
          className="mb-3 z-base"
        />

        <div className="absolute top-5 right-5">
          {marketplaceSkill?.purchased === true && (
            <Badge variant="primary">
              <Icon name="Check" size="sm" className="mr-1" /> In Library
            </Badge>
          )}
          {!marketplaceSkill?.purchased &&
            (marketplaceSkill?.latestVersion?.creditsCost > 0 ? (
              <ProSkillBadge />
            ) : (
              <Badge variant={marketplaceSkill?.requiresContact ? 'secondary' : 'inverted'}>
                {marketplaceSkill?.requiresContact ? 'Contact Us' : 'Free'}
              </Badge>
            ))}
        </div>

        <div className="text-center mb-1">
          <Heading>{marketplaceSkill?.name}</Heading>
        </div>

        {marketplaceSkill?.developerProfile?.name && (
          <div className="text-center mb-3">
            <Text size="sm" color="muted">
              by {marketplaceSkill?.developerProfile?.name}
            </Text>
          </div>
        )}

        {marketplaceSkill?.summary && (
          <div className="text-center mb-3">
            <Text size="sm" color="muted">
              {truncate(marketplaceSkill?.summary, { length: 80 })}
            </Text>
          </div>
        )}
      </div>
    </Card>
  );
}
