import { useState } from 'react';

import {
  Card,
  Icon,
  FilterContainer,
  Filter,
  Dropdown,
  Button,
  DROPDOWN_MENU_PLACEMENT,
} from '@optra/kit';

import AdminUpdateChartRetentionPeriod from 'components/admin-update-chart-retention-period';
import Label from 'components/label';
import Modal from 'components/modal';
import { api, q } from 'config/api';

const retentionFilterOptions = {
  '3 months': 90,
  '6 months': 180,
  '9 months': 270,
  '1 year': 365,
  '2 years': 730,
  '3 years': 1095,
  '4 years': 1460,
  '5 years': 1825,
  '6 years': 2190,
  '7 years': 2555,
  '8 years': 2920,
  '9 years': 3285,
  '10 years': 3650,
};

export default function AdminOrganizationRetentionPeriod(props) {
  const [retentionFilter, setRetentionFilter] = useState(null);
  const { organizationId } = props;
  const [isResetComponentVisible, setIsResetComponentVisible] = useState(false);
  const DEFAULT_MAGNETIC_STORE_RETENTION_DAYS = 90;

  const RetentionPeriod = q.useQuery({
    queryKey: ['organization', organizationId, 'chartRetentionPeriod'],
    queryFn: () =>
      api(
        `query chartRetentionPeriod($ownerId: ID!) {
          retentionPeriod: chartRetentionPeriod(ownerId: $ownerId)
        }`,
        { ownerId: organizationId },
      ),
    select: data => data?.retentionPeriod,
  });

  const currentRetentionPeriod = RetentionPeriod.data ?? DEFAULT_MAGNETIC_STORE_RETENTION_DAYS;
  const selectedRetentionPeriod = retentionFilter ?? currentRetentionPeriod;
  const isDestructive = selectedRetentionPeriod < currentRetentionPeriod;

  function handleResetClick() {
    setIsResetComponentVisible(true);
  }

  return (
    <>
      <div className="flex space-x-2 mb-2">
        <div className="flex items-center">
          <Icon name="Database" />
          <Label className="ml-2">Event Data Retention Period</Label>
        </div>
      </div>
      <div>
        <Card variant="secondary" className="mb-4">
          <div className="flex items-center justify-between">
            <Label>Retention Period</Label>
            <FilterContainer>
              <Filter
                variant="plain"
                menu={{
                  text: Object.keys(retentionFilterOptions).find(
                    key => retentionFilterOptions[key] === selectedRetentionPeriod,
                  ),
                  body: Object.entries(retentionFilterOptions).map(([key, value]) => (
                    <Dropdown.Item
                      key={value}
                      text={key}
                      active={value === selectedRetentionPeriod}
                      onClick={() => setRetentionFilter(value)}
                    />
                  )),
                }}
                placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
              />
            </FilterContainer>
          </div>
        </Card>
        <div>
          <Button variant="danger" onClick={handleResetClick} icon="Warning">
            Reset Data
          </Button>
        </div>
      </div>

      {isResetComponentVisible && (
        <Modal onClose={() => setIsResetComponentVisible(false)}>
          <AdminUpdateChartRetentionPeriod
            organizationId={organizationId}
            retentionPeriod={selectedRetentionPeriod}
            isDestructive={isDestructive}
            onClose={() => setIsResetComponentVisible(false)}
          />
        </Modal>
      )}
    </>
  );
}
