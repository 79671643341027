import { Cpu } from '@phosphor-icons/react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDebounce } from 'react-use';

import { PageWrapper, PrimaryCTAButton, SearchField, UiState } from '@optra/kit';

import Feature from 'components/feature';
import IntersectionLoadMore from 'components/intersection-load-more';
import ModelsList from 'components/models/models-list';
import PinPageButton from 'components/pin-page-button';
import SkillsSubNav from 'components/skills-sub-nav';
import { useSearchParams } from 'hooks';
import { useModels } from 'queries';

export default function Models() {
  const [searchParams, , createSearchParamSetter] = useSearchParams({
    search: '',
  });
  const { search } = searchParams;
  const setSearch = createSearchParamSetter('search');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const libraryModels = useModels({
    list: {
      sort: { direction: 'desc' },
      ...(!isEmpty(debouncedSearch) ? { filter: { $search: debouncedSearch } } : {}),
    },
  });
  const models = libraryModels.data;

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  return (
    <PageWrapper
      icon="Cpu"
      title="Models"
      heading="AI Models"
      components={{
        subnav: (
          <Feature feature="skillBuilder">
            <SkillsSubNav active="models" />
          </Feature>
        ),
        actions: (
          <>
            <PinPageButton />
            <PrimaryCTAButton to="/skills/models/create">New Model</PrimaryCTAButton>
          </>
        ),
        controls: (
          <div
            className={cx(
              'flex flex-col items-center',
              'md:flex-row md:items-center md:justify-between space-x-6',
            )}
          >
            <div className="w-full md:w-auto">
              <SearchField
                searching={libraryModels.isRefetching && !libraryModels.isFetchedAfterMount}
                value={search}
                onChange={setSearch}
                placeholder="Search Models…"
                count={models?.length}
              />
            </div>
          </div>
        ),
      }}
      loading={libraryModels.isLoading}
      error={libraryModels.error}
    >
      {models?.length ? (
        <ModelsList
          error={libraryModels.error}
          fetching={libraryModels.isLoading}
          models={models}
        />
      ) : (
        <UiState
          variant="empty"
          icon={{ component: Cpu, colorClass: 'text-primary' }}
          text="No Models"
          className="animate-fade-in"
        />
      )}

      <IntersectionLoadMore
        onVisible={libraryModels.fetchNextPage}
        disabled={libraryModels.isFetchingNextPage || !libraryModels.hasNextPage}
      />
    </PageWrapper>
  );
}
