import { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

export default function useIsVisible() {
  const domNodeRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const intersection = useIntersection(domNodeRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  useEffect(() => {
    if (!isVisible && intersection && intersection.intersectionRatio > 0) {
      setIsVisible(true);
    }
  }, [intersection, isVisible]);

  return [domNodeRef, isVisible];
}
