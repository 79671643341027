import { flatMap } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { Card, Button, SearchField } from '@optra/kit';

import Checkbox from 'components/checkbox';
import IntersectionLoadMore from 'components/intersection-load-more';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Tab from 'components/tab';
import Tabs from 'components/tabs';
import { api, q, useOnSuccess } from 'config/api';
import { useSearchParams } from 'hooks';

export default function AdminMarketplaceEditAccessList(props) {
  const {
    Form: { register, setValue, formState },
    submitting,
    subtitle,
  } = props;

  const { skillId } = useParams();
  const { handleClose } = useModalContext();
  const defaultValuesAreSet = useRef({});

  const [searchParams, , createSearchParamSetter] = useSearchParams({
    search: '',
  });
  const { search } = searchParams;
  const setSearch = createSearchParamSetter('search');

  const [debouncedSearch, setDebouncedSearch] = useState(search);
  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const variables = {
    list: {
      filter: {
        $search: debouncedSearch,
      },
    },
  };

  const {
    isLoading,
    isRefetching,
    isFetchedAfterMount,
    isSuccess,
    error,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = q.useInfiniteQuery({
    queryKey: ['users', 'accessList', skillId, variables],
    queryFn({ pageParam }) {
      return api(
        `query usersAccessList($list: listFields, $originSkillId: ID!) {
          list: userProfiles(list: $list) {
            count
            data {
              id
              email
              auth0UserId
              isSubscribedToMarketplaceSkill(originSkillId: $originSkillId)
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          originSkillId: skillId,
          list: {
            ...variables?.list,
            filter: {
              ...variables?.list?.filter,
              pullMarketplaceSkillOwnerBySkillId: skillId,
            },
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    enabled: !!skillId,
  });

  const users = flatMap(data?.pages, page => page?.list?.data);

  useOnSuccess(
    () => {
      users.forEach(user => {
        const key = btoa(user.auth0UserId);
        if (defaultValuesAreSet.current[key] || formState.touchedFields?.users?.[key]) return;

        setValue(`users.${key}`, user.isSubscribedToMarketplaceSkill);
        defaultValuesAreSet.current[key] = true;
      });
    },
    { isSuccess },
    [formState.touchedFields?.users, setValue, users],
  );

  return (
    <ModalInner>
      <ModalTitle title="Access List" subTitle={subtitle} icon="Cards" loading={isLoading} />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Load Users">
            {error.message}
          </Message>
        )}

        <Tabs>
          <Tab to="../access-list/organizations">Organizations</Tab>
          <Tab to="../access-list/users">Users</Tab>
        </Tabs>

        <SearchField
          searching={isRefetching && !isFetchedAfterMount}
          value={search}
          onChange={setSearch}
          placeholder="Search Users…"
        />

        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            {users?.map?.(user => (
              <Label key={user.id} className="flex-1">
                <Checkbox
                  {...register(`users.${btoa(user.auth0UserId)}`)}
                  className="mr-2"
                  readOnly={submitting}
                  disabled={isEmpty(user.auth0UserId)}
                />
                {user.email}
              </Label>
            ))}
          </div>
          <IntersectionLoadMore
            onVisible={fetchNextPage}
            disabled={isLoading || isFetchingNextPage || !hasNextPage}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button size="xl" onClick={handleClose}>
          Ok
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
