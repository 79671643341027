import { isEmpty, keyBy } from 'lodash';
import { useState } from 'react';

import { Card, Text, FilterContainer, Filter, Dropdown, DROPDOWN_MENU_PLACEMENT } from '@optra/kit';

import ResetChartButton from 'components/charts/reset-chart-button';
import { api, q } from 'config/api';
import { useCurrentOwnerHasTables } from 'queries';
export default function ProfileDataSection(props) {
  const [selectedDataTableId, setSelectedDataTableId] = useState(null);

  const DataTables = q.useQuery({
    queryKey: ['chartDataTables'],
    queryFn: () =>
      api(
        `query chartDataTables {
          list: chartDataTables {
            id
            name
          }
        }`,
      ),
    select: data => data.list,
    refetchOnWindowFocus: false,
  });

  const dataTablesById = keyBy(DataTables.data, 'id');
  const selectedDataTable = dataTablesById[selectedDataTableId];
  const currentOwnerHasTables = useCurrentOwnerHasTables();

  return (
    <div>
      <Text variant="label" size="xs" className="mr-2">
        Chart Data
      </Text>
      <Card variant="secondary" className="mb-4 space-y-4">
        <div className="flex items-center justify-between">
          <FilterContainer>
            <Filter
              variant="plain"
              disabled={!currentOwnerHasTables.data}
              menu={{
                text: selectedDataTable?.name || 'Select a Data Set',
                body: (DataTables.data || []).map(table => (
                  <Dropdown.Item
                    key={table.id}
                    text={table.name}
                    active={table.id === selectedDataTableId}
                    onClick={() => setSelectedDataTableId(table.id)}
                  />
                )),
              }}
              placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
            />
          </FilterContainer>
          <ResetChartButton
            variant="secondary"
            tableId={selectedDataTableId}
            tableName={selectedDataTable?.name}
            disabled={isEmpty(selectedDataTableId) || !currentOwnerHasTables.data}
          />
        </div>
      </Card>
    </div>
  );
}
