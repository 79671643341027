import zipObject from 'lodash/zipObject';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { Button } from '@optra/kit';

import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { serviceAccountPrivileges } from 'config/constants';
import { useInputFocus } from 'hooks';

export default function AdminCreateServiceAccount() {
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      name: '',
      privileges: zipObject(
        Object.keys(serviceAccountPrivileges),
        Object.keys(serviceAccountPrivileges).map(() => false),
      ),
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');

  const qc = q.useQueryClient();
  const createServiceAccount = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createServiceAccount($form: createServiceAccountForm!) {
          serviceAccount: createServiceAccount(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['serviceAccounts'] });
      handleClose();
    },
    onError(error) {
      setError(error);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    createServiceAccount.mutate({
      ...form,
      privileges: Object.entries(form.privileges)
        .filter(([, v]) => v)
        .map(([k]) => k),
    });
  });

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Create Service Account"
        icon="Robot"
        loading={createServiceAccount.isPending}
      />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Create Service Account">
            {error.message}
          </Message>
        )}

        <div className="space-y-2">
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              {...register('name', {
                required: 'Please enter a name',
              })}
              readOnly={createServiceAccount.isPending}
            />
            <ValidationError errors={errors} name="name" />
          </div>

          <div className="space-y-2 pt-2">
            <Label>Privileges</Label>
            <ul className="mt-4">
              {Object.entries(serviceAccountPrivileges).map(([privilege, description]) => {
                return (
                  <Controller
                    key={privilege}
                    control={control}
                    name={`privileges.${privilege}`}
                    render={({ field: { onChange, value, ref } }) => (
                      <FeatureToggle
                        as="li"
                        key={privilege}
                        title={privilege}
                        description={description}
                        checked={value}
                        ref={ref}
                        onChange={onChange}
                      />
                    )}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={createServiceAccount.isPending}>
          Create
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
