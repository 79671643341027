import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, Card, DetailHeading, DetailList, Icon } from '@optra/kit';
import { enums } from '@optra/shared';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillBuilderCollaboratorsSelect from 'components/skill-builder-collaborators-select';
import SkillBuilderDeviceSelect from 'components/skill-builder-device-select';
import SkillBuilderProjectTypeSelect from 'components/skill-builder-project-type-select';
import UpdateCollectorModelButton from 'components/update-collector-model-button';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus } from 'hooks';
import { useEntitlements, useCurrentOrganization } from 'queries';
import useModel from 'queries/models/use-model';

const defaultValues = {
  name: '',
  directoryUrl: '',
  enterprise: false,
  modelType: enums.MODEL_TYPE.EDGE_IMPULSE.value,

  devices: {
    push: [],
    pull: [],
  },
  collaborators: {
    push: [],
    pull: [],
  },
};

export default function EditModel() {
  const { modelId } = useParams();
  const { handleClose } = useModalContext();
  const [error, setError] = useState();
  const form = useForm({
    defaultValues,
  });

  const {
    formState: { errors },
    handleSubmit: onSubmit,
    register,
    setFocus,
    control,
    reset,
  } = form;
  const [projectId, setProjectId] = useState();
  const [isEdgeImpulse, setIsEdgeImpulse] = useState();
  const [edgeImpulseUrl, setEdgeImpulseUrl] = useState();
  useInputFocus(setFocus, 'name');
  const Entitlements = useEntitlements();
  const { limit = 0, current = 0 } = Entitlements.data?.edgeImpulse || {};
  const { data, error: fetchError, isSuccess } = useModel(modelId);

  const [organization] = useCurrentOrganization();
  const isPersonal = organization.id === '$$NONE';

  useOnSuccess(
    () => {
      const { model } = data;
      setProjectId(model?.edgeImpulse?.id);
      setIsEdgeImpulse(model.type === enums.MODEL_TYPE.EDGE_IMPULSE.value);
      setEdgeImpulseUrl(model.edgeImpulse?.url);

      reset(formValues => {
        return {
          id: model?.id,
          name: model?.name,

          // NOTE: SkillBuilderDeviceSelect loads selected devices internally, we want to make sure
          // we're not modifying those values on reset
          devices: formValues.devices,
          collaborators: formValues.collaborators,
          enterprise: model?.edgeImpulse?.enterprise || false,
        };
      });
    },
    { isSuccess },
    [data, reset],
  );

  const update = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateModel($form: updateModelForm!) {
            updateModel(form: $form) {
              id
              name
              type

              edgeImpulse {
                enterprise
                collaborators {
                  data {
                    email
                  }
                }
                workflow {
                  devices {
                    data {
                      id
                    }
                  }
                }
              }
          }
        }`,
        { form },
      ),
  });

  const deleteModel = q.useMutation({
    mutationFn: ({ id }) => {
      return api(
        `mutation RemoveModel($id: ID!) {
          removeModel(id: $id) {
            id
          }
        }`,
        { id },
      );
    },
    onSuccess() {
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleOnSubmit = onSubmit(form => {
    update.mutate(
      {
        id: form.id,
        name: form.name,
        type: form.modelType,

        collaborators: form.collaborators,
        enterprise: form.enterprise,
        samplingDeviceIds: form.devices,
      },
      {
        onSuccess(r) {
          handleClose();
        },
      },
    );
  });

  return (
    <ModalInner as="form" onSubmit={handleOnSubmit}>
      <ModalTitle
        title="Edit Model"
        icon="Plus"
        renderActions={() => (
          <>
            <Button
              size="xs"
              variant="secondary"
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this model?')) {
                  deleteModel.mutate({ id: modelId });
                }
              }}
              loading={deleteModel.isPending}
              icon="Trash"
            >
              Delete
            </Button>
            {isEdgeImpulse && <UpdateCollectorModelButton id={modelId} />}
          </>
        )}
      />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Update Model">
            {error.message}
          </Message>
        )}
        {update?.isError && (
          <Message variant="danger" title="Couldn't Update Model">
            {update?.error?.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Model">
            {fetchError.message}
          </Message>
        )}
        <Card variant="secondary">
          <div className="space-y-3">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              placeholder="Enter Name..."
              {...register('name', { required: 'Please enter a name.' })}
            />
            <ValidationError errors={errors} name="name" />
          </div>
        </Card>
        {isEdgeImpulse && (
          <Card
            variant="secondary"
            onClick={() => window.open(edgeImpulseUrl, '_blank')}
            className="cursor-pointer flex items-center justify-between gap-3 my-3"
          >
            <div className="flex-1">
              <DetailHeading variant="loud">Edit Model</DetailHeading>
              <DetailList details={['Go to Edge Impulse']} />
            </div>

            <Icon name="CaretRight" weight="bold" size="sm" className="opacity-50" />
          </Card>
        )}
        <Card variant="secondary" className="my-3">
          <SkillBuilderProjectTypeSelect
            control={control}
            name="enterprise"
            seatsLeft={limit - current}
          />
          <ValidationError errors={errors} name="enterprise" />
        </Card>
        <Card variant="secondary" className="my-3">
          <SkillBuilderDeviceSelect control={control} name="devices" projectId={projectId} />
          <ValidationError errors={errors} name="devices" />
        </Card>
        {!isPersonal && (
          <Card variant="secondary" className="my-3">
            <SkillBuilderCollaboratorsSelect
              control={control}
              name="collaborators"
              modelId={modelId}
            />
            <ValidationError errors={errors} name="collaborators" />
          </Card>
        )}
        <ModalFooter>
          <Button type="submit" size="xl" loading={update?.isPending || Entitlements.isLoading}>
            Save
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalInner>
  );
}
