import { find } from 'lodash';
import { useState, Fragment } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, DetailList, Text, Spinner } from '@optra/kit';

import { useFeature } from 'components/feature';
import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import OrganizationSelect from 'components/organization-select';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useItemNotFound, useImpersonation } from 'hooks';
import ItemNotFound from 'modals/item-not-found';

function useUser(id, overrides) {
  return q.useQuery({
    queryKey: ['user', id],
    queryFn: () =>
      api(
        `query userProfile($id: ID!) {
          user: userProfile(id: $id) {
            id
            name
            email
            features {
              data {
                enabled
                enabledAsConfigured
                feature {
                  id
                  label
                }
              }
            }
          }
          deviceSkillSyncLimit: ownerEntitlement(ownerId: $id, entitlement: deviceSkillSyncLimit) {
            value
          }
          deviceSyncLimit: ownerEntitlement(ownerId: $id, entitlement: deviceSyncLimit) {
            value
          }
          edgeImpulseEnterpriseAllowance: ownerEntitlement(ownerId: $id, entitlement: edgeImpulseEnterpriseAllowance) {
            value
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}

function UserImpersonator(props) {
  const { userProfileId } = props || {};
  const [organizationId, setOrganizationId] = useState('$$NONE');
  const { impersonateUser } = useImpersonation();

  return (
    <div>
      <div className="flex items-center justify-end">
        <Button
          variant="secondary"
          size="xs"
          icon={impersonateUser.isLoading ? null : 'Play'}
          disabled={impersonateUser.isLoading}
          onClick={() => {
            impersonateUser.mutate({
              userProfileId,
              organizationId,
            });
          }}
        >
          {impersonateUser.isLoading && <Spinner size="xs" />}
        </Button>
        <OrganizationSelect
          placeholderText="Personal"
          allOptionText="Personal"
          allOptionValue="$$NONE"
          size="xs"
          filters={{ userProfileId }}
          value={organizationId}
          onChange={setOrganizationId}
        />
      </div>
    </div>
  );
}

export default function AdminEditUser() {
  const { t } = useTranslation();
  const { userId } = useParams();
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    control,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      features: [],
    },
  });

  const DEVICE_SKILL_SYNC_LIMIT_FEATURE_NAME = 'deviceSkillSyncLimit';
  const SKILL_BUILDER_FEATURE_NAME = 'skillBuilder';
  const DEVICE_SYNC_LIMIT_FEATURE_NAME = 'deviceSyncLimit';
  const watchFeatures = watch('features');
  const watchDeviceSkillSyncLimit = find(watchFeatures, {
    name: DEVICE_SKILL_SYNC_LIMIT_FEATURE_NAME,
  });
  const watchDeviceSyncLimit = find(watchFeatures, { name: DEVICE_SYNC_LIMIT_FEATURE_NAME });
  const watchSkillBuilder = find(watchFeatures, { name: SKILL_BUILDER_FEATURE_NAME });
  const enterpriseSkillBuilderEnabled = useFeature('enterpriseSkillBuilder', 'global');

  const [error, setError] = useState();

  const { fields: featureFields } = useFieldArray({
    control,
    name: 'features',
  });

  const User = useUser(userId);
  useOnSuccess(
    () => {
      const { user, deviceSkillSyncLimit, deviceSyncLimit, edgeImpulseEnterpriseAllowance } =
        User.data;
      reset({
        features: user?.features?.data?.map?.(ft => ({
          id: ft.feature?.id,
          enabled: ft.enabledAsConfigured,
          label: ft.feature?.label,
          name: ft.feature?.id,
        })),
        entitlements: {
          deviceSkillSyncLimit: deviceSkillSyncLimit.value,
          deviceSyncLimit: deviceSyncLimit.value,
          edgeImpulseEnterpriseAllowance: edgeImpulseEnterpriseAllowance.value,
        },
      });
    },
    { isSuccess: User.isSuccess },
    [User.data, reset],
  );

  const qc = q.useQueryClient();
  const updateUserProfile = q.useMutation({
    mutationFn: _form => {
      const form = {
        ..._form,
        entitlements: Object.entries(_form.entitlements).map(([entitlement, value]) => ({
          entitlement,
          value: parseInt(value),
        })),
      };

      return api(
        `mutation updateUserProfile($form: updateUserProfileForm!) {
          updateUserProfile(form: $form) { id }
        }`,
        { form },
      );
    },
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['user', userId] });
      qc.invalidateQueries({ queryKey: ['users'] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    updateUserProfile.mutate({
      id: userId,
      ...form,
      features: form.features.filter(feat => feat.enabled).map(feat => feat.name),
    });
  });

  const loading = User.isLoading || updateUserProfile.isPending;

  const itemNotFound = useItemNotFound({
    fetching: User.isLoading,
    id: User.data?.user?.id,
  });

  if (itemNotFound) {
    return <ItemNotFound id={userId} type="User Profile" />;
  }

  const user = User.data?.user;

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Edit User"
        icon="UserCircle"
        loading={loading}
        renderActions={() => <UserImpersonator userProfileId={userId} />}
      />
      <ModalBody className="space-y-6">
        {User.error && (
          <Message variant="danger" title={t("Couldn't Load Organization")}>
            {User.error.message}
          </Message>
        )}
        {error && (
          <Message variant="danger" title={t("Couldn't Update Organization")}>
            {error.message}
          </Message>
        )}

        <div className="space-y-2">
          <Text size="sm" variant="bold">
            {user?.email}
          </Text>
          <DetailList details={[user?.name]} />
        </div>

        <div className="space-y-2">
          <Label htmlFor="features">{t('Enabled Features')}</Label>
          {featureFields.map((field, index) => {
            if (!field) return null;
            const isEnterpriseAllowanceEnabled =
              featureFields[index]?.name === SKILL_BUILDER_FEATURE_NAME &&
              watchSkillBuilder?.enabled &&
              enterpriseSkillBuilderEnabled;
            const isDeviceSkillSyncLimitEnabled =
              featureFields[index]?.name === DEVICE_SKILL_SYNC_LIMIT_FEATURE_NAME &&
              watchDeviceSkillSyncLimit?.enabled;
            const isDeviceSyncLimitEnabled =
              featureFields[index]?.name === DEVICE_SYNC_LIMIT_FEATURE_NAME &&
              watchDeviceSyncLimit?.enabled;
            return (
              <Fragment key={field.id}>
                <FeatureToggle
                  title={field.label}
                  expanded={
                    isEnterpriseAllowanceEnabled ||
                    isDeviceSkillSyncLimitEnabled ||
                    isDeviceSyncLimitEnabled
                  }
                  {...register(`features.${index}.enabled`)}
                >
                  {isEnterpriseAllowanceEnabled && (
                    <div className="space-y-2">
                      <Label htmlFor="entitlements.edgeImpulseEnterpriseAllowance">
                        Enterprise Allowance
                      </Label>
                      <Input
                        type="number"
                        {...register('entitlements.edgeImpulseEnterpriseAllowance')}
                        readOnly={loading}
                      />
                      <ValidationError
                        errors={errors}
                        name="entitlements.edgeImpulseEnterpriseAllowance"
                      />
                    </div>
                  )}
                  {isDeviceSkillSyncLimitEnabled && (
                    <div className="space-y-2">
                      <Label htmlFor="entitlements.deviceSkillSyncLimit">
                        Device Skill Sync Limit
                      </Label>
                      <Input
                        type="number"
                        {...register('entitlements.deviceSkillSyncLimit')}
                        readOnly={loading}
                      />
                      <ValidationError errors={errors} name="entitlements.deviceSkillSyncLimit" />
                    </div>
                  )}
                  {isDeviceSyncLimitEnabled && (
                    <div className="space-y-2">
                      <Label htmlFor="entitlements.deviceSyncLimit">Device Sync Limit</Label>
                      <Input
                        type="number"
                        {...register('entitlements.deviceSyncLimit')}
                        readOnly={loading}
                      />
                      <ValidationError errors={errors} name="entitlements.deviceSyncLimit" />
                    </div>
                  )}
                </FeatureToggle>
                <input type="hidden" {...register(`features.${index}.id`)} />
              </Fragment>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
