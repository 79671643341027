import { set, uniqueId, isEmpty } from 'lodash';
import { useRef } from 'react';
import * as uuid from 'uuid';

import { Button, IconButton, Toggle } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';

import FlexCenter from './flex-center';

export default function KeyValueInput(props) {
  const {
    value,
    disabled,
    keyLabel = 'Key',
    valueLabel = 'Value',
    loading = false,
    onChange = () => false,
    name: _name,
    showTypeSelection = false,
  } = props;

  const data =
    value?.length > 0
      ? value
      : [
          {
            _id: uniqueId(),
            key: '',
            value: '',
            ...(showTypeSelection ? { type: 'text' } : {}), // Default to text
          },
        ];

  const randomName = useRef(uuid.v4().split('-')[0]);
  const name = isEmpty(_name) ? randomName.current : _name;

  return (
    <>
      <div className="grid grid-cols-9 gap-4 mb-4">
        <Label className="col-span-4">{keyLabel}</Label>
        <Label className="col-span-4">{valueLabel}</Label>
      </div>
      {data?.map?.((datum, idx) => (
        <div key={datum._id} className="grid col-span-9 grid-cols-9 gap-4 mb-4">
          <div className="flex space-y-2 col-span-4">
            <Input
              type="text"
              name={`${name}[${idx}].key`}
              disabled={loading || disabled}
              defaultValue={datum.key}
              onChange={e => {
                onChange(set([...data], `[${idx}].key`, e.currentTarget.value));
              }}
            />
          </div>
          <div className="flex space-y-2 col-span-4 justify-between">
            <Input
              type="text"
              name={`${name}[${idx}].value`}
              disabled={loading || disabled}
              defaultValue={datum.value}
              onChange={e => {
                onChange(set([...data], `[${idx}].value`, e.currentTarget.value));
              }}
            />
          </div>
          {data?.length > 1 && idx > 0 && (
            <IconButton
              variant="secondary"
              className="col-span-1 my-auto"
              onClick={() => {
                if (disabled) return;
                if (data?.length > 1) {
                  onChange(data.filter((o, k) => k !== idx));
                }
              }}
              name="X"
            />
          )}

          {showTypeSelection && (
            <div className="flex space-y-2 col-span-4">
              <Toggle
                label="Is Password"
                checked={datum.type === 'password'}
                onChange={e => {
                  const updatedType = e.currentTarget.checked ? 'password' : 'text';
                  onChange(set([...data], `[${idx}].type`, updatedType));
                }}
              />
            </div>
          )}

          {idx + 1 === data?.length && (
            <FlexCenter className="mt-4 col-span-9">
              <Button
                variant="secondary"
                size="xs"
                onClick={() => {
                  if (disabled) return;
                  onChange([
                    ...(data || []),
                    {
                      _id: uniqueId(),
                      key: '',
                      value: '',
                      ...(showTypeSelection ? { type: 'text' } : {}), // Default to text
                    },
                  ]);
                }}
                icon="Plus"
              >
                Add Item
              </Button>
            </FlexCenter>
          )}
        </div>
      ))}
    </>
  );
}
