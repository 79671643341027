import { api, q } from 'config/api';

export default function useProfile() {
  return q.useQuery({
    queryKey: ['profile'],
    queryFn: () =>
      api(
        `query profile {
          currentOwner {
            ... on UserProfile {
              pricing
            }
            ... on Organization {
              pricing
            }
          }
          currentOrganizationMember {
            roles
          }
          currentUser {
            id
            email
            profile {
              id
              name
              image
              ownedOrganizationsCount
              toastNotificationsEnabled
            }
            memberships {
              data {
                id
                roles
                organization {
                  id
                  name
                }
              }
            }
          }
        }`,
      ),
    retry: 0,
  });
}
