import { useParams, useNavigate } from 'react-router-dom';

import DeviceSubscription from 'components/device-subscription';
import ModalTitle from 'components/modal-title';

export default function DeviceDetailSubscription() {
  const { deviceId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <ModalTitle title="Resubscribe to Device" icon="Aperture" />
      <DeviceSubscription
        deviceId={deviceId}
        btnText="Ok"
        onComplete={() => {
          navigate({
            pathname: `/devices/${deviceId}/overview`,
          });
        }}
      />
    </>
  );
}
