import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { Button, DROPDOWN_MENU_PLACEMENT } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import OrganizationSelect from 'components/organization-select';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';
import { useOrganizationsLimits, useCurrentOrganization } from 'queries';

export default function CreateOrganization() {
  const [, { handleChange: handleChangeCurrentOrg }] = useCurrentOrganization();
  const userLimits = useOrganizationsLimits();

  const {
    control,
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      name: '',
      parentId: null,
    },
  });
  const [error, setError] = useState();

  useInputFocus(setFocus, 'name');

  const createOrganization = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createOrganization($form: createOrganizationForm!) {
          organization: createOrganization(form: $form) {
            id
          }
        }`,
        {
          form: {
            ...form,
            parentId: isEmpty(form.parentId) ? '$$PERSONAL' : form.parentId,
          },
        },
      ),
    onSuccess(r) {
      handleChangeCurrentOrg(r.organization.id, '/profile');
    },
    onError(error) {
      setError(error);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    createOrganization.mutate(form);
  });

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Create Organization"
        icon="Buildings"
        loading={createOrganization.isPending || userLimits.isLoading}
      />
      <ModalBody className="space-y-6">
        {error && (
          <Message variant="danger" title="Couldn't Create Organization">
            {error.message}
          </Message>
        )}
        {userLimits.data?.profile?.canCreateChildOrganizations && (
          <div className="flex flex-row items-center space-x-2">
            <Label htmlFor="parentOrganization">Parent</Label>
            <Controller
              name="parentId"
              control={control}
              disabled={createOrganization.isPending}
              render={({ field: { ref, ...field } }) => (
                <OrganizationSelect
                  placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
                  placeholderText="No Parent"
                  allOptionText="No Parent"
                  allOptionValue={null}
                  allOptionIcon="LinkBreak"
                  filters={{
                    features: { createChildOrganizations: true },
                  }}
                  {...field}
                />
              )}
            />
          </div>
        )}
        <div className="space-y-2">
          <Label htmlFor="name">Organization Name</Label>
          <Input
            type="text"
            {...register('name', { required: 'Please enter a name.' })}
            readOnly={createOrganization.isPending}
          />
          <ValidationError errors={errors} name="name" />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={createOrganization.isPending}>
          Create Organization
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
