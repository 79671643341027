import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Card } from '@optra/kit';
import { validators } from '@optra/shared';
import { wholeCredits } from '@optra/shared/dist/utils/formatCredits';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';

export default function AdminPurchaseOrderCreate() {
  const {
    formState: { errors },
    handleSubmit,
    register,
    setFocus,
  } = useForm({
    defaultValues: {
      codesToGenerate: 0,
      creditsPerCode: 0,
      poNumber: '',
      vendor: '',
    },
    resolver: zodResolver(validators.giftCardPurchaseOrderCreate),
  });
  useInputFocus(setFocus, 'poNumber');
  const { handleClose } = useModalContext();
  const [error, setError] = useState();

  const create = q.useMutation({
    mutationFn: form =>
      api(
        `
					mutation CreateGiftCardPurchaseOrder($form: createGiftCardPurchaseOrderForm!) {
						createGiftCardPurchaseOrder(form: $form) {
							id
						}
					}
				`,
        { form },
      ),
  });

  const handleOnSubmit = handleSubmit(form => {
    const validationResult = validators.giftCardPurchaseOrderCreate.safeParse(form);

    if (!validationResult?.success) {
      const errors = validationResult.error.errors.map(error => error.message);
      setError(`Validation failed: [${errors.join(', ')}]`);
      return;
    }

    const validatedData = validationResult.data;
    const creditsPerCodeInWholeCredits = wholeCredits(validatedData.creditsPerCode);
    create.mutate(
      {
        codesToGenerate: validatedData.codesToGenerate,
        creditsPerCode: creditsPerCodeInWholeCredits,
        poNumber: validatedData.poNumber,
        vendor: validatedData.vendor,
      },
      {
        onSuccess(r) {
          handleClose();
        },
        onError(error) {
          setError(error);
        },
      },
    );
  });

  return (
    <ModalInner as="form" onSubmit={handleOnSubmit}>
      <ModalTitle title="New Usage Credits" icon="Key" loading={create?.isPending} />
      <ModalBody>
        {!!error && (
          <Message variant="danger" title="Couldn't Create Purchase Order">
            {error}
          </Message>
        )}
        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="poNumber">P.O. Number</Label>
            <Input type="text" {...register('poNumber')} readOnly={create?.isPending} />
            <ValidationError errors={errors} name="poNumber" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="vendor">Name</Label>
            <Input type="text" {...register('vendor')} readOnly={create?.isPending} />
            <ValidationError errors={errors} name="vendor" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="creditsPerCode">Number of Credits Per Code</Label>
            <Input
              type="number"
              step="0.01"
              {...register('creditsPerCode')}
              readOnly={create?.isPending}
            />
            <ValidationError errors={errors} name="creditsPerCode" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="codesToGenerate">Number of Codes to Generate</Label>
            <Input type="number" {...register('codesToGenerate')} readOnly={create?.isPending} />
            <ValidationError errors={errors} name="codesToGenerate" />
          </div>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={create?.isPending}>
          Generate
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
