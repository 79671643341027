import { uniqueId, isEmpty, isFinite as _isFinite, isBoolean } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { Button } from '@optra/kit';

import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import RawConfig from 'components/raw-config';
import SkillContainerFields from 'components/skill-container-fields';
import SkillEnvFields from 'components/skill-env-fields';
import SkillFormTabs, { isActiveTab } from 'components/skill-form-tabs';
import SkillInputFields from 'components/skill-input-fields';
import SkillJsonUploadButton from 'components/skill-json-upload-button';
import SkillMetaFields from 'components/skill-meta-fields';
import SkillOutputFields from 'components/skill-output-fields';
import SkillPrivilegesFields from 'components/skill-privileges-fields';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import cleanInputArray from 'lib/clean-input-array';
import { useCurrentUser, useLatestSkillVersion } from 'queries';

export default function CreateSkillDockerImageVersion() {
  const { skillId } = useParams();
  const navigate = useNavigate();

  const [error, setError] = useState();
  const [tab, setTab] = useState('container');
  const [currentUser] = useCurrentUser();

  const form = useForm();
  const {
    control,
    formState: { errors },
    handleSubmit: onSubmit,
    register,
    setValue,
    reset,
  } = form;

  // Make the password field look filled if the registry has a password
  const passwordPlaceholder = password => (password ? '             ' : '');

  const { data, isLoading: fetching, error: fetchError } = useLatestSkillVersion(skillId);

  let latestVersion = data?.skill?.versions?.data?.[0] || {};

  const qc = q.useQueryClient();
  const createSkillVersion = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createSkillVersion($form: createSkillVersionForm!) {
          version: createSkillVersion(form: $form) {
            id
            skill {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
      qc.invalidateQueries({ queryKey: ['skillVersions'] });
      qc.invalidateQueries({ queryKey: ['librarySkillVersions'] });
      navigate(`/skills/${skillId}/edit`);
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    const env = {};
    const storage = {};
    cleanInputArray(form.env).forEach(v => {
      env[v.key] = v.value;
    });
    cleanInputArray(form.storage).forEach(v => {
      storage[v.key] = v.value;
    });
    const endpointAliases = cleanInputArray(form.endpointAliases).map(v => v.alias);

    const port = parseInt(form.port);
    const shmSize = parseInt(form.shmSize);
    const writableRootFS = parseInt(form.writableRootFS);
    createSkillVersion.mutate({
      env,
      storage,
      hostName: !isEmpty(form.hostName) ? form.hostName : null,
      endpointAliases,
      portBindings: cleanInputArray(form.portBindings),
      tmpfs: cleanInputArray(form.tmpfs),
      shmSize: _isFinite(shmSize) ? shmSize : null,
      port: _isFinite(port) ? port : null,
      removableMedia: isBoolean(form.removableMedia) ? form.removableMedia : null,
      hostNetworking: isBoolean(form.hostNetworking) ? form.hostNetworking : null,
      gpio: isBoolean(form.gpio) ? form.gpio : null,
      cx2000IRRemote: isBoolean(form.cx2000IRRemote) ? form.cx2000IRRemote : null,
      cx2000VideoAcceleration: isBoolean(form.cx2000VideoAcceleration)
        ? form.cx2000VideoAcceleration
        : null,
      led: isBoolean(form?.led) ? form.led : null,
      dockerInDocker: isBoolean(form?.dockerInDocker) ? form.dockerInDocker : null,
      privileged: isBoolean(form?.privileged) ? form?.privileged : null,
      mountVolumes: cleanInputArray(form.mountVolumes),
      binds: cleanInputArray(form.binds),
      capAdd: cleanInputArray(form?.capAdd),
      capDrop: cleanInputArray(form?.capDrop),
      addedDevice: cleanInputArray(form?.addedDevice),
      writableRootFS: _isFinite(writableRootFS) ? writableRootFS : null,
      skillId,
      devices: {
        ...form.devices,
        // For now, we force 5 cameras. In the future, we may allow the user to choose a number
        cameras: form.devices.cameras ? 5 : null,
      },
      version: form.version,
      repository: {
        ...form.repository,
        password:
          // Only update the password if it has changed
          form.repository.password !== passwordPlaceholder(latestVersion?.repository?.password)
            ? form.repository.password
            : undefined,
      },
      outputs: cleanInputArray(form.outputs).map(o => ({
        id: o.key,
        label: o.value,
        mapping: {
          type: o.mapping?.type || null,
          statusMapping: o.mapping?.statusMapping || null,
        },
        unhealthyTimeoutMS: _isFinite(parseInt(o.unhealthyTimeoutMS))
          ? parseInt(o.unhealthyTimeoutMS)
          : null,
      })),
      inputs: cleanInputArray(form.inputs).map(i => ({
        binding: i.key,
        label: i.value,
        type: i.type,
      })),
      ...(!isEmpty(form.createOptions) && currentUser?.isSysAdmin
        ? { createOptions: JSON.parse(form.createOptions) }
        : { createOptions: latestVersion?.createOptions }),
    });
  });

  useDeepCompareEffect(() => {
    if (latestVersion) {
      reset({
        version: latestVersion?.version,
        repository: {
          uri: latestVersion?.repository?.uri,
          username: latestVersion?.repository?.username,
          password: passwordPlaceholder(latestVersion?.repository?.password),
        },
        inputs: (latestVersion?.inputs || []).map(i => ({
          _id: uniqueId(),
          key: i.binding,
          value: i.label,
          type: i.type,
        })),
        outputs: (latestVersion?.outputs || []).map(o => ({
          _id: uniqueId(),
          key: o.id,
          value: o.label,
          mapping: {
            type: o.mapping?.type || '',
            statusMapping: o.mapping?.statusMapping || null,
          },
          unhealthyTimeoutMS: o.unhealthyTimeoutMS || 1000 * 60 * 60, // Default
        })),
        env: Object.entries(latestVersion?.env || {}).map(([key, value]) => ({
          _id: uniqueId(),
          key,
          value,
        })),
        devices: {
          sound: latestVersion?.devices?.sound,
          hdmi: latestVersion?.devices?.hdmi,
          // For now, this is a toggle. In the future, we may allow the user to choose a number
          cameras: _isFinite(latestVersion?.devices?.cameras),
        },
        removableMedia: latestVersion?.removableMedia,
        hostNetworking: latestVersion?.hostNetworking,
        gpio: latestVersion?.gpio,
        cx2000IRRemote: latestVersion?.cx2000IRRemote,
        usbSerialConverter: latestVersion?.usbSerialConverter,
        cx2000VideoAcceleration: latestVersion?.cx2000VideoAcceleration,
        led: latestVersion?.led,
        dockerInDocker: latestVersion?.dockerInDocker,
        privileged: latestVersion?.privileged,
        mountVolumes: (latestVersion?.mountVolumes || []).map(size => ({
          _id: uniqueId(),
          ...size,
        })),
        binds: (latestVersion?.binds || []).map(bind => ({
          _id: uniqueId(),
          ...bind,
        })),
        capAdd: (latestVersion?.capAdd || []).map(cap => ({
          _id: uniqueId(),
          ...cap,
        })),
        capDrop: (latestVersion?.capDrop || []).map(cap => ({
          _id: uniqueId(),
          ...cap,
        })),
        addedDevice: (latestVersion?.addedDevice || []).map(device => ({
          _id: uniqueId(),
          ...device,
        })),
        writableRootFS: latestVersion?.writableRootFS,
        ...(!isEmpty(latestVersion?.createOptions)
          ? { createOptions: JSON.stringify(latestVersion?.createOptions, null, 2) }
          : { createOptions: '' }),
        storage: Object.entries(latestVersion?.storage || {}).map(([key, value]) => ({
          _id: uniqueId(),
          key,
          value,
        })),
        hostName: latestVersion?.hostName,
        endpointAliases: latestVersion?.endpointAliases?.map(alias => ({
          _id: uniqueId(),
          alias,
        })),
        port: latestVersion?.port,
        portBindings: (latestVersion?.portBindings || []).map(p => ({
          _id: uniqueId(),
          ...p,
        })),
        tmpfs: (latestVersion?.tmpfs || []).map(t => ({
          _id: uniqueId(),
          ...t,
        })),
        shmSize: latestVersion?.shmSize,
        ...(!isEmpty(latestVersion?.createOptions)
          ? { createOptions: JSON.stringify(latestVersion?.createOptions, null, 2) }
          : { createOptions: '' }),
      });
    }
  }, [latestVersion, currentUser?.isSysAdmin]);

  const loading = fetchError || fetching || createSkillVersion.isPending;

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Create Version"
        icon="Stack"
        loading={loading}
        renderActions={() => <SkillJsonUploadButton onError={setError} setValue={setValue} />}
      />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Create Version">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Version">
            {fetchError.message}
          </Message>
        )}

        <div>
          <SkillMetaFields
            hideName
            hideIcon
            loading={loading}
            register={register}
            control={control}
            errors={errors}
          />
        </div>

        <div>
          <SkillFormTabs
            tab={tab}
            onChange={setTab}
            tabs={['container', 'inputs', 'outputs', 'env', 'privileges']}
          />
        </div>

        <div>
          <ValidationError name="repository.uri" errors={errors} />
          {Object.keys(errors).some(e =>
            ['sound', 'storage', 'port', 'hostName', 'tmpfs', 'shmSize'].includes(e),
          ) && <ValidationError message="Invalid input in Privileges" />}
        </div>

        <div>
          <SkillContainerFields
            visible={isActiveTab(tab, 'container')}
            loading={loading}
            register={register}
          />
          <SkillInputFields
            visible={isActiveTab(tab, 'inputs')}
            loading={loading}
            control={control}
          />
          <SkillOutputFields
            visible={isActiveTab(tab, 'outputs')}
            loading={loading}
            control={control}
            errors={errors}
          />
          <SkillEnvFields visible={isActiveTab(tab, 'env')} loading={loading} control={control} />
          <SkillPrivilegesFields
            visible={isActiveTab(tab, 'privileges')}
            loading={loading}
            form={form}
          />
        </div>

        <RawConfig form={form} setError={setError} loading={loading} />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
