import { api, q } from 'config/api';

export default function useCurrentUser(overrides) {
  const { data, isLoading, isSuccess, error } = q.useQuery({
    queryKey: ['currentUser'],
    queryFn: () =>
      api(
        `query currentUser {
          currentUser {
            id
            email
            profile {
              name
              image
              toastNotificationsEnabled
            }
            isSysAdmin
            agreements {
              eula {
                state
                acceptedAt
              }
            }
            memberships {
              data {
                id
                roles
                organization {
                  id
                  name
                }
              }
            }
          }
        }`,
      ),
    retry: 0,
    ...overrides,
  });

  const currentUser = data?.currentUser
    ? {
        ...data?.currentUser,
        name: data?.currentUser?.profile?.name,
        image: data?.currentUser?.profile?.image,
        toastNotificationsEnabled: data?.currentUser?.profile?.toastNotificationsEnabled,
      }
    : undefined;

  return [currentUser, isLoading, isSuccess, error];
}
