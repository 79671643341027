import mapValuesDeep from 'deepdash/mapValuesDeep';
import { isNumber, isBoolean, isFunction, isEmpty, isArray, isPlainObject, get } from 'lodash';
import set from 'lodash/fp/set';
import qs from 'qs';
import { useRef, useMemo, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import deepMerge from 'lib/merge';

function makeEmpty(obj) {
  const next = {};
  Object.entries(obj).forEach(([k, v]) => {
    if (isArray(v) && v.every(arrValue => isEmpty(arrValue))) {
      next[k] = [];
      return;
    }
    if (isPlainObject(v)) {
      next[k] = makeEmpty(v);
      return;
    }
    next[k] = v;
  });
  return next;
}

export function parseSearchParams(searchString) {
  if (isEmpty(searchString)) return {};
  const encodedQueryParams = qs.parse(searchString, {
    ignoreQueryPrefix: true,
    decoder(str, defaultDecoder, charset, type) {
      if (type === 'key') {
        return str;
      }
      const isNum = str.startsWith('n(') && str.endsWith(')');
      const isBool = str.startsWith('b(') && str.endsWith(')');

      if (isNum) {
        return Number(str.slice(2).slice(0, -1));
      }

      if (isBool) {
        return JSON.parse(str.slice(2).slice(0, -1));
      }

      return defaultDecoder(str, defaultDecoder, charset, type);
    },
  });
  const mappedValues = mapValuesDeep(
    encodedQueryParams,
    v => {
      try {
        const isURIComponent = decodeURI(v) !== decodeURIComponent(v);
        if (isURIComponent) return JSON.parse(decodeURIComponent(v));
        return v;
      } catch (err) {
        return v;
      }
    },
    { leavesOnly: true },
  );
  return makeEmpty(mappedValues);
}

export function stringifySearchParams(state) {
  return qs.stringify(state, {
    addQueryPrefix: true,
    allowEmptyArrays: true,
    encoder(str, defaultEncoder, charset, type) {
      if (type === 'key') return str;
      if (isNumber(str)) return `n(${str})`;
      if (isBoolean(str)) return `b(${str})`;
      return defaultEncoder(str, defaultEncoder, charset, type);
    },
  });
}

export const locationStore = new Map();

export function getSearchParamsForLocation(search, defaultState) {
  if (defaultState) {
    const searchParams = parseSearchParams(search);
    const defaultSearchParams = parseSearchParams(defaultState);
    return stringifySearchParams(deepMerge(defaultSearchParams, searchParams));
  }

  return search;
}

// This is a custom useSearchParams leveraging a custom encoder/decoder
// NOTE: it depends on rendering/dismount to properly attach state, this should not be used in a "global" context
// for example, in tabs, it should not be used at the top-level of the tabs, it should exist in each individual tab (the leaf of the route)
export default function useSearchParams(defaultState, defaultNavigateOptions) {
  const location = useLocation();
  const navigate = useNavigate();

  const anchoredPathname = useRef(location.pathname);
  const defaultSearchParamsRef = useRef(stringifySearchParams(defaultState));
  const defaultNavigateOptionsRef = useRef(defaultNavigateOptions);

  const isMatch = anchoredPathname.current === location.pathname;
  const [searchParamsStr, searchParamsObj] = useMemo(() => {
    // hook may be rendered as a parent, it can only resolve for 1 route at a time so we try to retain state in memory for consistent resolution
    if (isMatch && !isEmpty(location.search)) {
      locationStore.set(anchoredPathname.current, location.search);
    }
    if (!isMatch) {
      const lastKnownState = locationStore.get(anchoredPathname.current);

      defaultSearchParamsRef.current = lastKnownState;
      return [lastKnownState, parseSearchParams(lastKnownState)];
    }

    const sp = getSearchParamsForLocation(location.search, defaultSearchParamsRef.current);
    return [sp, parseSearchParams(sp)];
  }, [location.search, isMatch]);

  useEffect(() => {
    const pathname = anchoredPathname.current;
    return () => {
      locationStore.delete(pathname);
    };
  }, []);

  const setSearchParams = useCallback(
    (nextInit, navigateOptions) => {
      const searchParams = parseSearchParams(searchParamsStr);
      const newSearchParams = stringifySearchParams(
        isFunction(nextInit) ? nextInit(searchParams) : nextInit,
      );
      navigate(newSearchParams, {
        replace: true,
        ...defaultNavigateOptionsRef.current,
        ...navigateOptions,
      });
    },
    [navigate, searchParamsStr],
  );

  const createSearchParamSetter = useCallback(
    fieldPath => v => {
      setSearchParams(prev => {
        return set(fieldPath, isFunction(v) ? v(get(prev, fieldPath)) : v, prev);
      });
    },
    [setSearchParams],
  );

  return [searchParamsObj, setSearchParams, createSearchParamSetter];
}
