import cx from 'classnames';
import { find, flatMap, isEmpty, keys } from 'lodash';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce, useKey } from 'react-use';

import {
  PageWrapper,
  DROPDOWN_MENU_PLACEMENT,
  Dropdown,
  Filter,
  FilterContainer,
  PrimaryCTAButton,
  Button,
  Tooltip,
  useBreakpoint,
  SearchField,
} from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import PinPageButton from 'components/pin-page-button';
import WorkflowsList from 'components/workflows-list';
import { q, useOnSuccess } from 'config/api';
import { useHasRoles, useSearchParams } from 'hooks';
import { useWorkflows, useCurrentOrganization } from 'queries';

export default function Workflows() {
  const { t } = useTranslation();
  const { thresholds } = useBreakpoint();
  const isMobile = !thresholds.lg;
  const [canCreateWorkflow] = useHasRoles(['admin', 'workflowEditor', 'workflowDeployer']);

  const [searchParams, , createSearchParamSetter] = useSearchParams({
    query: '',
    workflowsView: 'expanded',
    sort: 'createdAt_desc',
    filter: 'all',
  });
  const { query: search, workflowsView: view, sort, filter } = searchParams;
  const setSearch = createSearchParamSetter('query');
  const setSort = createSearchParamSetter('sort');
  const setView = createSearchParamSetter('workflowsView');
  const setFilter = createSearchParamSetter('filter');

  const activeSort = !!sort ? sort : undefined;
  const [currentOrganization, { fetching }] = useCurrentOrganization();

  const isPersonal = !fetching && currentOrganization?.id === '$$NONE';

  const filterOptions = {
    all: t('All'),
    starred: t('Starred'),
  };

  const sortOptions = {
    createdAt_desc: t('Newest'),
    createdAt_asc: t('Oldest'),
    name_asc: t('Name (A-Z)'),
    name_desc: t('Name (Z-A)'),
  };

  if (!isPersonal) filterOptions.created = t('Mine');

  const searchInputRef = useRef(null);
  useKey('/', e => {
    if (
      searchInputRef.current !== document.activeElement &&
      document.activeElement.tagName !== 'INPUT'
    ) {
      searchInputRef.current.focus();
      e.preventDefault();
    }
  });

  const memoizedFilter = useMemo(
    () => ({
      ...(filter === 'starred' ? { starred: true } : {}),
      ...(filter === 'created' ? { createdBy: '$$currentUser' } : {}),
      ...(!isEmpty(search) ? { $search: search } : {}),
    }),
    [search, filter],
  );

  const memoizedSort = useMemo(() => {
    const sortOptions = (sort || 'createdAt_desc').split('_');
    return {
      sortBy: sortOptions?.at(0),
      sortDirection: sortOptions?.at(1),
    };
  }, [sort]);

  const workflowDeploymentInProgressRef = useRef(null);
  const qc = q.useQueryClient();
  const {
    data,
    isLoading,
    isRefetching,
    isFetchedAfterMount,
    isSuccess,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    error,
  } = useWorkflows({
    list: {
      filter: memoizedFilter,
      sort: { by: memoizedSort.sortBy, direction: memoizedSort.sortDirection },
    },
  });
  const workflows = flatMap(data?.pages, page => page?.list?.data);

  useOnSuccess(
    () => {
      const updatedWorkflowDeploymentInProgress = workflows.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: ['queued', 'inProgress', 'calculating'].includes(
            curr.latestDeployment?.status,
          ),
        }),
        {},
      );
      for (const workflowId of Object.keys(updatedWorkflowDeploymentInProgress)) {
        if (
          !updatedWorkflowDeploymentInProgress[workflowId] &&
          workflowDeploymentInProgressRef.current?.[workflowId]
        ) {
          qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
        }
      }
      workflowDeploymentInProgressRef.current = updatedWorkflowDeploymentInProgress;
    },
    { isSuccess },
    [qc, workflows],
  );

  useDebounce(
    () => {
      if (isLoading) {
        return;
      }
      // for revealing a hidden workflow when utilizing `scrollTo`.
      // only search when not in the current list.
      // added benefit of adding a "not found" UX
      if (find(workflows, ['name', search])) {
        return;
      }

      setSearch(search ?? '');
    },
    500,
    [search, isLoading],
  );

  return (
    <PageWrapper
      heading={t('Workflows')}
      icon="CaretDoubleRight"
      loading={isLoading}
      error={error}
      components={{
        actions: (
          <>
            <PinPageButton />
            {canCreateWorkflow && (
              <PrimaryCTAButton to="/workflows/create" text={t('New Workflow')} />
            )}
          </>
        ),
        controls: (
          <div
            className={cx(
              'flex flex-col items-center',
              'md:flex-row md:items-center md:justify-between space-x-6',
            )}
          >
            {/* Search */}
            <div className="w-full md:w-auto">
              <SearchField
                searching={isRefetching && !isFetchedAfterMount}
                value={search}
                onChange={setSearch}
                placeholder={t('Search Workflows…')}
                ref={searchInputRef}
                count={workflows?.length}
              />
            </div>

            {/* Filters */}
            {!isMobile && (
              <FilterContainer>
                {/* Filter */}
                <Filter
                  label={t('Filter')}
                  variant="plain"
                  menu={{
                    text: filterOptions[filter],
                    body: keys(filterOptions).map((option, i) => (
                      <Dropdown.Item
                        key={`${option}_${i}`}
                        text={filterOptions[option]}
                        active={option === filter}
                        onClick={() => setFilter(option)}
                      />
                    )),
                  }}
                  placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}
                />
                {/* Sort */}
                <Filter
                  label={t('Sort')}
                  variant="plain"
                  menu={{
                    text: sortOptions[activeSort],
                    body: keys(sortOptions).map((option, i) => (
                      <Dropdown.Item
                        key={`${option}_${i}`}
                        text={sortOptions[option]}
                        active={option === activeSort}
                        onClick={() => {
                          setSort(option);
                        }}
                      />
                    )),
                  }}
                  placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}
                />
                {/* View */}
                <Filter label="View" variant="plain">
                  <div className="flex items-center justify-start md:justify-center">
                    <Tooltip label="Expanded">
                      <Button
                        variant={view === 'expanded' ? 'primary' : 'secondary'}
                        onClick={() => setView('expanded')}
                        icon="SquaresFour"
                        size="sm"
                        className="rounded-r-none pr-3"
                      />
                    </Tooltip>
                    <Tooltip label="Collapsed">
                      <Button
                        variant={view === 'collapsed' ? 'primary' : 'secondary'}
                        onClick={() => setView('collapsed')}
                        icon="Rows"
                        size="sm"
                        className="rounded-l-none pl-3"
                      />
                    </Tooltip>
                  </div>
                </Filter>
              </FilterContainer>
            )}
          </div>
        ),
      }}
    >
      <WorkflowsList
        workflows={workflows}
        scrollToId={searchParams?.id}
        canCreateWorkflow={canCreateWorkflow}
        expanded={view === 'expanded'}
      />

      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isFetchingNextPage || !hasNextPage}
      />
    </PageWrapper>
  );
}
