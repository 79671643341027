import { useAsync } from 'react-use';

import { LexmarkLogo, Text, Container, ButtonWrap } from '@optra/kit';

function Footer() {
  const version = useAsync(async () => {
    const v = await fetch('/version.json').then(r => r.json());
    return v?.message || 'v0';
  });

  return (
    <Container full>
      <footer className="flex flex-col lg:flex-row lg:justify-between items-center space-y-4 lg:space-y-0 py-4">
        <div className="flex-1">
          <Text size="xs" color="muted">
            <LexmarkLogo className="inline-block mr-2" />
            Copyright &copy; 2025 Lexmark Ventures LLC.
          </Text>
        </div>
        <Text size="xs" color="muted">{`Optra Portal ${version?.value || ''}`}</Text>
        <div className="flex flex-1 lg:justify-end lg:items-end space-x-3">
          <ButtonWrap
            to="/privacy"
            className="block text-xs text-light-fg-secondary dark:text-dark-fg-secondary"
          >
            Privacy
          </ButtonWrap>
          <ButtonWrap
            to="/terms-of-use"
            className="block text-xs text-light-fg-secondary dark:text-dark-fg-secondary"
          >
            Terms of Use
          </ButtonWrap>
          <ButtonWrap
            to="/terms-and-conditions"
            className="block text-xs text-light-fg-secondary dark:text-dark-fg-secondary"
          >
            Terms and Conditions
          </ButtonWrap>
        </div>
      </footer>
    </Container>
  );
}

export default Footer;
