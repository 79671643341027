import { useParams } from 'react-router-dom';

import { Toggle, Card, Button, Icon, Spinner, Text } from '@optra/kit';

import ApiActionForm from 'components/api-action-form';
import DataSinkActionRoutes from 'components/data-sink-action-routes';
import EmailActionForm from 'components/email-action-form';
import FirebasePushNotificationActionForm from 'components/firebase-push-notification-action-form';
import FlexCenter from 'components/flex-center';
import GoogleSpreadsheetActionForm from 'components/google-spreadsheet-action-form';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SmsActionForm from 'components/sms-action-form';
import TimestreamActionRoutes from 'components/timestream-action-routes';
import { api, q } from 'config/api';
import { useItemNotFound } from 'hooks';
import actionIcon from 'lib/action-icon';
import ItemNotFound from 'modals/item-not-found';
import { useWorkflowAction } from 'queries';

const actionForms = {
  api: ApiActionForm,
  email: EmailActionForm,
  firebasePushNotification: FirebasePushNotificationActionForm,
  sms: SmsActionForm,
  googleSpreadsheet: GoogleSpreadsheetActionForm,
  dataSink: DataSinkActionRoutes,
  timestream: TimestreamActionRoutes,
};

export default function EditWorkflowAction() {
  const { workflowActionId } = useParams();
  const { handleClose } = useModalContext();
  const { isLoading: fetching, data, error: fetchError } = useWorkflowAction(workflowActionId);

  const qc = q.useQueryClient();
  const updateWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflowAction($form: updateWorkflowActionForm!) {
          workflowAction: updateWorkflowAction(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      handleClose();
    },
  });

  const removeWorkflowAction = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeWorkflowAction($id: ID!) {
          removeWorkflowAction(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', data.workflowAction?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      handleClose();
    },
  });

  const handleRemove = async event => {
    event.stopPropagation();
    event.preventDefault();
    if (window.confirm('Are you sure you want to remove this action from its workflow?')) {
      removeWorkflowAction.mutate(workflowActionId);
    }
  };

  const handleToggleDisabled = async () => {
    const action = data?.workflowAction?.disabled ? 'enable' : 'disable';
    if (window.confirm(`Are you sure you want to ${action} this action?`)) {
      updateWorkflowAction.mutate({
        id: workflowActionId,
        disabled: !data?.workflowAction?.disabled,
      });
    }
  };

  const loading = fetchError || fetching || updateWorkflowAction.isPending;
  const ActionForm = actionForms?.[data?.workflowAction?.action?.id] || null;

  const itemNotFound = useItemNotFound({
    fetching,
    id: data?.workflowAction?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={workflowActionId} type="Action" />;
  }

  return (
    <ModalInner>
      <ModalTitle
        title="Edit Action"
        icon="Lightning"
        loading={loading}
        renderActions={() => (
          <Button
            onClick={handleRemove}
            loading={removeWorkflowAction.isPending}
            variant="secondary"
            size="xs"
            icon="Trash"
            disabled={data?.workflowAction?.deleted}
          >
            Remove
          </Button>
        )}
      />
      <>
        <div className="p-4">
          {fetchError && (
            <Message variant="danger" title="Couldn't Load Action">
              {fetchError.message}
            </Message>
          )}

          <Card variant="secondary">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Icon
                  name={actionIcon(data?.workflowAction?.action?.id)}
                  color="gradient"
                  className="mr-2"
                />
                <Text>{data?.workflowAction?.action?.name}</Text>
              </div>

              {data?.workflowAction?.id && (
                <Toggle
                  label="Enabled"
                  checked={!data?.workflowAction?.disabled}
                  onChange={(e, checked) => handleToggleDisabled(checked)}
                />
              )}
            </div>
          </Card>
        </div>

        {fetching && (
          <ModalBody>
            <Card variant="secondary">
              <FlexCenter>
                <Spinner />
              </FlexCenter>
            </Card>
          </ModalBody>
        )}

        {!fetching && ActionForm && (
          <ActionForm workflowActionId={workflowActionId} onClose={handleClose} />
        )}
      </>
    </ModalInner>
  );
}
