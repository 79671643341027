export default [
  {
    id: 'name',
    name: 'Name',
    sortable: true,
    sortKey: 'name',
    queryFragment: 'name',
  },
  {
    id: 'tags',
    name: 'Tags',
    sortable: false,
    queryFragment: `tags {
      data {
        tag {
          id
          name
        }
      }
    }`,
  },
  {
    id: 'firmwareVersion',
    name: 'Firmware',
    sortable: true,
    sortKey: 'firmware.version',
    queryFragment: `firmware {
      version
      releaseNotes
      updateAvailable
      isUpdating
      latestAvailableFirmware {
        version
        releaseNotes
      }
    }`,
  },
  {
    id: 'workflows',
    name: 'Workflows',
    sortable: false,
    queryFragment: `workflows {
      data {
        id
        name
      }
    }`,
  },
  {
    id: 'skills',
    name: 'Skills',
    sortable: false,
    queryFragment: `skills: workflowSkills {
      data {
        id
        name
        version {
          version
        }
        skill {
          id
          name
          icon
          iconUrl
          color
        }
      }
    }`,
  },
  {
    id: 'location',
    name: 'Location',
    sortable: false,
    queryFragment: `location {
      name
      lat
      lng
      address {
        address
        locality
      }
    }`,
  },
  {
    id: 'downtime',
    name: 'Downtime',
    sortable: true,
    sortKey: 'downtime.downAt',
    queryFragment: `downtime {
      down
      occurrences
      downAt
      recoveredAt
    }`,
  },
  {
    id: 'connectionStatus',
    name: 'Connection',
    sortable: false,
    queryFragment: 'status',
  },
  {
    id: 'diskStatus',
    name: 'Disk',
    sortable: true,
    sortKey: 'disk.availablePercent',
    queryFragment: `disk {
      usage
    }`,
  },
  {
    id: 'memoryStatus',
    name: 'Memory',
    sortable: true,
    sortKey: 'ram.availablePercent',
    queryFragment: `ram {
      usage
    }`,
  },
  {
    id: 'cpuStatus',
    name: 'CPU',
    sortable: true,
    sortKey: 'cpu.avgLoadPercent15Min',
    queryFragment: `cpu {
      usage
    }`,
  },
  {
    id: 'ip',
    name: 'IP Address',
    sortable: false,
    queryFragment: 'ip',
  },
  {
    id: 'mac',
    name: 'MAC Address',
    sortable: false,
    queryFragment: 'mac',
  },
  {
    id: 'headphoneJack',
    name: 'Audio Connection',
    sortable: false,
    queryFragment: 'headphoneJack',
  },
  {
    id: 'internalTemperature',
    name: 'Internal Temperature',
    sortable: false,
    queryFragment: 'internalTemperatureUsage',
  },
  {
    id: 'serialNumber',
    name: 'Serial Number',
    sortable: true,
    sortKey: 'serialNumber',
    queryFragment: 'serialNumber',
  },
  {
    id: 'warrantyStartedAt',
    name: 'Enrollment Date',
    sortable: false,
    queryFragment: `provisionedDevice {
      warrantyStartedAt
    }`,
  },
];
