import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Avatar, Card, Button, Text } from '@optra/kit';

import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import UserFormFields from 'components/user-form-fields';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus, useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useOrganizationMember, useRoles } from 'queries';

export default function EditUser() {
  const { userId } = useParams();
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    register,
    reset,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const [error, setError] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);
  useInputFocus(setFocus, 'email');

  const { isLoading: fetching, isSuccess, error: fetchError, data } = useOrganizationMember(userId);

  const roles = useRoles();

  const qc = q.useQueryClient();
  const updateUser = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateOrganizationMember($form: updateOrganizationMemberForm!) {
          updateOrganizationMember(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['organizationMembers'] });
      qc.invalidateQueries({ queryKey: ['organizationMember', userId] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const removeUser = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeOrganizationMember($id: ID!) {
          removeOrganizationMember(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['organizationMembers'] });
      handleClose();
    },
  });

  useOnSuccess(
    () => {
      setSelectedRoles(data?.organizationMember?.roles);
      reset({
        email: data?.organizationMember?.email,
      });
    },
    { isSuccess },
    [data, setSelectedRoles, reset],
  );

  const handleSubmit = onSubmit(form => {
    setError(null);
    updateUser.mutate({
      id: userId,
      ...form,
      roles: selectedRoles,
    });
  });

  const handleDelete = async event => {
    event.stopPropagation();
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this user?')) {
      removeUser.mutate(userId);
    }
  };

  const loading = fetchError || fetching || updateUser.isPending;

  const itemNotFound = useItemNotFound({
    fetching,
    id: data?.organizationMember?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={userId} type="User" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Edit User"
        icon="Users"
        loading={loading}
        renderActions={() => (
          <Button
            size="xs"
            variant="secondary"
            onClick={handleDelete}
            loading={removeUser.isPending}
            icon="Trash"
          >
            Delete
          </Button>
        )}
      />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Update User">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load User">
            {fetchError.message}
          </Message>
        )}

        <Card variant="secondary" className="flex items-center my-4">
          <Avatar src={data?.organizationMember?.user?.image} className="mr-4" />
          <div>
            <Text className="block">{data?.organizationMember?.user?.name || 'Invited User'}</Text>
            <Text size="xs" color="muted" className="block">
              {data?.organizationMember?.email}
            </Text>
          </div>
        </Card>

        <UserFormFields
          register={register}
          errors={errors}
          loading={loading}
          roles={roles?.data?.model?.list}
          selectedRoles={selectedRoles}
          onSelectRole={setSelectedRoles}
        />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
