import { flatMap } from 'lodash';

import { Button, CopyButton, IconButton, Text } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import useAccessSecrets from 'queries/use-access-secrets';

export default function UserAccessSecrets() {
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useAccessSecrets();
  const secrets = flatMap(data?.pages, page => page?.list?.data);

  const qc = q.useQueryClient();
  const generateAccessSecret = q.useMutation({
    mutationFn: () =>
      api(
        `mutation generateAccessSecret {
          accessSecret: generateAccessSecret {
            id
          }
        }`,
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['accessSecrets'] });
    },
  });

  const revokeAccessSecret = q.useMutation({
    mutationFn: id =>
      api(
        `mutation revokeAccessSecret($id: ID!) {
          accessSecret: revokeAccessSecret(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['accessSecrets'] });
    },
  });

  const handleGenerateSecret = () => {
    generateAccessSecret.mutate();
  };

  const handleRevokeSecret = id => {
    if (window.confirm('Are you sure you want to revoke this secret?')) {
      revokeAccessSecret.mutate(id);
    }
  };

  const isError = generateAccessSecret.isError || revokeAccessSecret.isError;
  const error = generateAccessSecret.error || revokeAccessSecret.error;

  return (
    <ModalInner>
      <ModalTitle title="API Keys" icon="Code" />
      <ModalBody>
        {isError && (
          <Message variant="danger" title="Failed">
            {error.message}
          </Message>
        )}

        <List loading={isLoading}>
          {secrets.length ? (
            secrets.map(item => (
              <ListItem key={item.id}>
                <div className="flex flex-row justify-between items-center space-y-2">
                  <Text variant="bold">{item.secret}</Text>
                  <div className="flex">
                    <CopyButton value={item.secret} variant="plain" />
                    <IconButton
                      name="Trash"
                      variant="plain"
                      onClick={() => handleRevokeSecret(item.id)}
                    />
                  </div>
                </div>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Text>No secrets available</Text>
            </ListItem>
          )}
        </List>
        <IntersectionLoadMore
          onVisible={fetchNextPage}
          disabled={!(secrets.length > 0) || isFetchingNextPage || !hasNextPage}
        />
        <ModalFooter>
          <Button onClick={handleGenerateSecret} loading={generateAccessSecret?.isPending}>
            Generate Key
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalInner>
  );
}
