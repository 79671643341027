import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button } from '@optra/kit';

import CameraFormFields from 'components/camera-form-fields';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus, useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useSensor } from 'queries';

export default function EditCamera() {
  const { cameraId } = useParams();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      ip: '',
      location: {
        lat: null,
        lng: null,
      },
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');
  const { handleBack } = useModalContext();

  const { data, isLoading: fetching, isSuccess, error: fetchError } = useSensor(cameraId);

  const qc = q.useQueryClient();
  const updateSensor = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateSensor($form: updateSensorForm!) {
          updateSensor(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['sensor', cameraId] });
      qc.invalidateQueries({ queryKey: ['device'] });
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow'] });
      handleBack();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    updateSensor.mutate({
      ...form,
      id: cameraId,
    });
  });

  useOnSuccess(
    () => {
      reset({
        name: data?.sensor?.name,
        ip: data?.sensor?.ip,
        location: {
          lat: data?.sensor?.location?.lat,
          lng: data?.sensor?.location?.lng,
        },
      });
    },
    { isSuccess },
    [data, reset],
  );

  const loading = fetchError || fetching || updateSensor.isPending;

  const itemNotFound = useItemNotFound({
    fetching,
    id: data?.sensor?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={cameraId} type="Camera" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Edit Camera" icon="VideoCamera" loading={loading} />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Update Camera">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Camera">
            {fetchError.message}
          </Message>
        )}

        <CameraFormFields register={register} control={control} errors={errors} loading={loading} />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
