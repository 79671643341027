// $1 USD = 10_000 OC
// $0.01 USD = 100 OC
// $0.0001 USD = 1 OC
"use strict";
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    wholeCredits: function() {
        return wholeCredits;
    },
    parseWholeCredits: function() {
        return parseWholeCredits;
    },
    creditsToFormattedString: function() {
        return creditsToFormattedString;
    },
    creditsToFormattedCurrency: function() {
        return creditsToFormattedCurrency;
    }
});
const _lodash = require("lodash");
const precision = 2; // a penny is 2 orders of magnitude larger than a credit
const pennyToDollarPrecision = 2;
function parseCredits(credits = 0) {
    const str = `${credits}`;
    const totalCents = str.slice(0, Math.max(0, str.length - precision));
    const microcents = (0, _lodash.takeRight)(str, precision).join('');
    const dollars = totalCents.slice(0, Math.max(0, totalCents.length - pennyToDollarPrecision));
    const cents = (0, _lodash.takeRight)(totalCents, pennyToDollarPrecision).join('');
    const totalDollars = `${dollars}.${cents}`;
    return {
        dollars: Number(dollars),
        cents: Number(cents),
        totalCents: Number(totalCents),
        totalDollars: Number(totalDollars),
        microcents: Number(microcents),
        credits: Number(totalCents),
        creditsCents: Number(microcents)
    };
}
function wholeCredits(amount) {
    return amount * 100;
}
function parseWholeCredits(credits = 0) {
    const creditObject = parseCredits(credits);
    const result = creditObject.credits + creditObject.microcents / 100;
    return result.toLocaleString();
}
function creditsToFormattedString(credits = 0) {
    return parseCredits(credits).credits.toLocaleString();
}
function creditsToFormattedCurrency(credits = 0) {
    return parseCredits(credits).totalDollars.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });
}
