import { get, isEmpty } from 'lodash';
import set from 'lodash/fp/set';
import { useState, useRef } from 'react';
import { useDebounce } from 'react-use';

import { useSearchParams } from 'hooks';

export default function useDebouncedState(defaultValue, _ops = {}) {
  const ops = useRef({
    storeInRoute: false,
    delayMS: 500,
    ..._ops,
  });
  const { storeInRoute, delayMS } = ops.current;

  const [searchParams, , createSearchParamSetter] = useSearchParams(
    set(storeInRoute, defaultValue, {}),
  );
  const searchValue = get(searchParams, storeInRoute);
  const setSearchValue = createSearchParamSetter(storeInRoute);

  const [stateValue, setStateValue] = useState(defaultValue);

  const [value, setValue] = !isEmpty(storeInRoute)
    ? [searchValue, setSearchValue]
    : [stateValue, setStateValue];

  const [$value, set$value] = useState(value);
  useDebounce(
    () => {
      set$value(value);
    },
    delayMS,
    [value],
  );

  return [$value, setValue, value];
}
