import { MagnifyingGlass } from '@phosphor-icons/react';
import cx from 'classnames';
import { isEmpty, isString } from 'lodash';
import { useRef, useState } from 'react';
import { useKey } from 'react-use';

import {
  Button,
  Icon,
  Spinner,
  Heading,
  DROPDOWN_MENU_PLACEMENT,
  DetailHeading,
  Dropdown,
  Filter,
  UiState,
  Tooltip,
  useBreakpoint,
  SearchField,
} from '@optra/kit';

import DevicesBulkActions from 'components/devices-bulk-actions';
import { useFeature } from 'components/feature';
import devicesTableColumns from 'config/devices-table-columns';
import useDevicesColumns from 'hooks/use-devices-columns';
import useMapStateOutput from 'hooks/use-mapState-output';
import pluralize from 'lib/pluralize';
import useOutputs from 'queries/use-outputs';

function SelectionLabel({ selectedDeviceIds, selectAll, numberOfDevices, devicesOmitted }) {
  // No selection
  if (isEmpty(selectedDeviceIds) && !selectAll) {
    return `${numberOfDevices} ${pluralize('Device', numberOfDevices)}`;
  }

  const numDevicesSelected = selectAll
    ? numberOfDevices - devicesOmitted.length
    : selectedDeviceIds.length;

  const selectCountLabel =
    (selectAll && isEmpty(devicesOmitted)) || selectedDeviceIds.length === numberOfDevices ? (
      <span className="italic">All </span>
    ) : (
      <>
        {numDevicesSelected}
        <span className="italic"> of </span>
      </>
    );

  // Only show the count iff there are selections
  const somethingSelected =
    (selectAll && devicesOmitted.length < numberOfDevices) || selectedDeviceIds.length > 0;

  // Don't show the count if there's only one result and it's selected
  const onlyItemSelected = numberOfDevices === 1 && numberOfDevices === selectedDeviceIds.length;

  const doShowCount = somethingSelected && !onlyItemSelected;

  return (
    <span>
      {doShowCount && selectCountLabel}
      {numberOfDevices} {pluralize('Device', numberOfDevices)}{' '}
      {somethingSelected && <span className="italic">selected</span>}
    </span>
  );
}

export default function DevicesHeaderBar(props) {
  const {
    searching = true,
    filter,
    filterActive = false,
    sort,
    numberOfDevices,
    onClearFilter,
    onSearch,
    search,
    selectedDeviceIds = [],
    selectAll = false,
    devicesOmitted = [],
  } = props;
  const [selectedOutput, setSelectedOutput] = useMapStateOutput();
  const [visibleColumns, , { toggleColumn }] = useDevicesColumns();
  const Outputs = useOutputs({ filter: { tracked: true } });

  const outputColumns = (Outputs.data || []).map(output => ({
    id: output.id,
    key: `outputs.${output.key}`,
    name: output.label,
    sortKey: `outputs.${output.key}`,
    sortable: true,
    mapping: output.mapping,
    queryFragment: `outputs: latestOutputs {
      id
      key
      name
      value
      type
      healthy
    }`,
  }));

  const skillInputOutputEnabled = useFeature('skillInputOutput');

  const searchInputRef = useRef(null);
  useKey('/', e => {
    if (
      searchInputRef.current !== document.activeElement &&
      document.activeElement.tagName !== 'INPUT'
    ) {
      searchInputRef.current.focus();
      e.preventDefault();
    }
  });

  const [columnSearchTerm, setColumnSearchTerm] = useState('');

  const { thresholds } = useBreakpoint();
  const isMobile = !thresholds.lg;
  const deviceConnectionEnabled = useFeature('deviceConnection');

  const calculateFilteredColumns = columns => {
    if (!columns || !Array.isArray(columns)) return [];
    if (!deviceConnectionEnabled) {
      columns = columns.filter(
        column => column.id !== 'connectionStatus' && column.id !== 'downtime',
      );
    }
    if (!columnSearchTerm || !isString(columnSearchTerm)) return columns;

    return columns.filter(column =>
      `${column.name}${column.skill?.name ? ` - ${column.skill.name}` : ''}`
        ?.toLowerCase()
        .includes(columnSearchTerm.toLowerCase()),
    );
  };

  const filteredTableColumnOptions = calculateFilteredColumns(devicesTableColumns);
  const filteredOutputColumnOptions = calculateFilteredColumns(outputColumns);
  const allFilteredColumnOptions = [...filteredTableColumnOptions, ...filteredOutputColumnOptions];

  return (
    <div
      className={cx(
        'flex',
        isMobile ? 'space-y-4 flex-col' : 'space-x-4 items-center justify-between',
      )}
    >
      <div>
        <SearchField
          searching={searching}
          value={search}
          onChange={onSearch}
          placeholder="Search Devices…"
          ref={searchInputRef}
        />
      </div>

      <div className="flex flex-1 justify-start">
        <Heading level={3} className="mr-2">
          {searching ? (
            <Spinner />
          ) : (
            <SelectionLabel
              selectedDeviceIds={selectedDeviceIds}
              selectAll={selectAll}
              numberOfDevices={numberOfDevices}
              devicesOmitted={devicesOmitted}
            />
          )}
        </Heading>

        {filterActive && (
          <Button variant="secondary" size="xs" onClick={onClearFilter} icon="XCircle">
            Clear Filters
          </Button>
        )}
      </div>

      {(!isEmpty(selectedDeviceIds) || selectAll) && (
        <DevicesBulkActions
          {...{ selectedDeviceIds, selectAll, filter, sort, devicesOmitted, numberOfDevices }}
        />
      )}

      <Filter
        variant="plain"
        menu={{
          text: selectedOutput.key === '' ? 'Connection' : selectedOutput.label,
          body: (
            <>
              <Dropdown.Item
                text="Connection Status"
                active={selectedOutput.key === ''}
                onClick={() => setSelectedOutput({ label: 'Connection', key: '' })}
              />
              {Outputs.data?.map(output => (
                <Dropdown.Item
                  key={output.id}
                  text={output.key}
                  active={selectedOutput.key === output.key}
                  onClick={() => setSelectedOutput({ label: output.label, key: output.key })}
                />
              ))}
            </>
          ),
        }}
        placement={
          isMobile ? DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT : DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT
        }
      />

      {!isMobile && (
        <Dropdown placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}>
          <Dropdown.Button unstyled>
            {({ isOpen }) => (
              <Tooltip label="Columns">
                <Button as="span" icon="Table" size="sm" variant="secondary">
                  <Icon
                    name={isOpen ? 'CaretUp' : 'CaretDown'}
                    weight="regular"
                    size="xs"
                    className="ml-1"
                  />
                </Button>
              </Tooltip>
            )}
          </Dropdown.Button>
          <div className="p-4">
            <SearchField
              searching={false}
              value={columnSearchTerm}
              onChange={setColumnSearchTerm}
              placeholder="Search Outputs…"
              className="dark:bg-dark-bg-tertiary"
              ref={searchInputRef}
            />
          </div>
          {!!allFilteredColumnOptions?.length ? (
            <div
              className={cx(
                'flex flex-row flex-nowrap',
                'border-t border-light-fg-tertiary dark:border-gray-200/10',
                'divide-x divide-light-fg-tertiary dark:divide-gray-200/10',
              )}
            >
              {!isEmpty(filteredTableColumnOptions) && (
                <Dropdown.MenuBody divide={false} scrolling className="p-5">
                  <DetailHeading variant="decorated" className="px-4 mb-4">
                    GENERAL
                  </DetailHeading>
                  {filteredTableColumnOptions.map(column => (
                    <Dropdown.Item
                      key={column.id}
                      onClick={e => {
                        toggleColumn(column);
                        e.preventDefault();
                      }}
                      selectable={true}
                      active={visibleColumns.find(col => col.id === column.id)}
                      text={column.name}
                      className="rounded-md"
                    />
                  ))}
                </Dropdown.MenuBody>
              )}
              {!isEmpty(filteredOutputColumnOptions) && skillInputOutputEnabled && (
                <Dropdown.MenuBody divide={false} scrolling className="p-5">
                  <DetailHeading variant="decorated" className="px-4 mb-4">
                    OUTPUTS
                  </DetailHeading>
                  {filteredOutputColumnOptions.map(column => (
                    <Dropdown.Item
                      key={column.id}
                      onClick={e => {
                        toggleColumn(column);
                        e.preventDefault();
                      }}
                      selectable
                      active={visibleColumns.find(col => col.id === column.id)}
                      text={column?.name}
                      detail={column?.skill?.name}
                      className="rounded-md"
                    />
                  ))}
                </Dropdown.MenuBody>
              )}
            </div>
          ) : (
            <Dropdown.MenuBody className="p-5">
              <UiState variant="empty" icon={{ component: MagnifyingGlass }} />
            </Dropdown.MenuBody>
          )}
        </Dropdown>
      )}
    </div>
  );
}
