import React, { useState, useEffect, createContext, useContext } from 'react';

const RouteTitleContext = createContext();

export function useTitleContext() {
  return useContext(RouteTitleContext);
}

export function RouteTitleProvider({ children }) {
  const [titles, setTitles] = useState([]);

  // Effect to update document title based on the last registered title
  useEffect(() => {
    if (titles.length > 0) {
      document.title = `${titles[titles.length - 1]} | Optra`;
    }
  }, [titles]);

  return (
    <RouteTitleContext.Provider value={{ titles, setTitles }}>
      {children}
    </RouteTitleContext.Provider>
  );
}

export default function RouteTitle({ title, children }) {
  const { setTitles } = useTitleContext();

  useEffect(() => {
    setTitles(prevTitles => [...prevTitles, title]);

    // Cleanup to remove the title when the component unmounts
    return () => {
      setTitles(prevTitles => prevTitles.filter(t => t !== title));
    };
  }, [title, setTitles]);

  return children;
}
