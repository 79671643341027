import { Button, Card, Text, Icon } from '@optra/kit';

import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';

export default function AdminUpdateChartRetentionPeriod(props) {
  const { organizationId, retentionPeriod, isDestructive } = props;
  const { handleClose } = useModalContext();

  const handleSubmit = () => {
    return removeTables.mutate(null, {
      onSuccess() {
        handleClose();
      },
    });
  };

  const removeTables = q.useMutation({
    mutationFn: () =>
      api(
        `mutation updateChartRetentionPeriod($form: updateChartRetentionPeriodForm!) {
          owner: updateChartRetentionPeriod(form: $form) {
            ... on UserProfile {
              id
            }
            ... on Organization {
              id
            }
          }
        }`,
        {
          form: {
            ownerId: organizationId,
            retentionPeriod,
          },
        },
      ),
  });
  return (
    <>
      <ModalTitle title="Reset Data" icon="Danger" showBackButton={false} />
      <ModalBody>
        {removeTables.isError && (
          <Message variant="danger" title="Couldn't Perform Action">
            {removeTables.error.message}
          </Message>
        )}
        <Card variant="secondary">
          <div className="flex flex-row items-center space-x-4">
            <Icon name="Warning" weight="duotone" color="red" size="xl" />
            <Text size="lg" color="red">
              Are you sure?
              {isDestructive && (
                <>
                  <br />
                  This will reset all event and telemetry data for your organization.
                </>
              )}
            </Text>
          </div>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="danger"
          type="submit"
          onClick={handleSubmit}
          size="xl"
          loading={removeTables.isPending}
        >
          Reset Retention Period
        </Button>
      </ModalFooter>
    </>
  );
}
