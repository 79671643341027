import { Route, Routes } from 'react-router-dom';

import CreateTag from 'components/create-tag';
import EditTag from 'components/edit-tag';
import ModalInner from 'components/modal-inner';
import ManageTagsOverview from 'modals/manage-tags-overview';
import RouteTitle from 'router/RouteTitle';

export default function ManageTags() {
  return (
    <ModalInner>
      <Routes>
        <Route
          index
          element={
            <RouteTitle title="Manage Tags">
              <ManageTagsOverview />
            </RouteTitle>
          }
        />
        <Route
          path="new"
          element={
            <RouteTitle title="New Tags">
              <CreateTag />
            </RouteTitle>
          }
        />
        <Route
          path=":tagId/edit"
          element={
            <RouteTitle title="Edit Tag">
              <EditTag />
            </RouteTitle>
          }
        />
      </Routes>
    </ModalInner>
  );
}
