import { useParams, Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { Button, Icon, Text } from '@optra/kit';

import DeviceDetailCameras from 'components/device-detail-cameras';
import DeviceDetailEdit from 'components/device-detail-edit';
import DeviceDetailLogs from 'components/device-detail-logs';
import DeviceDetailOverview from 'components/device-detail-overview';
import DeviceDetailSkills from 'components/device-detail-skills';
import Message from 'components/message';
import Modal, { useModalContext } from 'components/modal';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Tab from 'components/tab';
import Tabs from 'components/tabs';
import { api, q } from 'config/api';
import { useItemNotFound } from 'hooks';
import AdminCreateFirmware from 'modals/admin-create-firmware';
import CreateCamera from 'modals/create-camera';
import DeviceFirmware from 'modals/device-firmware';
import EditCamera from 'modals/edit-camera';
import EWSController from 'modals/ews-controller';
import ItemNotFound from 'modals/item-not-found';
import ManageLocations from 'modals/manage-locations';
import ManageTags from 'modals/manage-tags';
import ReplaceDevice from 'modals/replace-device';
import SkillWebUI from 'modals/skill-web-ui';
import SSHController from 'modals/ssh-controller';
import { useDevice, useProfile } from 'queries';
import RouteTitle from 'router/RouteTitle';

import Role from '../components/role';
import ProtectedRoute from '../router/protected-route';

import DeviceDetailSubscription from './device-detail-subscription';

export default function DeviceDetail() {
  const { deviceId, workflowId } = useParams();
  const { handleClose } = useModalContext();
  const Profile = useProfile();
  const isCreditOwner = Profile.data?.currentOwner?.pricing === 'credit';

  const {
    data,
    isLoading: fetching,
    error: fetchError,
  } = useDevice(
    deviceId,
    `
      id
      name
      family
      autoClaim
      provisionedDevice {
        returnAuthorization
      }
      subscriptionActive
      firmware {
        firmware {
          name
          buildTarget
          iotedgeVersion
          releaseNotes
        }
      }
    `,
  );

  const qc = q.useQueryClient();
  const removeWorkflowDevice = q.useMutation({
    mutationFn: form =>
      api(
        `mutation removeWorkflowDevice($form: removeWorkflowDeviceForm!) {
          workflowDevice: removeWorkflowDevice(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
      handleClose();
    },
  });

  const handleRemove = async event => {
    event.stopPropagation();
    event.preventDefault();
    if (window.confirm('Are you sure you want to remove this device from its workflow?')) {
      removeWorkflowDevice.mutate({
        workflowId,
        deviceId,
      });
    }
  };

  const loading = fetching;

  const itemNotFound = useItemNotFound({
    fetching: loading,
    id: data?.device?.id,
  });

  const subscriptionExpired =
    !fetching && isCreditOwner && data?.device?.subscriptionActive !== true;

  let render;
  if (itemNotFound) {
    render = <ItemNotFound id={deviceId} type="Device" />;
  } else {
    render = (
      <>
        <ModalTitle
          title={data?.device?.name}
          icon="Aperture"
          loading={loading}
          renderActions={() =>
            workflowId && (
              <Button
                icon="Trash"
                size="xs"
                variant="secondary"
                onClick={handleRemove}
                loading={removeWorkflowDevice.isPending}
              >
                Remove
              </Button>
            )
          }
        />

        {data?.device?.provisionedDevice?.returnAuthorization && (
          <div className="bg-secondary p-3 md:flex items-center justify-between space-y-2">
            <div className="md:flex items-center md:space-x-2">
              <Icon name="Lightning" size="xs" />
              <Text>RMA Number Generated:</Text>
              <Text className="font-medium">
                {data?.device?.provisionedDevice?.returnAuthorization}
              </Text>
            </div>
            <Button
              size="xs"
              variant="plain"
              to="./edit/replace"
              state={{ fromModal: true }}
              icon="Question"
              className="border border-white border-solid"
            >
              View Details
            </Button>
          </div>
        )}

        {data?.device?.autoClaim && (
          <div className="bg-blue text-white p-3 md:flex items-center justify-between space-y-2">
            <div className="md:flex items-center md:space-x-2">
              <Icon name="Clock" size="xs" />
              <Text>Device Pending Enrollment</Text>
            </div>
          </div>
        )}

        {subscriptionExpired && (
          <div className="bg-red text-white p-3 md:flex items-center justify-between space-y-2">
            <div className="md:flex items-center md:space-x-2">
              <Icon name="Warning" size="xs" />
              <div className="flex flex-col">
                <Text className="font-medium">Subscription Expired</Text>
                <Text size="sm">
                  The Optra Portal Subscription for this device is no longer active. Would you like
                  to re-subscribe?
                </Text>
              </div>
            </div>
            <Button
              size="xs"
              variant="plain"
              to="./subscribe"
              state={{ fromModal: true }}
              icon="Check"
              className="border border-white border-solid"
            >
              Subscribe
            </Button>
          </div>
        )}

        <Tabs className="p-4">
          <Tab to="./overview" icon="SquaresFour">
            Overview
          </Tab>
          <Tab to="./skills" icon="Cube">
            Skills
          </Tab>
          {data?.device?.family === 'vision' && (
            <Role
              roles={[
                'admin',
                'deviceTechnician',
                'deviceEnroller',
                'workflowEditor',
                'workflowDeployer',
              ]}
            >
              <Tab to="./cameras" icon="VideoCamera">
                Cameras
              </Tab>
            </Role>
          )}
          <Role
            roles={[
              'admin',
              'deviceTechnician',
              'deviceEnroller',
              'workflowEditor',
              'workflowDeployer',
            ]}
          >
            <Tab to="./edit" icon="Gear">
              Settings
            </Tab>
          </Role>
          <Tab to="./logs" icon="Terminal">
            Logs
          </Tab>
        </Tabs>

        {fetchError && (
          <Message variant="danger" title="Error Loading Device">
            {fetchError.message}
          </Message>
        )}
      </>
    );
  }

  return (
    <Routes>
      <Route
        path="*"
        element={
          <ModalInner>
            {render}
            <Outlet />
          </ModalInner>
        }
      >
        <Route index element={<Navigate to="./overview" replace />} />
        <Route
          path="overview/*"
          element={
            <RouteTitle title="Device Overview">
              <DeviceDetailOverview deviceId={deviceId} />
              <Outlet />
            </RouteTitle>
          }
        >
          <Route
            path="firmware"
            element={
              <RouteTitle title="Device Firmware">
                <Modal>
                  <DeviceFirmware />
                </Modal>
              </RouteTitle>
            }
          />
          <Route
            path="firmware/override"
            element={
              <RouteTitle title="Override Firmware">
                <Modal>
                  <AdminCreateFirmware
                    override
                    defaultValues={data?.device?.firmware?.firmware}
                    isLoadingDefaults={fetching}
                    deviceId={deviceId}
                  />
                </Modal>
              </RouteTitle>
            }
          />
          <Route
            path="ews"
            element={
              <RouteTitle title="Control Panel">
                <Modal>
                  <EWSController />
                </Modal>
              </RouteTitle>
            }
          />
        </Route>
        <Route path="skills">
          <Route
            index
            element={
              <RouteTitle title="Device Skills">
                <DeviceDetailSkills deviceId={deviceId} />
              </RouteTitle>
            }
          />
          <Route
            path=":workflowSkillId/web-ui"
            element={
              <RouteTitle title="Device Skill">
                <Modal>
                  <SkillWebUI />
                </Modal>
              </RouteTitle>
            }
          />
        </Route>
        <Route
          path="edit"
          element={
            <RouteTitle title="Device Settings">
              <ProtectedRoute
                authenticate
                orgMemberRoles={[
                  'admin',
                  'deviceEnroller',
                  'deviceTechnician',
                  'workflowEditor',
                  'workflowDeployer',
                ]}
                element={
                  <>
                    <DeviceDetailEdit deviceId={deviceId} onClose={handleClose} />
                    <Outlet />
                  </>
                }
              />
            </RouteTitle>
          }
        >
          <Route
            path="manage-tags/*"
            element={
              <RouteTitle title="Manage Tags Overview">
                <Modal>
                  <ManageTags />
                </Modal>
              </RouteTitle>
            }
          />
          <Route
            path="manage-locations/*"
            element={
              <RouteTitle title="Manage Locations">
                <Modal>
                  <ManageLocations />
                </Modal>
              </RouteTitle>
            }
          />
          <Route
            path="replace"
            element={
              <RouteTitle title="Replace Device">
                <Modal>
                  <ReplaceDevice />
                </Modal>
              </RouteTitle>
            }
          />
        </Route>
        <Route
          path="cameras"
          element={
            <RouteTitle title="Device Cameras">
              <ProtectedRoute
                authenticate
                orgMemberRoles={[
                  'admin',
                  'deviceEnroller',
                  'deviceTechnician',
                  'workflowEditor',
                  'workflowDeployer',
                ]}
                element={
                  <>
                    <DeviceDetailCameras deviceId={deviceId} />
                    <Outlet />
                  </>
                }
              />
            </RouteTitle>
          }
        >
          <Route
            path="create"
            element={
              <RouteTitle title="Create Camera">
                <Modal>
                  <CreateCamera />
                </Modal>
              </RouteTitle>
            }
          />
          <Route
            path=":cameraId/edit"
            element={
              <RouteTitle title="Edit Camera">
                <Modal>
                  <EditCamera />
                </Modal>
              </RouteTitle>
            }
          />
        </Route>
        <Route
          path="logs"
          element={
            <RouteTitle title="Device Logs">
              <DeviceDetailLogs deviceId={deviceId} />
              <Outlet />
            </RouteTitle>
          }
        >
          <Route
            path="ssh"
            element={
              <RouteTitle title="Control Panel">
                <Modal>
                  <SSHController />
                </Modal>
              </RouteTitle>
            }
          />
        </Route>
      </Route>
      <Route
        path="subscribe/*"
        element={
          <RouteTitle title="Resubscribe to Device">
            <Modal>
              <DeviceDetailSubscription />
            </Modal>
          </RouteTitle>
        }
      />
    </Routes>
  );
}
