import { isEmpty, noop } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Card, SearchField, Table, Text, Icon } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import useDebouncedState from 'hooks/use-debounced-state';
import useOutputs from 'queries/use-outputs';

export default function ManageOutputs(props) {
  const { onClose = noop } = props || {};

  const { handleBack } = useModalContext();

  const [$search, setSearch] = useDebouncedState('');
  const {
    data: outputs,
    isLoading,
    error,
  } = useOutputs({
    filter: {
      $search,
    },
  });

  const navigate = useNavigate();

  return (
    <ModalInner>
      <ModalTitle
        title="Manage Outputs"
        icon="MonitorPlay"
        loading={isLoading}
        handleClose={() => {
          handleBack();
          onClose();
        }}
      />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Load Tag">
            {error.message}
          </Message>
        )}
        <Card variant="secondary" className="flex items-start space-x-2">
          <SearchField
            searching={isLoading}
            value={$search}
            onChange={setSearch}
            onClear={() => setSearch('')}
            placeholder="Search Outputs"
          />
        </Card>
        {isEmpty(outputs) ? (
          <EmptyContentMessage icon="MonitorPlay" title="No Outputs" />
        ) : (
          <Table>
            <Table.Body>
              {outputs.map(output => {
                return (
                  <Table.Row
                    key={output.id}
                    caret
                    hover
                    onClick={() => navigate(`${output.key}/edit`)}
                  >
                    <Table.TD>
                      <Icon name="Output" color="gradient" className="mr-2" />
                      <Text>{output.label}</Text>
                    </Table.TD>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </ModalBody>
    </ModalInner>
  );
}
