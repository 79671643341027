import { api, q } from 'config/api';

export default function useCurrentOwnerHasTables() {
  return q.useQuery({
    queryKey: ['currentOwnerHasTables'],
    queryFn: () =>
      api(
        `query currentOwnerHasTables {
        hasTables: currentOwnerHasTables
      }`,
      ),
    select: data => data?.hasTables,
  });
}
