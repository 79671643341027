import { useParams, useNavigate } from 'react-router-dom';

import DeviceSubscription from 'components/device-subscription';
import { useModalContext } from 'components/modal';
import ModalTitle from 'components/modal-title';

export default function DeviceCreateSubscription() {
  const { handleClose, handleBack, showBackButton } = useModalContext();
  const { provisionedDeviceId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <ModalTitle
        title="Enroll Device"
        icon="Aperture"
        handleBack={handleBack}
        showBackButton={showBackButton}
        handleClose={() => {
          if (window.confirm('Are you sure you want to cancel the enrollment process?')) {
            handleClose();
          }
        }}
      />
      <DeviceSubscription
        provisionedDeviceId={provisionedDeviceId}
        onComplete={() => {
          navigate({
            pathname: `../${provisionedDeviceId}/details`,
            state: {
              fromModal: true,
            },
          });
        }}
      />
    </>
  );
}
