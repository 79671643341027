import { useParams } from 'react-router-dom';

import { Icon } from '@optra/kit';

import MarketplaceSkillHeader from 'components/marketplace-skill-header';
import MarketplaceSkillInstalled from 'components/marketplace-skill-installed';
import MarketplaceSkillListing from 'components/marketplace-skill-listing';
import MarketplaceSkillManual from 'components/marketplace-skill-manual';
import MarketplaceSkillTabs from 'components/marketplace-skill-tabs';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import { useSearchParams } from 'hooks';
import { useMarketplaceSkill } from 'queries';

export default function MarketplaceDetail() {
  const { marketplaceSkillId } = useParams();
  const { showBackButton, handleBack, handleClose } = useModalContext();
  const [{ tab = 'listing' }] = useSearchParams();

  const { data, isLoading: fetching, error: fetchError } = useMarketplaceSkill(marketplaceSkillId);

  return (
    <ModalInner style={{ maxHeight: '100vh' }}>
      <ModalBody size="lg" className="overflow-x-hidden py-0 px-0">
        <MarketplaceSkillHeader fetching={fetching} marketplaceSkill={data?.marketplaceSkill} />

        {showBackButton && (
          <button
            type="button"
            onClick={handleBack}
            className="appearance-none select-none w-6 h-6 inline-flex items-center justify-center absolute top-5 left-5 z-top"
          >
            <Icon name="CaretLeft" weight="line" />
          </button>
        )}

        <button
          type="button"
          onClick={handleClose}
          className="appearance-none select-none w-8 h-8 inline-flex items-center justify-center absolute top-5 right-5 z-top"
        >
          <Icon name="X" size="lg" />
        </button>

        <div className="px-6 lg:px-12 pb-16 pt-8 space-y-6 z-base">
          {fetchError && (
            <Message variant="danger" title="Error Loading Skill">
              {fetchError.message}
            </Message>
          )}

          <MarketplaceSkillTabs currentTab={tab} marketplaceSkill={data?.marketplaceSkill} />

          {!fetching && tab === 'installed' && (
            <MarketplaceSkillInstalled
              marketplaceSkillId={marketplaceSkillId}
              marketplaceSkill={data?.marketplaceSkill}
            />
          )}
          {!fetching && tab === 'listing' && (
            <MarketplaceSkillListing marketplaceSkill={data?.marketplaceSkill} />
          )}
          {!fetching && tab === 'manual' && (
            <MarketplaceSkillManual marketplaceSkill={data?.marketplaceSkill} />
          )}
        </div>
      </ModalBody>
    </ModalInner>
  );
}
