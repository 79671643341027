import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Card, Button } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';

export default function DuplicateWorkflow() {
  const { workflowId } = useParams();
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      name: '',
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');

  const qc = q.useQueryClient();
  const duplicateWorkflow = q.useMutation({
    mutationFn: form =>
      api(
        `mutation duplicateWorkflow($form: duplicateWorkflowForm!) {
          workflow: duplicateWorkflow(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    duplicateWorkflow.mutate({
      sourceWorkflowId: workflowId,
      ...form,
    });
  });

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Duplicate Workflow" icon="Copy" loading={duplicateWorkflow.isPending} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Create Workflow">
            {error.message}
          </Message>
        )}

        <Card variant="secondary">
          <div className="space-y-2">
            <Label htmlFor="name">Workflow Name</Label>
            <Input
              type="text"
              {...register('name', { required: 'Please enter a name.' })}
              readOnly={duplicateWorkflow.isPending}
            />
            <ValidationError errors={errors} name="name" />
          </div>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={duplicateWorkflow.isPending}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
