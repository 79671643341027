import { Controller } from 'react-hook-form';

import { Card, Icon, Text } from '@optra/kit';

import DeviceImage from 'components/device-image';
import FileInputButton from 'components/file-input-button';
import Input from 'components/input';
import Label from 'components/label';
import ValidationError from 'components/validation-error';
import modelNames from 'config/model-names';

export default function PublishSkillCompatibility(props) {
  const { form, skill, disabled } = props;
  const selectedCompatibleDeviceModels = form.watch('compatibleDeviceModels');

  return (
    <>
      <Card variant="secondary" className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="compatibleFirmwareVersions">Compatible Firmware Versions</Label>
          <Input
            type="text"
            {...form.register('compatibleFirmwareVersions', {
              required: 'Please enter a firmware version range',
            })}
            placeholder=">=0.0.0"
            readOnly={skill.isLoading}
            disabled={disabled}
          />
          <ValidationError name="compatibleFirmwareVersions" errors={form.formState.errors} />
        </div>

        <div className="space-y-2">
          <Label htmlFor="licenseUrl">License</Label>
          <Controller
            name="licenseUrl"
            control={form.control}
            render={({ field }) => <FileInputButton {...field} accept=".txt" disabled={disabled} />}
          />

          <ValidationError errors={form.formState.errors} name="licenseUrl" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="licenseUrl">Manual</Label>
          <Controller
            name="manualUrl"
            control={form.control}
            render={({ field }) => <FileInputButton {...field} accept=".pdf" disabled={disabled} />}
          />

          <ValidationError errors={form.formState.errors} name="licenseUrl" />
        </div>
      </Card>

      <Label className="pt-4">Compatible Devices</Label>
      <Card variant="secondary" noPadding className="overflow-hidden">
        <div className="space-y-2">
          <div className="divide-y divide-light-fg-tertiary dark:divide-dark-fg-tertiary">
            {['vz1000', 'vz5000', 'compute550', 'cx1000', 'cx2000'].map(model => (
              <div
                key={model}
                className="relative flex items-center justify-between p-4 hover:bg-light-bg-tertiary dark:hover:bg-dark-bg-tertiary"
              >
                <div className="flex items-center">
                  <div className="mr-2">
                    <DeviceImage model={model} size="xs" />
                  </div>
                  <Text size="xs" variant="label">
                    {modelNames[model]}
                  </Text>
                </div>
                {selectedCompatibleDeviceModels?.[model] && (
                  <Icon name="CheckCircle" size="sm" color="primary" />
                )}
                <label
                  className={`absolute inset-0 opacity-0 ${
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                >
                  <input
                    type="checkbox"
                    {...form.register(`compatibleDeviceModels.${model}`)}
                    disabled={disabled}
                  />
                </label>
              </div>
            ))}
          </div>

          <ValidationError errors={form.formState.errors} name="compatibleDeviceModels" />
        </div>
      </Card>
    </>
  );
}
