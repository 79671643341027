import { uniq, flatMap } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@optra/kit';

import DevicesChooser from 'components/devices-chooser';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import { useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useWorkflowDeviceGroup, useChooseGroupDevices } from 'queries';

export default function ChooseGroupDevices() {
  const { workflowId, groupId } = useParams();
  const [error, setError] = useState();
  const [deviceIds, setDeviceIds] = useState({
    push: [],
    pull: [],
  });
  const { handleClose } = useModalContext();

  const group = useWorkflowDeviceGroup(groupId);

  const [filter, setFilter] = useState({});
  const devicesNames = useChooseGroupDevices(workflowId, groupId, { list: { filter } });
  const devices = flatMap(devicesNames?.data?.pages, page => page?.list?.data);

  const qc = q.useQueryClient();
  const updateDeviceGroup = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflowDeviceGroup($form: updateWorkflowDeviceGroupForm!) {
          deviceGroup: updateWorkflowDeviceGroup(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflowDeviceGroup', r.deviceGroup.id] });
      qc.invalidateQueries({ queryKey: ['workflow', r.deviceGroup.workflow.id] });
      qc.invalidateQueries({ queryKey: ['workflows'] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const removeWorkflowDeviceGroup = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeWorkflowDeviceGroup($id: ID!) {
          removeWorkflowDeviceGroup(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflowDeviceGroups'] });
      handleClose();
    },
  });

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      removeWorkflowDeviceGroup.mutate(groupId);
    }
  };

  const handleSelectDevice = (selected, device) => {
    if (selected) {
      setDeviceIds({
        push: uniq(deviceIds.push.filter(d => d !== device.id)),
        pull: uniq([...deviceIds.pull, device.id]),
      });
    } else {
      setDeviceIds({
        push: uniq([...deviceIds.push, device.id]),
        pull: uniq(deviceIds.pull.filter(d => d !== device.id)),
      });
    }
  };

  const handleSave = () => {
    updateDeviceGroup.mutate({
      id: groupId,
      deviceIds,
    });
  };

  const loading = group?.isLoading || group?.error || updateDeviceGroup.isPending;

  const itemNotFound = useItemNotFound({
    fetching: group?.isLoading,
    id: group?.data?.workflowDeviceGroup?.id,
  });

  if (itemNotFound) {
    return <ItemNotFound id={groupId} type="Group" />;
  }
  return (
    <ModalInner>
      <ModalTitle
        title={group?.data?.workflowDeviceGroup?.name}
        icon="Folder"
        renderActions={() => (
          <>
            <Button
              size="xs"
              variant="secondary"
              icon="Pencil"
              to={`/workflows/${workflowId}/groups/${groupId}/edit`}
              state={{ fromModal: true }}
            >
              Rename
            </Button>
            <Button
              size="xs"
              variant="secondary"
              icon="Trash"
              onClick={handleDelete}
              loading={removeWorkflowDeviceGroup.isPending}
            >
              Delete
            </Button>
          </>
        )}
      />
      <ModalBody size="lg">
        {error && (
          <Message variant="danger" title="Couldn't Save Group">
            {error.message}
          </Message>
        )}
        {group?.error && (
          <Message variant="danger" title="Couldn't Load Group">
            {group?.error.message}
          </Message>
        )}
        <DevicesChooser
          isLoading={group?.isLoading || devicesNames?.isLoading}
          devices={devices || []}
          showEnrollmentButton={devicesNames.data?.pages?.[0]?.total?.count === 0}
          showCompatibility
          error={devicesNames.error}
          hasNextPage={devicesNames?.hasNextPage}
          fetchNextPage={devicesNames?.fetchNextPage}
          isFetchingNextPage={devicesNames?.isFetchingNextPage}
          onSelectDevice={handleSelectDevice}
          badgeType="Workflow"
          filter={filter}
          onFilter={setFilter}
          newSelectedDevices={deviceIds.push}
          newDeselectedDevices={deviceIds.pull}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="xl" loading={loading} onClick={handleSave}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
