import omit from 'lodash/omit';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSearchParams } from 'hooks';
import { stringifySearchParams } from 'hooks/use-search-params';

export default function Forward({ to, omit: _omit = [] }) {
  const [queryParams] = useSearchParams();
  const forwardQueryParams = stringifySearchParams(omit(queryParams, _omit));

  if (!to) return null;

  return <Navigate to={`${to}${forwardQueryParams}`} />;
}
