import { api, q } from 'config/api';

export default function useWorkspaces(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workspaces', variables],
    queryFn({ pageParam }) {
      return api(
        `query workspaces($list: listFields) {
          list: workspaces(list: $list) {
            data {
              id
              name
              starred
              workspaceCharts {
                data {
                  chart {
                    config {
                      workflowIds
                    }
                  }
                }
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    select: data => (data?.pages || []).flatMap(page => page.list?.data || []),
    placeholderData: q.keepPreviousData,
    ...overrides,
  });
}
