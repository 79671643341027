import { Routes, Route } from 'react-router-dom';

import AddCreditsChoose from 'modals/add-credits-choose';
import AddCreditsEditCard from 'modals/add-credits-edit-card';
import AddCreditsNewCard from 'modals/add-credits-new-card';
import AddCreditsPayment from 'modals/add-credits-payment';
import RouteTitle from 'router/RouteTitle';

export default function AddCredits() {
  return (
    <Routes>
      <Route
        index
        element={
          <RouteTitle title="Add Credits">
            <AddCreditsChoose />
          </RouteTitle>
        }
      />
      {/* FUTURE ROUTE:
        <Route path="refill-settings" element={<AddCreditsRefillSettings />} />
      */}
      <Route
        path="payment"
        element={
          <RouteTitle title="Add Credits">
            <AddCreditsPayment />
          </RouteTitle>
        }
      />
      <Route
        path="edit-card/:paymentMethodId"
        element={
          <RouteTitle title="Edit Credit Card">
            <AddCreditsEditCard />
          </RouteTitle>
        }
      />
      <Route
        path="new-card"
        element={
          <RouteTitle title="New Credit Card">
            <AddCreditsNewCard />
          </RouteTitle>
        }
      />
    </Routes>
  );
}
