import { Cube } from '@phosphor-icons/react';
import cx from 'classnames';
import { flatMap, isEmpty } from 'lodash';
import { useState } from 'react';
import { useDebounce } from 'react-use';

import {
  PageWrapper,
  DetailHeading,
  PrimaryCTAButton,
  UiState,
  Button,
  Icon,
  Text,
  SearchField,
} from '@optra/kit';

import Feature from 'components/feature';
import FeaturedSkills from 'components/featured-skills';
import IntersectionLoadMore from 'components/intersection-load-more';
import PinPageButton from 'components/pin-page-button';
import SkillBuilderSkills from 'components/skillbuilder-skills';
import SkillsList from 'components/skills-list';
import SkillsSubNav from 'components/skills-sub-nav';
import { useHasRoles, useSearchParams } from 'hooks';
import { useLibrarySkills } from 'queries';

export default function Skills() {
  const [searchParams, , createSearchParamSetter] = useSearchParams({
    search: '',
  });
  const { search } = searchParams;
  const setSearch = createSearchParamSetter('search');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const librarySkills = useLibrarySkills({
    list: {
      sort: { direction: 'desc' },
      ...(!isEmpty(debouncedSearch) ? { filter: { $search: debouncedSearch } } : {}),
    },
  });
  const skills = flatMap(librarySkills.data?.pages, page => page?.list?.data);

  const [canCreateSkill] = useHasRoles(['admin', 'workflowDeployer']);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  return (
    <PageWrapper
      icon="Cube"
      heading="Skills Library"
      components={{
        subnav: <SkillsSubNav />,
        actions: (
          <>
            <PinPageButton />
            {canCreateSkill && (
              <Feature
                authorizedFlags={['skillBuilder', 'dockerCompose']}
                renderOff={() => (
                  <PrimaryCTAButton to="/skills/create/docker-image">New Skill</PrimaryCTAButton>
                )}
              >
                <>
                  <PrimaryCTAButton to="/skills/create/options">New Skill</PrimaryCTAButton>
                </>
              </Feature>
            )}
          </>
        ),
        controls: (
          <div
            className={cx(
              'flex flex-col items-center',
              'md:flex-row md:items-center md:justify-between space-x-6',
            )}
          >
            <div className="w-full md:w-auto">
              <SearchField
                searching={librarySkills.isRefetching && !librarySkills.isFetchedAfterMount}
                value={search}
                onChange={setSearch}
                placeholder="Search Skills…"
                count={skills?.length}
              />
            </div>
          </div>
        ),
        rightToggle: (
          <Button variant="secondary" size="sm" as="span">
            More
          </Button>
        ),
        right: (
          <div className="w-64">
            {!librarySkills.isLoading && (
              <div className="animate-fade-in space-y-8">
                <Feature feature="skillsMarketplace">
                  <FeaturedSkills />
                </Feature>
                <Feature feature="skillBuilder">
                  <SkillBuilderSkills />
                </Feature>
                <div>
                  <DetailHeading className="mb-6" variant="decorated">
                    Developer Resources
                  </DetailHeading>
                  <div className="divide-y divide-light-fg-tertiary dark:divide-dark-bg-secondary">
                    <a
                      href="https://github.com/LexmarkVentures/optra-sample-java"
                      target="_blank"
                      rel="noreferrer"
                      className="flex flex-row items-center justify-between py-3"
                    >
                      <div className="flex flex-row items-center">
                        <div className="flex items-center justify-center w-10 h-10 rounded bg-gradient-to-r from-primary-500 to-primary-400 mr-2">
                          <Icon name="FileText" size="sm" color="white" />
                        </div>
                        <Text size="sm">Sample Skill Repository</Text>
                      </div>
                      <Icon name="CaretRight" weight="light" />
                    </a>

                    <a
                      href="https://optra.com/support"
                      target="_blank"
                      rel="noreferrer"
                      className="flex flex-row items-center justify-between py-3"
                    >
                      <div className="flex flex-row items-center">
                        <div className="flex items-center justify-center w-10 h-10 rounded bg-gradient-to-r from-primary-500 to-primary-400 mr-2">
                          <Icon name="PaperPlaneTilt" size="sm" color="white" />
                        </div>
                        <Text size="sm">Contact Support</Text>
                      </div>
                      <Icon name="CaretRight" weight="light" />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        ),
      }}
      loading={librarySkills.isLoading}
      error={librarySkills.error}
    >
      {!!skills?.length ? (
        <SkillsList skills={skills} />
      ) : (
        <UiState
          variant="empty"
          icon={{ component: Cube, colorClass: 'text-primary' }}
          text="No Skills"
          className="animate-fade-in"
        >
          {canCreateSkill &
          (
            <Button to="/skills/create" icon="Plus">
              Add Skill
            </Button>
          )}
        </UiState>
      )}

      <IntersectionLoadMore
        onVisible={librarySkills.fetchNextPage}
        disabled={librarySkills.isFetchingNextPage || !librarySkills.hasNextPage}
      />
    </PageWrapper>
  );
}
