import { useState } from 'react';

import { Button } from '@optra/kit';

import Message from 'components/message';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';

export default function DeleteS3AssetModal(props) {
  const { url, shouldShow } = props;
  const [error, setError] = useState();

  const handleSubmit = () => {
    setError(null);
    return deleteAsset.mutateAsync(
      { url },
      {
        onSuccess() {
          shouldShow();
        },
        onError(error) {
          setError(error);
        },
      },
    );
  };

  const qc = q.useQueryClient();
  const deleteAsset = q.useMutation({
    mutationFn: form =>
      api(
        `mutation DeleteS3Asset($url: String!) {
          deleteS3Asset(url: $url)
        }`,
        {
          url,
        },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['chartDetails'] });
      qc.refetchQueries({ queryKey: ['chartDetails'] });
      shouldShow();
    },
  });

  return (
    <Modal onClose={shouldShow}>
      <ModalTitle title="File Preview" icon="Danger" showBackButton={false} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Delete Asset">
            {error.message}
          </Message>
        )}
        <div className="flex flex-col items-center space-y-4 p-4">
          <div className="w-full max-w-md rounded-lg p-4 mt-4">
            <div className="aspect-w-16 aspect-h-9 relative">
              <img
                src={url}
                alt="Snapshot Preview"
                className="object-contain w-full h-full rounded"
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="danger"
          type="submit"
          onClick={handleSubmit}
          loading={deleteAsset.isPending}
        >
          Delete File
        </Button>
      </ModalFooter>
    </Modal>
  );
}
