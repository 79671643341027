import { SubNavList } from '@optra/kit';

import { useFeature } from 'components/feature';

import { useHasRoles } from '../hooks';

export default function SkillsSubNav({ active = 'library' }) {
  const skillBuilderEnabled = useFeature('skillBuilder');
  const marketplaceEnabled = useFeature('skillsMarketplace');
  const [canEdit] = useHasRoles([
    'admin',
    'deviceEnroller',
    'deviceTechnician',
    'workflowEditor',
    'workflowDeployer',
  ]);

  if (!skillBuilderEnabled && !marketplaceEnabled) return null;

  return (
    <SubNavList>
      <SubNavList.Item icon="Cube" active={active === 'library'} to="/skills">
        Library
      </SubNavList.Item>
      {skillBuilderEnabled && (
        <SubNavList.Item icon="Cpu" active={active === 'models'} to="/skills/models">
          <span className="flex flex-row items-center gap-1 justify-center">Models</span>
        </SubNavList.Item>
      )}
      {marketplaceEnabled && canEdit && (
        <SubNavList.Item
          icon="SquaresFour"
          active={active === 'marketplace'}
          to="/skills/marketplace"
        >
          Marketplace
        </SubNavList.Item>
      )}
    </SubNavList>
  );
}
