import { useRef, useState } from 'react';

import { Button, Card, Icon, Text } from '@optra/kit';

import Message from 'components/message';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';

export default function ResetChartButton(props) {
  const { tableId, tableName, ...btnProps } = props;
  const modalRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const qc = q.useQueryClient();
  const removeTable = q.useMutation({
    mutationFn: () =>
      api(
        `mutation resetChartData($tableId: ID!) {
          resetChartData(tableId: $tableId)
        }`,
        {
          tableId,
        },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workspace'] });
      modalRef.current.handleClose();
    },
  });

  return (
    <>
      <Button
        variant="danger"
        size="xs"
        icon="Trash"
        {...btnProps}
        onClick={() => {
          setIsVisible(true);
        }}
      >
        Reset Data
      </Button>
      {isVisible && (
        <Modal onClose={() => setIsVisible(false)} ref={modalRef}>
          <ModalTitle title="Reset Data" icon="Danger" showBackButton={false} />
          <ModalBody>
            {removeTable.isError && (
              <Message variant="danger" title="Couldn't Perform Action">
                {removeTable.error.message}
              </Message>
            )}
            <Card variant="secondary">
              <div className="flex flex-row items-center space-x-4">
                <Icon name="Warning" weight="duotone" color="red" size="xl" />
                <Text size="lg" color="red">
                  Are you sure? <br />
                  This will reset all data for
                  <span className="font-mono mx-2 bg-gray-800 px-2 py-1">{tableName}</span>
                </Text>
              </div>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="danger"
              type="submit"
              onClick={() => removeTable.mutate()}
              size="xl"
              loading={removeTable.isPending}
            >
              Reset All Data
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
