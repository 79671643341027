import { uniq } from 'lodash';
import { useEffect, useState } from 'react';

import { Button, Modal, Table, Text } from '@optra/kit';

import DeviceImage from 'components/device-image';
import DevicesChooser from 'components/devices-chooser';
import useDevicesSelector from 'queries/use-devices-selector';

export default function DevicesSelector(props) {
  const { handleSelectDevice, isOpen, reset, selectButtonText = 'Select', setIsOpen } = props;
  const [filter, setFilter] = useState({});
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [deselectedDevices, setDeselectedDevices] = useState([]);

  const updateSelectedDevices = (selected, device) => {
    if (selected) {
      setSelectedDevices(uniq(selectedDevices.filter(s => s !== device.id)));
      setDeselectedDevices(uniq([...deselectedDevices, device.id]));
    } else {
      setSelectedDevices(uniq([...selectedDevices, device.id]));
      setDeselectedDevices(uniq(deselectedDevices.filter(d => d !== device.id)));
    }
  };

  const devices = useDevicesSelector({
    list: {
      filter: { deleted: false, ...filter },
      sort: {
        by: 'name',
        direction: 'asc',
      },
    },
  });

  useEffect(() => {
    if (reset) {
      setSelectedDevices([]);
      setDeselectedDevices([]);
      setFilter({});
    }
  }, [reset]);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Header heading="Choose Devices" />
      <Modal.Body>
        <DevicesChooser
          isLoading={devices.isLoading}
          devices={devices.data}
          error={devices?.error}
          hasNextPage={devices?.hasNextPage}
          fetchNextPage={devices?.fetchNextPage}
          isFetchingNextPage={devices?.isFetchingNextPage}
          onSelectDevice={updateSelectedDevices}
          filter={filter}
          onFilter={setFilter}
          newSelectedDevices={selectedDevices}
          newDeselectedDevices={deselectedDevices}
          RowDataComponent={({ device }) => (
            <>
              <Table.TD collapsed>
                <DeviceImage model={device.model} size="sm" hideStatus />
              </Table.TD>
              <Table.TD>
                <Text size="sm">{device.name}</Text>
              </Table.TD>
            </>
          )}
        />
      </Modal.Body>
      <Modal.Footer className="justify-center">
        <Button
          size="xl"
          loading={devices?.isLoading}
          onClick={() => {
            handleSelectDevice({ push: selectedDevices, pull: deselectedDevices });
            setIsOpen(false);
          }}
        >
          {selectButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
