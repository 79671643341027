import set from 'lodash/fp/set';
import React from 'react';

import { Text, Toggle } from '@optra/kit';

import { api, q } from 'config/api';

import { useCurrentUser } from '../queries';

import ListItem from './list-item';

export default function ProfileOptionToastNotificationsListItem() {
  const [currentUser] = useCurrentUser();
  const isToastEnabled = currentUser?.profile?.toastNotificationsEnabled ?? true;

  const qc = q.useQueryClient();
  const toggleCurrentUserProfileToastNotificationsEnabled = q.useMutation({
    mutationFn: _form => {
      const form = {
        toastNotificationsEnabled: !isToastEnabled,
      };
      return api(
        `mutation updateCurrentUserProfile($form: updateCurrentUserProfileForm!) {
          updateCurrentUserProfile(form: $form) {
            toastNotificationsEnabled
          }
        }`,
        { form },
      );
    },
    onMutate: async () => {
      await qc.cancelQueries({ queryKey: ['currentUser'] });

      const next = !isToastEnabled;
      qc.setQueryData(['currentUser'], old =>
        set('currentUser.profile.toastNotificationsEnabled', next, old),
      );

      return next;
    },
    onError(err, form, prev) {
      qc.setQueryData(['currentUser'], old =>
        set('currentUser.profile.toastNotificationsEnabled', prev, old),
      );
    },
    onSettled() {
      qc.invalidateQueries({ queryKey: ['currentUser'] });
    },
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });

  function toggle(e) {
    e.stopPropagation();
    e.preventDefault();
    toggleCurrentUserProfileToastNotificationsEnabled.mutate();
  }

  return (
    <ListItem
      onClick={toggle}
      renderLeft={() => <Text>Display Toast Notifications</Text>}
      renderRight={() => (
        <Toggle
          onChange={toggle}
          checked={isToastEnabled}
          controlled
          stopPropagation
          disabled={false}
          labelPlacement="right"
          uppercase={false}
        />
      )}
    />
  );
}
