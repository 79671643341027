import { Login } from 'react-auth0rize';
import { Route, Routes, Outlet } from 'react-router-dom';

import AdminLayout from 'components/admin-layout';
import Modal from 'components/modal';
import ProfileLayout from 'components/profile-layout';
import { supportUrl } from 'config/constants';
import useVersionCheck from 'hooks/use-version-check';
import AddCredits from 'modals/add-credits';
import AddWorkflowAction from 'modals/add-workflow-action';
import AddWorkflowSkill from 'modals/add-workflow-skill';
import AdminCreateFirmware from 'modals/admin-create-firmware';
import AdminCreateInternalSkillVersion from 'modals/admin-create-internal-skill-version';
import AdminCreateOrganization from 'modals/admin-create-organization';
import AdminCreateServiceAccount from 'modals/admin-create-service-account';
import AdminCreateSuperUser from 'modals/admin-create-super-user';
import AdminEditInternalSkill from 'modals/admin-edit-internal-skill';
import AdminEditInternalSkillVersion from 'modals/admin-edit-internal-skill-version';
import AdminEditOrganization from 'modals/admin-edit-organization';
import AdminEditServiceAccount from 'modals/admin-edit-service-account';
import AdminEditUser from 'modals/admin-edit-user';
import AdminMarketplaceCreateListing from 'modals/admin-marketplace-create-listing';
import AdminMarketplaceEditListing from 'modals/admin-marketplace-edit-listing';
import AdminPreclaimDevicesUpload from 'modals/admin-preclaimed-devices-upload';
import AdminPurchaseOrderCreate from 'modals/admin-purchase-order-create';
import AdminPurchaseOrderUpdate from 'modals/admin-purchase-order-update';
import BulkCreateDevices from 'modals/bulk-create-devices';
import BulkEditWorkflow from 'modals/bulk-edit-workflow';
import ChooseGroupDevices from 'modals/choose-group-devices';
import ChooseWorkflowDevices from 'modals/choose-workflow-devices';
import CopySkill from 'modals/copy-skill';
import CopyWorkflowItems from 'modals/copy-workflow-items';
import CreateDevice from 'modals/create-device';
import CreateDeviceGroup from 'modals/create-device-group';
import CreateOrganization from 'modals/create-organization';
import CreateSkillDockerCompose from 'modals/create-skill-docker-compose';
import CreateSkillDockerComposeVersion from 'modals/create-skill-docker-compose-version';
import CreateSkillDockerImage from 'modals/create-skill-docker-image';
import CreateSkillDockerImageVersion from 'modals/create-skill-docker-image-version';
import CreateSkillOptions from 'modals/create-skill-options';
import CreateSkillTemplates from 'modals/create-skill-templates';
import CreateUser from 'modals/create-user';
import CreateWorkflow from 'modals/create-workflow';
import CreateWorkflowSkill from 'modals/create-workflow-skill';
import CreditsSettings from 'modals/credits-settings';
import DeviceDetail from 'modals/device-detail';
import DeviceDetailSubscription from 'modals/device-detail-subscription';
import DuplicateWorkflow from 'modals/duplicate-workflow';
import EditDeviceGroup from 'modals/edit-device-group';
import EditOrganization from 'modals/edit-organization';
import EditOutput from 'modals/edit-output';
import EditPublishedSkill from 'modals/edit-published-skill';
import EditSkill from 'modals/edit-skill';
import EditSkillVersion from 'modals/edit-skill-version';
import EditUser from 'modals/edit-user';
import EditWorkflow from 'modals/edit-workflow';
import EditWorkflowAction from 'modals/edit-workflow-action';
import EditWorkflowSkill from 'modals/edit-workflow-skill';
import ManageLocations from 'modals/manage-locations';
import ManageOutputs from 'modals/manage-outputs';
import MarketplaceDetail from 'modals/marketplace-detail';
import MarketplaceDetailGallery from 'modals/marketplace-detail-gallery';
import MarketplaceSubscribe from 'modals/marketplace-subscribe';
import CreateModel from 'modals/model/create-model';
import EditModel from 'modals/model/edit-model';
import PublishSkill from 'modals/publish-skill';
import SkillPipelineBuilder from 'modals/skill-pipeline-builder';
import UserAccessSecrets from 'modals/user-access-secrets';
import WorkflowSyncCreditsConfirmation from 'modals/workflow-sync-credits-confirmation';
import WorkflowSyncProgress from 'modals/workflow-sync-progress';
import AdminFeatures from 'pages/admin-features';
import AdminFirmware from 'pages/admin-firmware';
import AdminInternalSkills from 'pages/admin-internal-skills';
import AdminMarketplace from 'pages/admin-marketplace';
import AdminOrganizations from 'pages/admin-organizations';
import AdminPreclaimedDevices from 'pages/admin-preclaimed-devices';
import AdminProvisioning from 'pages/admin-provisioning';
import AdminResellerCodes from 'pages/admin-reseller-codes';
import AdminResellerCodeGiftCardDetail from 'pages/admin-reseller-codes-gift-card-detail';
import AdminResellerCodePurchaseOrderDetail from 'pages/admin-reseller-codes-purchase-order-detail';
import AdminServiceAccounts from 'pages/admin-service-accounts';
import AdminSkillBuilderBetaNodeDetail from 'pages/admin-skill-builder-beta-node-detail';
import AdminSkillBuilderBetaNodeNew from 'pages/admin-skill-builder-beta-node-new';
import AdminSkillBuilderBetaNodes from 'pages/admin-skill-builder-beta-nodes';
import AdminSkillBuilderConfig from 'pages/admin-skill-builder-config';
import AdminSkillBuilderModels from 'pages/admin-skill-builder-models';
import AdminSkillBuilderRecipeDetail from 'pages/admin-skill-builder-recipe-detail';
import AdminSkillBuilderRecipeNew from 'pages/admin-skill-builder-recipe-new';
import AdminSkillBuilderRecipes from 'pages/admin-skill-builder-recipes';
import AdminSkillBuilderTemplateDetail from 'pages/admin-skill-builder-template-detail';
import AdminSkillBuilderTemplateNew from 'pages/admin-skill-builder-template-new';
import AdminSkillBuilderTemplates from 'pages/admin-skill-builder-templates';
import AdminSuperUsers from 'pages/admin-super-users';
import AdminUsage from 'pages/admin-usage';
import AdminUsers from 'pages/admin-users';
import AuthCallback from 'pages/auth-callback';
import AuthorizeAction from 'pages/authorize-action';
import BillingTransactions from 'pages/billing-transactions';
import BillingTransactionsDetail from 'pages/billing-transactions-detail';
import Devices from 'pages/devices';
import Logout from 'pages/logout';
import Marketplace from 'pages/marketplace';
import Models from 'pages/models';
import Privacy from 'pages/privacy';
import Profile from 'pages/profile';
import Skills from 'pages/skills';
import SoftwareAgreement from 'pages/software-agreement';
import TermsAndConditions from 'pages/terms-and-conditions';
import TermsOfUse from 'pages/terms-of-use';
import Users from 'pages/users';
import Workflows from 'pages/workflows';
import Workspaces from 'pages/workspaces';
import ExternalRedirect from 'router/external-redirect';
import IntendedRouteTracker from 'router/intended-route-tracker';
import NoMatch from 'router/no-match';
import ProtectedRoute from 'router/protected-route';

import PinnedRoute from './pinnedRoute';
import RouteTitle, { RouteTitleProvider } from './RouteTitle';

export const interactiveRoles = [
  'admin',
  'deviceEnroller',
  'deviceTechnician',
  'workflowEditor',
  'workflowDeployer',
];

export const appRoutes = (
  <Routes>
    <Route path="/" element={<PinnedRoute />} />
    {/* Workflows Tab */}
    <Route
      path="workflows"
      element={
        <ProtectedRoute
          authenticate
          element={
            <RouteTitle title="Workflows">
              <Workflows />
              <Outlet />
            </RouteTitle>
          }
        />
      }
    >
      <Route
        path=":workflowId/choose-devices"
        element={
          <RouteTitle title="Choose Devices">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <ChooseWorkflowDevices />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/groups/create"
        element={
          <RouteTitle title="New Device Group">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <CreateDeviceGroup />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/groups/:groupId/edit"
        element={
          <RouteTitle title="Edit Device Group">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <EditDeviceGroup />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/groups/:groupId"
        element={
          <RouteTitle title="Device Group">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <ChooseGroupDevices />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/workflow-devices/create/*"
        element={
          <RouteTitle title="Enroll Device">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <CreateDevice />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/workflow-devices/:deviceId/*"
        element={
          <RouteTitle title="Device Detail">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <DeviceDetail />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/workflow-skills/add"
        element={
          <RouteTitle title="Add Skill">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <AddWorkflowSkill />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/workflow-skills/create/:skillId"
        element={
          <RouteTitle title="Configure Skill">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <CreateWorkflowSkill />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/skills/create-options"
        element={
          <RouteTitle title="Create Skill">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <CreateSkillOptions />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/skills/create-templates"
        element={
          <RouteTitle title="Choose a Template">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <CreateSkillTemplates />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/skills/create-docker-image"
        element={
          <RouteTitle title="Create Skill">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <CreateSkillDockerImage />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/workflow-skills/:workflowSkillId/edit"
        element={
          <RouteTitle title="Edit Skill">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <EditWorkflowSkill />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/workflow-actions/:workflowActionId/edit/*"
        element={
          <RouteTitle title="Edit Action">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <EditWorkflowAction />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/workflow-actions/add"
        element={
          <RouteTitle title="Add Action">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <AddWorkflowAction />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/copy-workflow-items"
        element={
          <RouteTitle title="Choose Workflow">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <CopyWorkflowItems />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/duplicate"
        element={
          <RouteTitle title="Duplicate Workflow">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <DuplicateWorkflow />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/edit"
        element={
          <RouteTitle title="Rename Workflow">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <EditWorkflow />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/bulk-edit"
        element={
          <RouteTitle title="Bulk Edit">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <BulkEditWorkflow />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/deployments/latest/*"
        element={
          <RouteTitle title="Sync Progress">
            <Modal>
              <WorkflowSyncProgress />
            </Modal>
          </RouteTitle>
        }
      />
      <Route
        path=":workflowId/credits-confirmation"
        element={
          <RouteTitle title="Sync Workflow">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <WorkflowSyncCreditsConfirmation />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path="create"
        element={
          <RouteTitle title="New Workflow">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <CreateWorkflow />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
    </Route>

    {/* Devices Tab */}
    <Route
      path="devices"
      element={
        <ProtectedRoute
          authenticate
          element={
            <RouteTitle title="Devices">
              <Devices />
              <Outlet />
            </RouteTitle>
          }
        />
      }
    >
      <Route
        path="manage-outputs"
        element={
          <RouteTitle title="Manage Outputs">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <ManageOutputs />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path="manage-outputs/:outputKey/edit"
        element={
          <RouteTitle title="Edit Output">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <EditOutput />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path="create-bulk"
        element={
          <RouteTitle title="Bulk Upload">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <BulkCreateDevices />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path="create/*"
        element={
          <RouteTitle title="Enroll Device">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <CreateDevice />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":deviceId/subscribe"
        element={
          <RouteTitle title="Subscribe to Device">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <DeviceDetailSubscription />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":deviceId/add-credits/*"
        element={
          <RouteTitle title="Add Credits">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <AddCredits />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
      <Route
        path=":deviceId/*"
        element={
          <RouteTitle title="Device Detail">
            <Modal>
              <DeviceDetail />
            </Modal>
          </RouteTitle>
        }
      />
      <Route
        path="manage-locations/*"
        element={
          <RouteTitle title="Manage Locations">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <Modal>
                  <ManageLocations />
                </Modal>
              }
            />
          </RouteTitle>
        }
      />
    </Route>

    {/* Skills Tab */}
    <Route path="skills" element={<ProtectedRoute authenticate />}>
      <Route
        element={
          <RouteTitle title="Skills">
            <Skills />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route index element={null} />
        <Route
          path="create/options"
          element={
            <RouteTitle title="Create Skill">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <CreateSkillOptions />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path="create/templates"
          element={
            <RouteTitle title="Create Skill | Choose a Template">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <CreateSkillTemplates />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path="create/docker-image"
          element={
            <RouteTitle title="Create Docker Image Skill">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <CreateSkillDockerImage />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path="create/docker-compose"
          element={
            <RouteTitle title="Create Docker Compose Skill">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <CreateSkillDockerCompose />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path="create/builder/*"
          element={
            <RouteTitle title="Skill Builder Pipeline">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal cover>
                    <SkillPipelineBuilder />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path=":skillId"
          element={
            <RouteTitle title="Skill Detail">
              <Modal>
                <Outlet />
              </Modal>
            </RouteTitle>
          }
        >
          <Route
            path="edit"
            element={
              <RouteTitle title="Edit Skill">
                <ProtectedRoute
                  authenticate
                  orgMemberRoles={interactiveRoles}
                  element={
                    <>
                      <EditSkill />
                      <Outlet />
                    </>
                  }
                />
              </RouteTitle>
            }
          >
            <Route index element={null} />
            <Route
              path="publish/*"
              element={
                <RouteTitle title="Publish Skill">
                  <ProtectedRoute
                    authenticate
                    orgMemberRoles={interactiveRoles}
                    element={<PublishSkill />}
                  />
                </RouteTitle>
              }
            />
            <Route
              path="listing/*"
              element={
                <RouteTitle title="Edit Listing">
                  <ProtectedRoute
                    authenticate
                    orgMemberRoles={interactiveRoles}
                    element={<EditPublishedSkill />}
                  />
                </RouteTitle>
              }
            />
            <Route
              path="copy"
              element={
                <RouteTitle title="Copy Skill">
                  <ProtectedRoute
                    authenticate
                    orgMemberRoles={interactiveRoles}
                    element={
                      <ProtectedRoute authenticate requiresGlobalAdmin element={<CopySkill />} />
                    }
                  />
                </RouteTitle>
              }
            />
          </Route>
          <Route
            path="versions/add"
            element={
              <RouteTitle title="Create Version">
                <ProtectedRoute
                  authenticate
                  orgMemberRoles={interactiveRoles}
                  element={<CreateSkillDockerImageVersion />}
                />
              </RouteTitle>
            }
          />
          <Route
            path="versions/add_compose"
            element={
              <RouteTitle title="Create Docker Compose Version">
                <ProtectedRoute
                  authenticate
                  orgMemberRoles={interactiveRoles}
                  element={<CreateSkillDockerComposeVersion />}
                />
              </RouteTitle>
            }
          />
          <Route
            path="versions/:skillVersionId/*"
            element={
              <RouteTitle title="Edit Version">
                <ProtectedRoute
                  authenticate
                  orgMemberRoles={interactiveRoles}
                  element={<EditSkillVersion />}
                />
              </RouteTitle>
            }
          />
          <Route
            path="versions/:skillVersionId/pipeline/*"
            element={
              <RouteTitle title="Edit Pipeline">
                <ProtectedRoute
                  authenticate
                  orgMemberRoles={interactiveRoles}
                  element={
                    <Modal cover closePath="/skills">
                      <SkillPipelineBuilder />
                    </Modal>
                  }
                />
              </RouteTitle>
            }
          />
        </Route>
        <Route
          path=":marketplaceSkillId/subscribe"
          element={
            <RouteTitle title="Add Skill">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={<MarketplaceSubscribe />}
              />
            </RouteTitle>
          }
        />
        <Route
          path=":marketplaceSkillId"
          element={
            <RouteTitle title="Marketplace Skill">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <MarketplaceDetail />
                    <Outlet />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        >
          <Route index element={null} />
          <Route
            path="gallery"
            element={
              <RouteTitle title="Marketplace Gallery">
                <MarketplaceDetailGallery />
              </RouteTitle>
            }
          />
        </Route>
      </Route>
      <Route
        path="models"
        element={
          <RouteTitle title="Models">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <>
                  <Models />
                  <Outlet />
                </>
              }
            />
          </RouteTitle>
        }
      >
        <Route index element={null} />
        <Route
          path="create"
          element={
            <RouteTitle title="New Model">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <CreateModel />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path=":modelId"
          element={
            <RouteTitle title="Model Detail">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <Outlet />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        >
          <Route index element={null} />
          <Route
            path="edit"
            element={
              <RouteTitle title="Edit Model">
                <ProtectedRoute
                  authenticate
                  orgMemberRoles={interactiveRoles}
                  element={
                    <>
                      <EditModel />
                      <Outlet />
                    </>
                  }
                />
              </RouteTitle>
            }
          />
        </Route>
      </Route>
      <Route
        path="marketplace"
        element={
          <RouteTitle title="Skills Marketplace">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={
                <>
                  <Marketplace />
                  <Outlet />
                </>
              }
            />
          </RouteTitle>
        }
      >
        <Route index element={null} />
        <Route
          path=":marketplaceSkillId/subscribe"
          element={
            <RouteTitle title="Add Skill">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <MarketplaceSubscribe />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path=":marketplaceSkillId"
          element={
            <RouteTitle title="Marketplace Skill">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <MarketplaceDetail />
                    <Outlet />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        >
          <Route index element={null} />
          <Route
            path="gallery"
            element={
              <RouteTitle title="Marketplace Gallery">
                <ProtectedRoute
                  authenticate
                  orgMemberRoles={interactiveRoles}
                  element={<MarketplaceDetailGallery />}
                />
              </RouteTitle>
            }
          />
        </Route>
      </Route>
    </Route>

    {/* Charts Tab */}
    <Route
      path="charts/*"
      element={
        <RouteTitle title="Charts">
          <ProtectedRoute
            authenticate
            orgMemberRoles={['admin', 'chartEditor', 'chartViewerOnly']}
            allowChartViewer
            element={<Workspaces />}
          />
        </RouteTitle>
      }
    />

    {/* Users Tab */}
    <Route
      path="users"
      element={
        <ProtectedRoute
          authenticate
          orgMemberRoles={['admin']}
          element={
            <RouteTitle title="Users">
              <Users />
              <Outlet />
            </RouteTitle>
          }
        />
      }
    >
      <Route index element={null} />
      <Route
        path="create"
        element={
          <RouteTitle title="Create User">
            <Modal>
              <CreateUser />
            </Modal>
          </RouteTitle>
        }
      />
      <Route
        path="api-keys"
        element={
          <RouteTitle title="API Keys">
            <Modal>
              <UserAccessSecrets />
            </Modal>
          </RouteTitle>
        }
      />
      <Route
        path=":userId/edit"
        element={
          <RouteTitle title="Edit User">
            <Modal>
              <EditUser />
            </Modal>
          </RouteTitle>
        }
      />
    </Route>

    {/* Profile Tab */}
    <Route
      path="profile"
      element={
        <RouteTitle title="Profile">
          <ProtectedRoute authenticate allowChartViewer element={<ProfileLayout />} />
        </RouteTitle>
      }
    >
      <Route
        path=""
        element={
          <RouteTitle title="Profile">
            <Profile />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route index element={null} />
        <Route
          path="add-credits/*"
          element={
            <RouteTitle title="Add Credits">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <AddCredits />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path="credits-settings/*"
          element={
            <RouteTitle title="Credits Settings">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <CreditsSettings />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path="organizations/create"
          element={
            <RouteTitle title="Create Organization">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <CreateOrganization />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path="organizations/:organizationId/edit"
          element={
            <RouteTitle title="Edit Organization">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <EditOrganization />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
        <Route
          path="api-keys"
          element={
            <RouteTitle title="API Keys">
              <ProtectedRoute
                authenticate
                orgMemberRoles={interactiveRoles}
                element={
                  <Modal>
                    <UserAccessSecrets />
                  </Modal>
                }
              />
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="transactions"
        element={
          <RouteTitle title="Billing Transactions">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={<BillingTransactions />}
            />
          </RouteTitle>
        }
      />
      <Route
        path="transactions/:billingTransactionId"
        element={
          <RouteTitle title="Transaction Detail">
            <ProtectedRoute
              authenticate
              orgMemberRoles={interactiveRoles}
              element={<BillingTransactionsDetail />}
            />
          </RouteTitle>
        }
      />
    </Route>

    {/* Super Admin */}
    <Route
      path="admin"
      element={
        <RouteTitle title="Admin">
          <ProtectedRoute authenticate requiresGlobalAdmin element={<AdminLayout />} />
        </RouteTitle>
      }
    >
      <Route
        path="service-accounts"
        element={
          <RouteTitle title="Service Accounts | Admin">
            <AdminServiceAccounts />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path="create"
          element={
            <RouteTitle title="Create Service Account | Admin">
              <Modal>
                <AdminCreateServiceAccount />
              </Modal>
            </RouteTitle>
          }
        />
        <Route
          path=":serviceAccountId/edit"
          element={
            <RouteTitle title="Edit Service Account | Admin">
              <Modal>
                <AdminEditServiceAccount />
              </Modal>
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="organizations"
        element={
          <RouteTitle title="Organizations | Admin">
            <AdminOrganizations />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path="create"
          element={
            <RouteTitle title="Create Organization | Admin">
              <Modal>
                <AdminCreateOrganization />
              </Modal>
            </RouteTitle>
          }
        />
        <Route
          path=":organizationId/edit"
          element={
            <RouteTitle title="Edit Organization | Admin">
              <Modal>
                <AdminEditOrganization />
              </Modal>
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="super-users"
        element={
          <RouteTitle title="Super Users | Admin">
            <AdminSuperUsers />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path="create"
          element={
            <RouteTitle title="Create Super User | Admin">
              <Modal>
                <AdminCreateSuperUser />
              </Modal>
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="firmware"
        element={
          <RouteTitle title="Firmware | Admin">
            <AdminFirmware />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path="create"
          element={
            <RouteTitle title="Create Firmware | Admin">
              <Modal>
                <AdminCreateFirmware />
              </Modal>
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="preclaimed-devices"
        element={
          <RouteTitle title="Preclaimed Devices | Admin">
            <AdminPreclaimedDevices />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path="upload"
          element={
            <RouteTitle title="New Preclaimed Device">
              <Modal>
                <AdminPreclaimDevicesUpload />
              </Modal>
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="usage"
        element={
          <RouteTitle title="Usage | Admin">
            <AdminUsage />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path=":deviceId/*"
          element={
            <RouteTitle title="Device Usage Detail">
              <Modal>
                <DeviceDetail />
              </Modal>
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="marketplace"
        element={
          <RouteTitle title="Marketplace | Admin">
            <AdminMarketplace />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path="listings/create"
          element={
            <RouteTitle title="New Listing">
              <Modal>
                <AdminMarketplaceCreateListing />
              </Modal>
            </RouteTitle>
          }
        />
        <Route
          path="listings/:skillId/edit/*"
          element={
            <RouteTitle title="Edit Marketplace Listing">
              <AdminMarketplaceEditListing />
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="usage-credits"
        element={
          <RouteTitle title="Usage Credits | Admin">
            <AdminResellerCodes />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path="create-purchase-order"
          element={
            <RouteTitle title="Create Purchase Order">
              <Modal>
                <AdminPurchaseOrderCreate />
              </Modal>
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="usage-credits/purchase-order/:id"
        element={
          <RouteTitle title="Purchase Order Detail">
            <AdminResellerCodePurchaseOrderDetail />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path="edit"
          element={
            <RouteTitle title="Edit Purchase Order">
              <Modal>
                <AdminPurchaseOrderUpdate />
              </Modal>
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="usage-credits/purchase-order/:purchaseOrderId/gift-card/:giftCardId"
        element={
          <RouteTitle title="Gift Card Detail">
            <AdminResellerCodeGiftCardDetail />
          </RouteTitle>
        }
      />
      <Route
        path="users"
        element={
          <RouteTitle title="Admin Users">
            <>
              <AdminUsers />
              <Outlet />
            </>
          </RouteTitle>
        }
      >
        <Route
          path=":userId/edit"
          element={
            <RouteTitle title="Edit User | Admin">
              <Modal>
                <AdminEditUser />
              </Modal>
            </RouteTitle>
          }
        />
      </Route>
      <Route
        path="provisioning"
        element={
          <RouteTitle title="Provisioning | Admin">
            <AdminProvisioning />
          </RouteTitle>
        }
      />
      <Route
        path="features"
        element={
          <RouteTitle title="Features | Admin">
            <AdminFeatures />
            <Outlet />
          </RouteTitle>
        }
      />
      <Route
        path="agents"
        element={
          <RouteTitle title="Agents | Admin">
            <AdminInternalSkills />
            <Outlet />
          </RouteTitle>
        }
      >
        <Route
          path=":skillId"
          element={
            <RouteTitle title="Agent Skill Detail">
              <Modal>
                <Outlet />
              </Modal>
            </RouteTitle>
          }
        >
          <Route
            path="edit"
            element={
              <RouteTitle title="Edit Agent Skill">
                <AdminEditInternalSkill />
              </RouteTitle>
            }
          />
          <Route
            path="versions/add"
            element={
              <RouteTitle title="Create Internal Skill Version">
                <AdminCreateInternalSkillVersion />
              </RouteTitle>
            }
          />
          <Route
            path="versions/:skillVersionId"
            element={
              <RouteTitle title="Edit Agent Version">
                <AdminEditInternalSkillVersion />
              </RouteTitle>
            }
          />
        </Route>
      </Route>
      <Route path="skill-builder-content">
        <Route
          index
          element={
            <RouteTitle title="Skill Builder Content">
              <AdminSkillBuilderTemplates />
            </RouteTitle>
          }
        />
        <Route
          path="config"
          element={
            <RouteTitle title="Skill Builder Config">
              <AdminSkillBuilderConfig />
            </RouteTitle>
          }
        />
        <Route
          path="templates/new"
          element={
            <RouteTitle title="New Template">
              <AdminSkillBuilderTemplateNew />
            </RouteTitle>
          }
        />
        <Route
          path="templates/:id"
          element={
            <RouteTitle title="Template Detail">
              <AdminSkillBuilderTemplateDetail />
            </RouteTitle>
          }
        />
        <Route
          path="recipes"
          element={
            <RouteTitle title="Recipes | Admin">
              <AdminSkillBuilderRecipes />
            </RouteTitle>
          }
        />
        <Route
          path="recipes/new"
          element={
            <RouteTitle title="New Recipe">
              <AdminSkillBuilderRecipeNew />
            </RouteTitle>
          }
        />
        <Route
          path="recipes/:id"
          element={
            <RouteTitle title="Recipe Detail">
              <AdminSkillBuilderRecipeDetail />
            </RouteTitle>
          }
        />
        <Route path="beta-nodes/new" element={<AdminSkillBuilderBetaNodeNew />} />
        <Route path="beta-nodes/:id" element={<AdminSkillBuilderBetaNodeDetail />} />
        <Route path="beta-nodes" element={<AdminSkillBuilderBetaNodes />} />
        <Route
          path="models"
          element={
            <RouteTitle title="Models | Admin">
              <AdminSkillBuilderModels />
            </RouteTitle>
          }
        />
      </Route>
    </Route>

    {/* Auth Routes */}
    <Route
      path="/actions/authorize"
      element={<ProtectedRoute authenticate element={<AuthorizeAction />} />}
    />

    <Route path="/login" element={<Login />} />
    <Route path="/logout" element={<Logout />} />
    <Route path="/authorize" element={<AuthCallback />} />

    {/* Misc Routes */}
    <Route path="/eula" element={<SoftwareAgreement />} />
    <Route path="/help" element={<ExternalRedirect to={supportUrl} replace />} />

    {/* Legal Routes */}
    <Route path="/terms-of-use" element={<TermsOfUse />} />
    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
    <Route path="/privacy" element={<Privacy />} />

    {/* Fallback Routes */}
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

export default function AppRoutes() {
  useVersionCheck();
  return (
    <>
      <IntendedRouteTracker ignore={['/login', '/logout', '/authorize', '/eula']} />

      <RouteTitleProvider>{appRoutes}</RouteTitleProvider>
    </>
  );
}
