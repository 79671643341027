import * as date from 'date-fns';
import { omit, upperFirst, uniq, isEmpty, isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { useDebounce } from 'react-use';

import { PrimaryCTAButton, Toggle, Button, PageWrapper } from '@optra/kit';

import DevicesFilterBar from 'components/devices-filter-bar';
import DevicesHeaderBar from 'components/devices-header-bar';
import DevicesList from 'components/devices-list';
import DevicesMap from 'components/devices-map';
import PinPageButton from 'components/pin-page-button';
import { useHasRoles, useLocalStorage, useSearchParams } from 'hooks';
import { useDevices } from 'queries';

const defaultFilter = {
  downtime: {
    enabled: false,
    units: 'minutes',
    from: 0,
  },
};

export default function Devices() {
  const [searchParams, , createSearchParamSetter] = useSearchParams({
    query: '',
    filter: defaultFilter,
    sortBy: {
      by: 'name',
      direction: 'asc',
    },
    deviceIds: [],
    selectAll: false,
    devicesOmitted: [],
  });

  const {
    query: search,
    sortBy: sort,
    filter,
    deviceIds: selectedDeviceIds = [],
    selectAll,
    devicesOmitted = [],
  } = searchParams;

  const setSearch = createSearchParamSetter('query');
  const setFilter = createSearchParamSetter('filter');
  const setSort = createSearchParamSetter('sortBy');
  const setSelectedDeviceIds = createSearchParamSetter('selectedDeviceIds');
  const setSelectAll = createSearchParamSetter('selectAll');
  const setDevicesOmitted = createSearchParamSetter('devicesOmitted');

  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [mapVisible, setMapVisible] = useLocalStorage('mapVisible', true);
  const [filtersVisible, setFiltersVisible] = useLocalStorage('filtersVisible', false);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const [canCreateDevice] = useHasRoles([
    'admin',
    'deviceEnroller',
    'deviceTechnician',
    'workflowEditor',
    'workflowDeployer',
  ]);

  const mappedFilter = useMemo(
    () => ({
      ...omit(filter || defaultFilter, ['downtime', 'location']),
      ...(filter?.downtime?.enabled
        ? {
            downtime: {
              downAt: [
                null,
                date[`sub${upperFirst(filter.downtime.units)}`](
                  new Date(),
                  filter.downtime.from,
                ).getTime(),
              ],
            },
          }
        : {}),
      ...(!isEmpty(filter?.locationIds)
        ? { locationIds: filter?.locationIds.map(location => location.value) }
        : {}),
      ...(!isEmpty(debouncedSearch) ? { $search: debouncedSearch } : {}),
    }),
    [filter, debouncedSearch],
  );

  const {
    data,
    isLoading,
    isRefetching,
    isFetchedAfterMount,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error,
  } = useDevices({
    list: {
      filter: mappedFilter,
      sort,
    },
  });
  const searching = isRefetching && !isFetchedAfterMount;
  const devices = data?.list || [];
  const count = data?.count;
  const deviceIds = devices.map(device => device.id);
  const allSelected =
    (selectAll && devicesOmitted.length < 1) ||
    (deviceIds.length > 0 &&
      deviceIds.length === count &&
      deviceIds.every(id => selectedDeviceIds.includes(id)));

  const handleSelectDevice = id => {
    if (selectAll) {
      if (devicesOmitted.includes(id)) {
        setDevicesOmitted(devicesOmitted.filter(sid => sid !== id));
      } else {
        setDevicesOmitted(uniq([...devicesOmitted, id]));
      }
    } else {
      if (selectedDeviceIds.includes(id)) {
        setSelectedDeviceIds(selectedDeviceIds.filter(sid => sid !== id));
      } else {
        setSelectedDeviceIds(uniq([...selectedDeviceIds, id]));
      }
    }
  };

  const handleSelectAll = () => {
    if (!selectAll && !allSelected) {
      setSelectedDeviceIds([]);
      setSelectAll(true);
    } else {
      if (!allSelected) {
        // Enabling selectAll while already in selectAll mode and some devices have been omitted
        // should just clear the omissions.
        setDevicesOmitted([]);
        setSelectAll(true);
      } else {
        // Disable selectAll with no omissions

        // Avoiding unnecessary updates to these values, as it can interfere with setting the selectAll value due to an
        // apparent bug in useRouteQueryState
        if (devicesOmitted.length > 0) setDevicesOmitted([]);
        if (selectedDeviceIds.length > 0) setSelectedDeviceIds([]);
        setSelectAll(false);
      }
    }
  };

  const clearSelection = () => {
    flushSync(() => {
      setDevicesOmitted([]);
      setSelectedDeviceIds([]);
      setSelectAll(false);
    });
  };

  const handleOnSearch = s => {
    clearSelection();
    setSearch(s);
  };

  const handleSetFilter = f => {
    clearSelection();
    setFilter(f);
  };

  const handleOnClearFilter = () => {
    clearSelection();
    setFilter(defaultFilter);
  };

  const isFiltered = !isEmpty(mappedFilter);

  return (
    <PageWrapper icon="Aperture" heading="Devices" loading={isLoading} error={error}>
      <PageWrapper.Actions>
        <PinPageButton />
        <Toggle
          label="Show Map"
          labelPlacement="left"
          checked={mapVisible}
          onChange={(e, checked) => setMapVisible(checked)}
          className="self-center"
        />
        {canCreateDevice && <PrimaryCTAButton to="/devices/create" text="Enroll Device" />}
      </PageWrapper.Actions>
      <PageWrapper.LeftToggle>
        <Button icon="FunnelSimple" variant="secondary" size="sm" as="span">
          Filters
        </Button>
      </PageWrapper.LeftToggle>
      <PageWrapper.Left>
        <div className="w-56">
          {!isLoading && (
            <div className="animate-fade-in">
              <DevicesFilterBar
                defaultFilter={defaultFilter}
                filter={filter || defaultFilter}
                onFilter={handleSetFilter}
                onToggleVisible={() => setFiltersVisible(!filtersVisible)}
                visible={filtersVisible}
              />
            </div>
          )}
        </div>
      </PageWrapper.Left>
      {!isLoading && (
        <PageWrapper.Controls>
          <DevicesHeaderBar
            searching={searching}
            filter={mappedFilter}
            filtersVisible={filtersVisible}
            filterActive={filter && !isEqual(filter, defaultFilter)}
            numberOfDevices={count}
            onFilter={handleSetFilter}
            onClearFilter={handleOnClearFilter}
            onSearch={handleOnSearch}
            onToggleFiltersVisible={() => setFiltersVisible(!filtersVisible)}
            search={search}
            selectedDeviceIds={selectedDeviceIds}
            selectAll={selectAll}
            devicesOmitted={devicesOmitted}
            sort={sort}
          />
        </PageWrapper.Controls>
      )}
      <DevicesMap
        filter={mappedFilter}
        visible={mapVisible}
        selectAll={selectAll}
        selectedDeviceIds={selectedDeviceIds}
        devicesOmitted={devicesOmitted}
      />
      <DevicesList
        canCreateDevice={canCreateDevice}
        currentSort={sort}
        devices={devices}
        error={error}
        fetching={isLoading}
        hasNextPage={hasNextPage}
        isFiltered={isFiltered}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        onSelectDevice={handleSelectDevice}
        onSelectDevices={handleSelectAll}
        onSort={setSort}
        selectedDeviceIds={selectedDeviceIds}
        selectAll={selectAll}
        devicesOmitted={devicesOmitted}
        allSelected={allSelected}
      />
    </PageWrapper>
  );
}
