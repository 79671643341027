import { omit } from 'lodash';
import { useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-terminal';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import YAML from 'yaml';

import { Card, Button, IconButton, PageWrapper } from '@optra/kit';

import IconChooser from 'components/icon-chooser';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import Textarea from 'components/textarea';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';
import useIsDarkMode from 'hooks/use-is-dark-mode';
import defaultPipeline from 'modals/skill-pipeline-builder/default-pipeline';

export default function AdminSkillBuilderTemplateCreate() {
  const navigate = useNavigate();
  const isDarkMode = useIsDarkMode();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      config: YAML.stringify(defaultPipeline, { aliasDuplicateObjects: false }),
      icon: {
        icon: 'action',
        color: '#00C425',
        iconUrl: null,
      },
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');

  const qc = q.useQueryClient();
  const createSkillBuilderTemplate = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createSkillBuilderTemplate($form: createSkillBuilderTemplateForm!) {
          skillBuilderTemplate: createSkillBuilderTemplate(form: $form) {
            id
          }
        }`,
        {
          form: {
            ...omit(form, 'icon'),
            ...form.icon,
          },
        },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skillBuilderTemplates'] });
      navigate('/admin/skill-builder-content');
    },
    onError(error) {
      setError(error);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    createSkillBuilderTemplate.mutate({
      ...form,
      config: YAML.parse(form.config),
    });
  });

  return (
    <PageWrapper
      heading="New Template"
      icon="FileCode"
      inline
      components={{
        headingBefore: (
          <IconButton name="CaretLeft" variant="plain" to="/admin/skill-builder-content" />
        ),
      }}
    >
      {error && (
        <Message variant="danger" title="Couldn't Create Template">
          {error.message}
        </Message>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            {...register('name', {
              required: 'Please enter a name',
            })}
            readOnly={createSkillBuilderTemplate.isPending}
          />
          <ValidationError errors={errors} name="name" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="description">Description</Label>
          <Textarea
            {...register('description', {
              required: 'Please enter a description',
            })}
            readOnly={createSkillBuilderTemplate.isPending}
          />
          <ValidationError errors={errors} name="description" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="icon">Icon</Label>
          <Controller
            name="icon"
            control={control}
            rules={{
              required: 'Please choose an icon.',
            }}
            render={({ field, fieldState }) => (
              <Card>
                <IconChooser
                  {...field}
                  loading={createSkillBuilderTemplate.isPending}
                  disabled={createSkillBuilderTemplate.isPending}
                  allowsUpload={false}
                />
              </Card>
            )}
          />
          <ValidationError errors={errors} name="icon" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="config">Config YAML</Label>
          <Controller
            control={control}
            name="config"
            render={({ field: { onChange, value, ref } }) => (
              <AceEditor
                mode="yaml"
                theme={isDarkMode ? 'terminal' : 'tomorrow'}
                value={value}
                width="100%"
                tabSize={2}
                onChange={onChange}
                setOptions={{ useWorker: false }}
              />
            )}
          />
        </div>

        <Button type="submit" size="xl" loading={createSkillBuilderTemplate.isPending}>
          Create
        </Button>
      </form>
    </PageWrapper>
  );
}
