import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card, Button, Spinner, Text } from '@optra/kit';

import DeviceImage from 'components/device-image';
import { useFeature } from 'components/feature';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q, useOnSuccess } from 'config/api';
import { useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useDevice } from 'queries';

export default function ReplaceDevice() {
  const { deviceId } = useParams();
  const [returnAuthorization, setReturnAuthorization] = useState();
  const { handleBack } = useModalContext();
  const isAllowed = useFeature('replaceDevice');

  const {
    data,
    isLoading: fetching,
    isSuccess,
  } = useDevice(
    deviceId,
    `
      id
      name
      model
      serialNumber
      provisionedDevice {
        returnAuthorization
      }
    `,
  );

  useOnSuccess(
    () => {
      setReturnAuthorization(data?.device?.provisionedDevice?.returnAuthorization);
    },
    { isSuccess },
    [data],
  );

  const qc = q.useQueryClient();
  const returnDevice = q.useMutation({
    mutationFn: id =>
      api(
        `mutation returnDevice($id: ID!) {
          returnDevice(id: $id) {
            provisionedDevice {
              returnAuthorization
            }
          }
        }`,
        { id },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['devices'] });
      qc.invalidateQueries({ queryKey: ['device', deviceId] });
      setReturnAuthorization(r?.returnDevice?.provisionedDevice?.returnAuthorization);
    },
  });

  const cancelReturnDevice = q.useMutation({
    mutationFn: id =>
      api(
        `mutation cancelReturnDevice($id: ID!) {
          cancelReturnDevice(id: $id) {
            provisionedDevice {
              returnAuthorization
            }
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
      qc.invalidateQueries({ queryKey: ['device', deviceId] });
      handleBack();
    },
  });

  function handleReturnDevice() {
    if (returnDevice.isPending) return;
    returnDevice.mutate(data?.device?.id);
  }

  function handleCancelReturn() {
    if (cancelReturnDevice.isPending) return;
    cancelReturnDevice.mutate(data?.device?.id);
  }

  const itemNotFound = useItemNotFound({
    fetching,
    id: data?.device?.id,
  });
  if (itemNotFound || !isAllowed) {
    return <ItemNotFound id={deviceId} type="Device" />;
  }

  return (
    <ModalInner>
      <ModalTitle title="Replace Device" icon="Aperture" />
      <ModalBody>
        {returnAuthorization && (
          <>
            <Card variant="secondary" className="text-center mb-3">
              <Text className="block" color="muted">
                Include this RMA number in the Card
              </Text>
              <Text size="xl" className="block mb-4" variant="label">
                {returnAuthorization}
              </Text>
              <Text className="block">Ship to:</Text>
              <Text className="block" color="muted">
                Lexmark Ventures LLC
                <br />
                co: Optra Device Replacement
                <br />
                740 W. New Circle Road
                <br />
                Lexington, KY 40550
              </Text>
            </Card>

            <div className="flex items-center justify-center">
              <Button
                icon="X"
                variant="tertiary"
                size="xs"
                loading={cancelReturnDevice.isPending}
                onClick={handleCancelReturn}
              >
                Cancel Return
              </Button>
              {/* <Button label="Print" icon="print" /> */}
            </div>
          </>
        )}

        {!returnAuthorization && (
          <>
            <Card variant="secondary" className="text-center mb-3 space-y-2">
              {!fetching && (
                <DeviceImage
                  status={data?.device?.status}
                  model={data?.device?.model}
                  size="xl"
                  className="mx-auto"
                />
              )}
              {fetching && <Spinner color="gradient" size="xl" className="mx-auto" />}
              <Text className="block">{data?.device?.name}</Text>
              <Text size="xs" color="muted" className="uppercase font-mono">
                {data?.device?.serialNumber}
              </Text>
            </Card>

            <div className="text-center space-y-2">
              <Text className="block">
                Would you like to submit a warranty claim for this device?
              </Text>
              <Button
                variant="primary"
                onClick={handleReturnDevice}
                loading={returnDevice.isPending}
              >
                Yes, Generate RMA Number
              </Button>
            </div>
          </>
        )}
      </ModalBody>
    </ModalInner>
  );
}
