import { api, q } from 'config/api';

export default function useProvisionedDevicesUsage(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['provisionedDevicesUsage', variables],
    queryFn({ pageParam }) {
      return api(
        `query provisionedDevicesUsage($rootList: listFields, $usagesList: listFields) {
          list: provisionedDevices(list: $rootList) {
            count
            cursor {
              after
            }
            data {
              serialNumber
              model
              createdAt
              device {
                id
                status
                autoClaim
                location {
                  address {
                    address
                    locality
                  }
                }
              }
              organization {
                name
              }
              claimantId
              usages(list: $usagesList) {
                count
                cursor {
                  before
                  after
                }
                data {
                  id
                  messagesTransmitted
                  ownerName
                }
              }
            }
          }
        }`,
        {
          ...variables,
          rootList: {
            ...variables.rootList,
            cursor: { after: pageParam },
          },
        },
      );
    },
    placeholderData: q.keepPreviousData,
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    select: data => data?.pages.flatMap(page => page.list.data),
    ...overrides,
  });
}
