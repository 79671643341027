import { Routes, Route } from 'react-router-dom';

import DeviceCreateClaim from 'components/device-create-claim';
import DeviceCreateConfirmation from 'components/device-create-confirmation';
import DeviceCreateDetails from 'components/device-create-details';
import DeviceCreateSerialNumber from 'components/device-create-serial-number';
import DeviceCreateSubscription from 'components/device-create-subscription';
import ModalInner from 'components/modal-inner';
import ManageLocations from 'modals/manage-locations';
import RouteTitle from 'router/RouteTitle';

export default function CreateDevice() {
  return (
    <ModalInner>
      <Routes>
        <Route
          index
          element={
            <RouteTitle title="Enroll Device">
              <DeviceCreateSerialNumber />
            </RouteTitle>
          }
        />
        <Route
          path=":provisionedDeviceId/claim"
          element={
            <RouteTitle title="Claim Device">
              <DeviceCreateClaim />
            </RouteTitle>
          }
        />
        <Route
          path=":provisionedDeviceId/subscription/*"
          element={
            <RouteTitle title="Enroll Device">
              <DeviceCreateSubscription />
            </RouteTitle>
          }
        />
        <Route
          path=":provisionedDeviceId/details"
          element={
            <RouteTitle title="Enroll Device">
              <DeviceCreateDetails />
            </RouteTitle>
          }
        />
        <Route
          path=":provisionedDeviceId/details/manage-locations/*"
          element={
            <RouteTitle title="Manage Locations">
              <ManageLocations />
            </RouteTitle>
          }
        />
        <Route
          path=":deviceId/confirmation"
          element={
            <RouteTitle title="Enroll Device">
              <DeviceCreateConfirmation />
            </RouteTitle>
          }
        />
      </Routes>
    </ModalInner>
  );
}
