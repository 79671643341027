import { api, q } from 'config/api';

export default function useProvisionedDevices(variables) {
  return q.useInfiniteQuery({
    queryKey: ['provisionedDevices', variables],
    queryFn({ pageParam }) {
      return api(
        `query provisionedDevices($list: listFields) {
          list: provisionedDevices(list: $list) {
            cursor {
              after
            }
            data {
              id
              model
              serialNumber
              claimable
              status
              adminConnectionString: adminConnectionStringPrimary
              moduleConnectionString: moduleConnectionStringPrimary
              certificateErrors {
                module
                admin
              }
              claimant {
                name
              }
              organization {
                name
              }
            }
          }
        }`,
        {
          list: {
            ...variables,
            cursor: { after: pageParam },
          },
        },
      );
    },
    placeholderData: q.keepPreviousData,
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    select: data => data?.pages.flatMap(page => page.list.data),
  });
}
