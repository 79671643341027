import { last, isEmpty } from 'lodash';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Routes, Route, Outlet, useParams, useSearchParams } from 'react-router-dom';

import { Card, Button } from '@optra/kit';

import IconChooser from 'components/icon-chooser';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Tab from 'components/tab';
import Tabs from 'components/tabs';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import isFile from 'lib/is-file';
import useParseFileUrl from 'lib/use-parse-file-url';
import MarketplaceDetailGallery from 'modals/marketplace-detail-gallery';
import PublishSkillCompatibility from 'modals/publish-skill-compatibility';
import PublishSkillDescription from 'modals/publish-skill-description';
import PublishSkillImages from 'modals/publish-skill-images';
import PublishSkillPreview from 'modals/publish-skill-preview';
import PublishSkillVersion from 'modals/publish-skill-version';
import { usePublishSkill, useSignedUpload } from 'queries';

export default function EditPublishedSkill(props) {
  const Form = useForm({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      versionId: '',
      name: '',
      icon: {
        icon: 'action',
        color: '#00C425',
        iconUrl: null,
      },
      summary: '',
      description: '',
      releaseNotes: '',
      compatibleFirmwareVersions: '',
      compatibleDeviceModels: {
        vz1000: false,
        vz5000: false,
        compute550: false,
        cx1000: false,
        cx2000: false,
      },
      licenseUrl: null,
      manualUrl: null,
      screenshotUrls: [null, null, null],
    },
  });
  const { skillId } = useParams();
  const [searchParams] = useSearchParams();
  const searchParamsTab = searchParams.get('tab');
  const [currentTab, setCurrentTab] = useState(searchParamsTab || 'version');

  const isEditable = version =>
    !['published', 'publishable', 'publishing'].includes(version?.publicationState);
  const isSelectable = v => v.publicationState !== 'publishable';

  const skillQuery = usePublishSkill(skillId);
  const { data: skillData, isLoading, isSuccess, error } = skillQuery;

  useOnSuccess(
    () => {
      const { skill, marketplaceSkill } = skillData;
      const versions = skill?.versions?.data || [];
      const latestSkillVersion = versions?.filter(isSelectable)[0] || versions[0];
      const msv = marketplaceSkill?.versions?.data?.find?.(
        v => v.originSkillVersionId === latestSkillVersion?.id,
      );

      Form.reset({
        versionId: latestSkillVersion?.id,
        name: msv?.name || skill?.name,
        icon: {
          icon: msv?.icon || skill?.icon || 'action',
          color: msv?.color || skill?.color || '#00C425',
          iconUrl: msv?.iconUrl || skill?.iconUrl || null,
        },
        summary: msv?.summary || '',
        creditsCost: msv?.creditsCost ?? 0,
        description: msv?.description || '',
        releaseNotes: msv?.releaseNotes || '',
        compatibleFirmwareVersions: msv?.compatibleFirmwareVersions || '',
        compatibleDeviceModels: {
          vz1000: msv?.compatibleDeviceModels?.includes?.('vz1000') || false,
          vz5000: msv?.compatibleDeviceModels?.includes?.('vz5000') || false,
          compute550: msv?.compatibleDeviceModels?.includes?.('compute550') || false,
          cx1000: msv?.compatibleDeviceModels?.includes?.('cx1000') || false,
          cx2000: msv?.compatibleDeviceModels?.includes?.('cx2000') || false,
        },
        licenseUrl: msv?.licenseUrl || null,
        manualUrl: msv?.manualUrl || null,
        screenshotUrls: [
          msv?.screenshotUrls?.[0] || null,
          msv?.screenshotUrls?.[1] || null,
          msv?.screenshotUrls?.[2] || null,
        ],
      });
    },
    { isSuccess },
    [Form, skillData],
  );

  const formValues = Form.watch();
  const selectedVersion = skillData?.skill?.versions?.data?.find(
    v => v.id === formValues.versionId,
  );
  const selectedVersionIsEditable = isEditable(selectedVersion);
  const iconUrl = useParseFileUrl(formValues.icon?.iconUrl);
  const licenseUrl = useParseFileUrl(formValues.licenseUrl);
  const manualUrl = useParseFileUrl(formValues.manualUrl);
  const screenshotUrls = [
    useParseFileUrl(formValues.screenshotUrls?.[0]),
    useParseFileUrl(formValues.screenshotUrls?.[1]),
    useParseFileUrl(formValues.screenshotUrls?.[2]),
  ];

  // TODO: When the listing is not editable, use the default values instead. The form values will be undefined for non-editable skills because disabled inputs return no value.
  const proposedMarketplaceSkill = {
    ...formValues,
    ...formValues.icon,
    iconUrl,
    licenseUrl,
    manualUrl,
    latestVersion: {
      ...selectedVersion,
      releaseNotes: formValues.releaseNotes,
      compatibleDeviceModels: Object.entries(formValues.compatibleDeviceModels || {})
        .filter(([k, v]) => v)
        .map(([k]) => k),
    },
    developerProfile: skillData?.currentDeveloperProfile,
    screenshotUrls: screenshotUrls.filter(f => f),
  };

  const [uploadIcon] = useSignedUpload({ type: 'skillImage' });
  const [uploadLicense] = useSignedUpload({ type: 'marketplaceSkillLicense' });
  const [uploadManual] = useSignedUpload({ type: 'skillManual' });
  const [uploadScreenShot1] = useSignedUpload({ type: 'skillImage' });
  const [uploadScreenShot2] = useSignedUpload({ type: 'skillImage' });
  const [uploadScreenShot3] = useSignedUpload({ type: 'skillImage' });
  const [generalError, setGeneralError] = useState();

  const qc = q.useQueryClient();
  const updateMarketplaceSkill = q.useMutation({
    mutationFn: async form => {
      const { publicationState, skillVersionId, marketplaceSkillVersionId, ...fields } = form;

      if (publicationState === 'published') {
        throw new Error('Skill version is already published!');
      }

      if (publicationState === 'failed') {
        return api(
          `mutation publishMarketplaceSkill($form: publishMarketplaceSkillForm!) {
            marketplaceSkill: publishMarketplaceSkill(form: $form) {
              id
            }
          }`,
          {
            form: {
              ...fields,
              skillVersionId,
            },
          },
        );
      }

      return api(
        `mutation updateMarketplaceSkill($form: updateMarketplaceSkillVersionForm!) {
          marketplaceSkillVersion: updateMarketplaceSkillVersion(form: $form) {
            id
          }
        }`,
        {
          form: {
            id: marketplaceSkillVersionId,
            ...fields,
          },
        },
      );
    },
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
      qc.invalidateQueries({ queryKey: ['marketplaceSkills'] });
    },
    onError(err) {
      setGeneralError(err.message);
    },
  });

  const handleSubmit = Form.handleSubmit(async form => {
    setGeneralError(null);
    const formValid = await Form.trigger();
    if (!formValid) throw new Error('Form invalid!');

    // Upload set images in parallel
    const [icon, license, manual, screenshot1, screenshot2, screenshot3] = await Promise.all([
      isFile(form.icon?.iconUrl)
        ? uploadIcon(form.icon.iconUrl, {
            extension: last(form.icon.iconUrl.name.split('.')),
            name: `${skillId}-icon`,
          })
        : { url: form.icon?.iconUrl },
      isFile(form.licenseUrl)
        ? uploadLicense(form.licenseUrl, {
            extension: last(form.licenseUrl.name.split('.')),
            name: form.licenseUrl.name.split('.').slice(0, -1).join('.'),
          })
        : { url: form.licenseUrl },
      isFile(form.manualUrl)
        ? uploadManual(form.manualUrl, {
            extension: last(form.manualUrl.name.split('.')),
            name: form.manualUrl.name.split('.').slice(0, -1).join('.'),
          })
        : { url: form.manualUrl },
      isFile(form.screenshotUrls?.[0])
        ? uploadScreenShot1(form.screenshotUrls[0], {
            extension: last(form.screenshotUrls[0].name.split('.')),
            name: `${skillId}-screenshot-0`,
          })
        : { url: form.screenshotUrls?.[0] },
      isFile(form.screenshotUrls?.[1])
        ? uploadScreenShot2(form.screenshotUrls[1], {
            extension: last(form.screenshotUrls[1].name.split('.')),
            name: `${skillId}-screenshot-1`,
          })
        : { url: form.screenshotUrls?.[1] },
      isFile(form.screenshotUrls?.[2])
        ? uploadScreenShot3(form.screenshotUrls[2], {
            extension: last(form.screenshotUrls[2].name.split('.')),
            name: `${skillId}-screenshot-2`,
          })
        : { url: form.screenshotUrls?.[2] },
    ]);

    const compatibleDeviceModels = Object.entries(form.compatibleDeviceModels)
      .filter(([k, v]) => v)
      .map(([k]) => k);

    const preparedForm = {
      name: form.name,
      color: form.icon?.color,
      icon: form.icon?.icon,
      iconUrl: icon?.url,
      summary: form.summary,
      description: form.description,
      screenshotUrls: [screenshot1.url, screenshot2.url, screenshot3.url],
      licenseUrl: license.url,
      manualUrl: manual.url,
      compatibleFirmwareVersions: form.compatibleFirmwareVersions,
      compatibleDeviceModels,
      releaseNotes: form.releaseNotes,
      creditsCost: Number(form.creditsCost ?? 0),
      // tags,
      // price,
    };

    const selectedMarketplaceSkillVersion = skillData.marketplaceSkill.versions.data.find(
      v => v.originSkillVersionId === form.versionId,
    );

    return updateMarketplaceSkill.mutateAsync({
      // shared
      ...preparedForm,
      publicationState: selectedVersion.publicationState, // For processing, this will not write
      // for publish
      skillVersionId: form.versionId,
      // for update
      marketplaceSkillVersionId: selectedMarketplaceSkillVersion.id,
    });
  });

  const submitBtnText = {
    failed: 'Retry Publish',
    changesRequested: 'Resubmit',
  };

  const outletContext = [Form, skillQuery, proposedMarketplaceSkill];

  const selectedMarketplaceSkillVersion = skillData?.marketplaceSkill?.versions?.data?.find(
    v => v.originSkillVersionId === selectedVersion?.id,
  );
  const deprecated = selectedMarketplaceSkillVersion?.deprecated || false;

  return (
    <Modal>
      <Routes>
        <Route
          element={
            <ModalInner as="form" onSubmit={handleSubmit}>
              <ModalTitle
                title="Edit Listing"
                icon="SquaresFour"
                loading={isLoading}
                renderActions={() => (
                  <>
                    {
                      // The preview currently relies on the form values, which are undefined when the inputs are disabled.
                      // TODO: Remove this condition when previewing read-only skills is fixed.
                      selectedVersionIsEditable && (
                        <Button size="xs" variant="secondary" icon="Eye" to="../preview">
                          Preview
                        </Button>
                      )
                    }
                  </>
                )}
              />
              <ModalBody>
                {error && (
                  <Message variant="danger" title="Couldn't Load Skill">
                    {error.message}
                  </Message>
                )}
                {!isEmpty(generalError) && (
                  <Message variant="danger" title="Failed">
                    {generalError}
                  </Message>
                )}
                {(skillData?.marketplaceSkill?.versions?.data || [])
                  .filter(
                    v =>
                      !isEmpty(v.requestedChanges) &&
                      ['reviewing', 'changesRequested'].includes(v.publicationState),
                  )
                  .map(v => (
                    <Message key={v.id} title={`${v.version} Change Request`}>
                      {v.requestedChanges}
                    </Message>
                  ))}

                <div className="space-y-4">
                  <Card variant="secondary" className="space-y-4">
                    <div className="space-y-4">
                      <div className="space-y-2">
                        <Controller
                          name="icon"
                          control={Form.control}
                          rules={{
                            required: 'Please choose an icon.',
                          }}
                          render={({ field, fieldState }) => (
                            <IconChooser
                              {...field}
                              loading={isLoading}
                              disabled={!selectedVersionIsEditable}
                            />
                          )}
                        />
                        <ValidationError name="icon" errors={Form.formState.errors} />
                      </div>
                    </div>
                  </Card>

                  <Card variant="secondary" className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="name">Name</Label>
                      <Input
                        type="text"
                        {...Form.register('name', {
                          required: 'Please enter a name',
                        })}
                        readOnly={isLoading}
                        disabled={!selectedVersionIsEditable}
                      />
                      <ValidationError name="name" errors={Form.formState.errors} />
                    </div>
                  </Card>

                  <Tabs>
                    <Tab
                      onClick={() => {
                        setCurrentTab('version');
                      }}
                      active={currentTab === 'version'}
                      className="justify-center"
                    >
                      Version
                    </Tab>
                    <Tab
                      onClick={() => setCurrentTab('description')}
                      active={currentTab === 'description'}
                      className="justify-center"
                    >
                      Description
                    </Tab>
                    <Tab
                      onClick={() => setCurrentTab('compatibility')}
                      active={currentTab === 'compatibility'}
                      className="justify-center"
                    >
                      Compatibility
                    </Tab>
                    <Tab
                      onClick={() => setCurrentTab('images')}
                      active={currentTab === 'images'}
                      className="justify-center"
                    >
                      Images
                    </Tab>
                  </Tabs>

                  <Outlet context={outletContext} />

                  {currentTab === 'version' && (
                    <PublishSkillVersion
                      form={Form}
                      isLoading={isLoading}
                      versions={skillData?.skill?.versions?.data.filter(isSelectable) || []}
                      marketplaceVersions={skillData?.marketplaceSkill?.versions?.data || []}
                      disabled={!selectedVersionIsEditable}
                      setError={setGeneralError}
                      onChangeVersion={e => {
                        setGeneralError(null);
                        const nextSelectedVersion = e.currentTarget.value;
                        const msv = skillData?.marketplaceSkill?.versions?.data?.find?.(
                          v => v.originSkillVersionId === nextSelectedVersion,
                        );

                        // NOTE: Same as defaults
                        Form.reset({
                          versionId: nextSelectedVersion,
                          name: msv?.name || skillData?.skill?.name,
                          icon: {
                            icon: msv?.icon || skillData?.skill?.icon || 'action',
                            color: msv?.color || skillData?.skill?.color || '#00C425',
                            iconUrl: msv?.iconUrl || skillData?.skill?.iconUrl || null,
                          },
                          creditsCost: msv?.creditsCost ?? 0,
                          summary: msv?.summary || '',
                          description: msv?.description || '',
                          releaseNotes: msv?.releaseNotes || '',
                          compatibleFirmwareVersions: msv?.compatibleFirmwareVersions || '',
                          compatibleDeviceModels: {
                            vz1000: msv?.compatibleDeviceModels?.includes?.('vz1000') || false,
                            vz5000: msv?.compatibleDeviceModels?.includes?.('vz5000') || false,
                            compute550:
                              msv?.compatibleDeviceModels?.includes?.('compute550') || false,
                            cx1000: msv?.compatibleDeviceModels?.includes?.('cx1000') || false,
                            cx2000: msv?.compatibleDeviceModels?.includes?.('cx2000') || false,
                          },
                          licenseUrl: msv?.licenseUrl || null,
                          manualUrl: msv?.manualUrl || null,
                          screenshotUrls: [
                            msv?.screenshotUrls?.[0] || null,
                            msv?.screenshotUrls?.[1] || null,
                            msv?.screenshotUrls?.[2] || null,
                          ],
                          deprecated: msv?.deprecated || false,
                        });
                      }}
                    />
                  )}
                  {currentTab === 'description' && (
                    <PublishSkillDescription
                      form={Form}
                      skill={skillQuery}
                      disabled={!selectedVersionIsEditable}
                    />
                  )}
                  {currentTab === 'compatibility' && (
                    <PublishSkillCompatibility
                      form={Form}
                      skill={skillQuery}
                      disabled={!selectedVersionIsEditable}
                    />
                  )}
                  {currentTab === 'images' && (
                    <PublishSkillImages form={Form} disabled={!selectedVersionIsEditable} />
                  )}
                </div>
              </ModalBody>
              <ModalFooter className={!selectedVersionIsEditable && 'h-auto'}>
                <div className="flex flex-col space-y-4 my-4">
                  {(!selectedVersionIsEditable || deprecated) && (
                    <ValidationError
                      message={`This version ${
                        !selectedVersionIsEditable ? 'cannot be modified' : ''
                      }${!selectedVersionIsEditable && deprecated ? ' and ' : ''}${
                        deprecated ? 'has been deprecated' : ''
                      }`}
                    />
                  )}
                  <span className="flex flex-col justify-center items-center space-y-4">
                    <Button
                      size="xl"
                      type="submit"
                      loading={Form.formState.isSubmitting}
                      disabled={!selectedVersionIsEditable}
                    >
                      {submitBtnText[selectedVersion?.publicationState] || 'Save'}
                    </Button>
                  </span>
                </div>
              </ModalFooter>
            </ModalInner>
          }
        >
          <Route index element={null} />
          <Route
            element={
              <Modal>
                <Outlet context={outletContext} />
              </Modal>
            }
          >
            <Route
              path="preview/*"
              element={
                <>
                  <PublishSkillPreview hideSubmitBtn />
                  <Outlet />
                </>
              }
            >
              <Route index element={null} />
              <Route
                path="gallery"
                element={<MarketplaceDetailGallery marketplaceSkill={proposedMarketplaceSkill} />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Modal>
  );
}
