import { api, q } from 'config/api';

export default function useDevicesSelector(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['devices', 'selector', variables],
    queryFn({ pageParam }) {
      return api(
        `query devices($list: listFields) {
          list: devices(list: $list) {
            count
            data {
              id
              createdAt
              name
              model
              status
              autoClaim
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    select: data => (data?.pages || []).flatMap(page => page.list?.data || []),
    ...overrides,
  });
}
