import { Link, Route, Routes } from 'react-router-dom';

import { Text, Icon } from '@optra/kit';

import Modal from 'components/modal';
import EditOutput from 'modals/edit-output';
import ManageOutputs from 'modals/manage-outputs';
import RouteTitle from 'router/RouteTitle';

export default function ManageOutputsLink(props) {
  const { className, noRoutes = false } = props || {};
  return (
    <>
      <Link to="./manage-outputs" className={className}>
        <Icon name="Gear" size="xs" className="mr-1" />
        <Text size="xs" color="muted">
          Manage Outputs
        </Text>
      </Link>
      {!noRoutes && (
        <Routes>
          <Route
            path="manage-outputs"
            element={
              <RouteTitle title="Manage Outputs">
                <Modal>
                  <ManageOutputs />
                </Modal>
              </RouteTitle>
            }
          />
          <Route
            path="manage-outputs/:outputKey/edit"
            element={
              <RouteTitle title="Edit Output">
                <Modal>
                  <EditOutput />
                </Modal>
              </RouteTitle>
            }
          />
        </Routes>
      )}
    </>
  );
}
