import { uniq, flatMap } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@optra/kit';

import DevicesChooser from 'components/devices-chooser';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import { useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useChooseWorkflowDevices } from 'queries';

export default function ChooseWorkflowDevices() {
  const { workflowId } = useParams();
  const [error, setError] = useState();
  const [deviceIds, setDeviceIds] = useState({
    push: [],
    pull: [],
  });
  const { handleClose } = useModalContext();

  const [filter, setFilter] = useState({});
  const devicesNames = useChooseWorkflowDevices(workflowId, { list: { filter } });
  const devices = flatMap(devicesNames?.data?.pages, page => page?.list?.data);

  const qc = q.useQueryClient();
  const updateWorkflow = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflow($form: updateWorkflowForm!) {
          updateWorkflow(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSelectDevice = (selected, device) => {
    if (selected) {
      setDeviceIds({
        push: uniq(deviceIds.push.filter(d => d !== device.id)),
        pull: uniq([...deviceIds.pull, device.id]),
      });
    } else {
      setDeviceIds({
        push: uniq([...deviceIds.push, device.id]),
        pull: uniq(deviceIds.pull.filter(d => d !== device.id)),
      });
    }
  };

  const handleSave = async () => {
    updateWorkflow.mutate({
      id: workflowId,
      deviceIds,
    });
  };

  const loading = devicesNames.isLoading || devicesNames.error || updateWorkflow.isPending;

  const itemNotFound = useItemNotFound({
    fetching: devicesNames.isLoading,
    id: workflowId,
  });

  if (itemNotFound) {
    return <ItemNotFound id={workflowId} type="Workflow" />;
  }

  return (
    <ModalInner>
      <ModalTitle
        title="Choose Devices"
        icon="Aperture"
        renderActions={() => (
          <Button
            to={`/workflows/${workflowId}/groups/create`}
            state={{ fromModal: true }}
            icon="FolderPlus"
            size="xs"
            variant="secondary"
          >
            Create Group
          </Button>
        )}
      />
      <ModalBody size="lg">
        {error && (
          <Message variant="danger" title="Couldn't Save Devices">
            {error.message}
          </Message>
        )}
        {devicesNames.error && (
          <Message variant="danger" title="Couldn't Load Devices">
            {devicesNames.error.message}
          </Message>
        )}
        <DevicesChooser
          isLoading={devicesNames?.isLoading}
          devices={devices || []}
          showEnrollmentButton={devicesNames.data?.pages?.[0]?.total?.count === 0}
          showCompatibility
          error={devicesNames.error}
          hasNextPage={devicesNames?.hasNextPage}
          fetchNextPage={devicesNames?.fetchNextPage}
          isFetchingNextPage={devicesNames?.isFetchingNextPage}
          onSelectDevice={handleSelectDevice}
          badgeType="Group"
          filter={filter}
          onFilter={setFilter}
          newSelectedDevices={deviceIds.push}
          newDeselectedDevices={deviceIds.pull}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="xl" loading={loading} onClick={handleSave}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
