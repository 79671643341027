import {
  isEmpty,
  template,
  union,
  unionBy,
  uniq,
  isFinite as _isFinite,
  uniqBy,
  sortBy,
} from 'lodash';
import { z } from 'zod';

function getBinding(str) {
  // NOTE: Permissions are always 3rd and are the only optional field
  const [binding = '', path = '', permissions = 'rw'] = str?.split?.(':') || [];
  return {
    binding,
    path,
    permissions,
    normalized: `${binding}:${path}:${permissions}`,
  };
}

function parseCGroupRule(rule) {
  const [type, majorAndMinor, access] = rule.split(' ');
  const [major, minor] = majorAndMinor.split(':');
  return {
    type,
    major,
    minor,
    access,
  };
}

/**
 * Known keys as defined in the spec [here]{@link https://github.com/Azure/iotedge/tree/main/edge-agent/src/Microsoft.Azure.Devices.Edge.Agent.Docker/models}
 */

export const createOptionsSchema = z
  .object({
    Hostname: z.string().nullish(),
    Domainname: z.string().nullish(),
    User: z.string().nullish(),
    AttachStdin: z.boolean().nullish(),
    AttachStdout: z.boolean().nullish(),
    AttachStderr: z.boolean().nullish(),
    Tty: z.boolean().nullish(),
    OpenStdin: z.boolean().nullish(),
    StdinOnce: z.boolean().nullish(),
    Env: z.array(z.string()).nullish(),
    Cmd: z.array(z.string()).nullish(),
    Entrypoint: z.string().nullish(),
    Image: z.string().nullish(),
    Labels: z.record(z.string()).nullish(),
    Volumes: z.record(z.object({}).passthrough()).nullish(),
    WorkingDir: z.string().nullish(),
    NetworkDisabled: z.boolean().nullish(),
    MacAddress: z.string().nullish(),
    ExposedPorts: z.record(z.object({}).passthrough()).nullish(),
    StopSignal: z.string().nullish(),
    StopTimeout: z.number().int().positive().nullish(),
    HostConfig: z
      .object({
        AutoRemove: z.boolean().nullish(),
        Binds: z.array(z.string()).nullish(),
        BlkioDeviceReadBps: z
          .array(
            z
              .object({
                Path: z.string(),
                Rate: z.number(),
              })
              .strict(),
          )
          .nullish(),
        BlkioDeviceReadIOps: z
          .array(
            z
              .object({
                Path: z.string(),
                Rate: z.number(),
              })
              .strict(),
          )
          .nullish(),
        BlkioDeviceWriteBps: z
          .array(
            z
              .object({
                Path: z.string(),
                Rate: z.number(),
              })
              .strict(),
          )
          .nullish(),
        BlkioDeviceWriteIOps: z
          .array(
            z
              .object({
                Path: z.string(),
                Rate: z.number(),
              })
              .strict(),
          )
          .nullish(),
        BlkioWeight: z.number().int().min(0).max(1000).nullish(),
        BlkioWeightDevice: z
          .array(
            z
              .object({
                Path: z.string(),
                Weight: z.number().int().min(0),
              })
              .strict(),
          )
          .nullish(),
        CpuCount: z.number().int().nullish(),
        CpuPercent: z.number().int().nullish(),
        CpuPeriod: z.number().int().nullish(),
        CpuQuota: z.number().int().nullish(),
        CpuRealtimePeriod: z.number().int().nullish(),
        CpuRealtimeRuntime: z.number().int().nullish(),
        CpuShares: z.number().int().nullish(),
        CapAdd: z.array(z.string()).nullish(),
        CapDrop: z.array(z.string()).nullish(),
        Cgroup: z.string().nullish(),
        CgroupParent: z.string().nullish(),
        ConsoleSize: z.tuple([z.number(), z.number()]).nullish(),
        ContainerIDFile: z.string().nullish(),
        CpusetCpus: z.string().nullish(),
        CpusetMems: z.string().nullish(),
        Dns: z.array(z.string()).nullish(),
        DnsOptions: z.array(z.string()).nullish(),
        DnsSearch: z.array(z.string()).nullish(),
        DiskQuota: z.number().int().nullish(),
        ExtraHosts: z.array(z.string()).nullish(),
        GroupAdd: z.array(z.string()).nullish(),
        IOMaximumIOps: z.number().int().nullish(),
        IOMaximumBandwidth: z.number().int().nullish(),
        Init: z.boolean().nullish(),
        InitPath: z.string().nullish(),
        IpcMode: z
          .string()
          .regex(
            /(none|private|shareable|host|^container:.*)/,
            'Must be one of none, private, shareable, host, or container:*',
          )
          .nullish(),
        Isolation: z.enum(['default', 'process', 'hyperv']).nullish(),
        KernelMemory: z.number().int().nullish(),
        Links: z.array(z.string()).nullish(),
        MaximumIOBps: z.number().int().nullish(),
        MaximumIOps: z.number().int().nullish(),
        Memory: z.number().int().nullish(),
        MemoryReservation: z.number().int().nullish(),
        MemorySwap: z.number().int().nullish(),
        MemorySwappiness: z.number().int().min(0).max(100).nullish(),
        NanoCpus: z.number().int().nullish(),
        NetworkMode: z.string().nullish(),
        OomKillDisable: z.boolean().nullish(),
        OomScoreAdj: z.number().int().nullish(),
        PidMode: z
          .string()
          .regex(/(host|$container:.*)/, 'Must be one of host or container:*')
          .nullish(),
        PidsLimit: z.number().int().nullish(),
        Privileged: z.boolean().nullish(),
        PublishAllPorts: z.boolean().nullish(),
        ReadonlyRootfs: z.boolean().nullish(),
        Runtime: z.string().nullish(),
        SecurityOpt: z.array(z.string()).nullish(),
        ShmSize: z.number().int().min(0).nullish(),
        StorageOpt: z.object({}).passthrough().nullish(),
        Sysctls: z.object({}).passthrough().nullish(),
        Tmpfs: z.object({}).passthrough().nullish(),
        UTSMode: z.string().nullish(),
        Ulimits: z
          .array(
            z
              .object({
                Name: z.string(),
                Soft: z.number().int(),
                Hard: z.number().int(),
              })
              .strict(),
          )
          .nullish(),
        UsernsMode: z.string().nullish(),
        VolumeDriver: z.string().nullish(),
        VolumesFrom: z.array(z.string()).nullish(),
        // OtherProperties // ignored
        PortBindings: z
          .record(
            z.array(
              z
                .object({
                  HostIp: z.string().nullish(),
                  HostPort: z.string(),
                  // OtherProperties // ignored
                })
                .passthrough(),
            ),
          )
          .nullish(),
        Devices: z
          .array(
            z
              .object({
                PathOnHost: z.string().nullish(),
                PathInContainer: z.string().nullish(),
                CgroupPermissions: z.string().nullish(),
                // OtherProperties // ignored
              })
              .passthrough(),
          )
          .nullish(),
        Mounts: z
          .array(
            z
              .object({
                ReadOnly: z.boolean().nullish(),
                Source: z.string().nullish(),
                Target: z.string().nullish(),
                Type: z.string().nullish(),
                // OtherProperties // ignored
              })
              .passthrough(),
          )
          .nullish(),
        LogConfig: z
          .object({
            Type: z.enum([
              'json-file',
              'syslog',
              'journald',
              'gelf',
              'fluentd',
              'awslogs',
              'splunk',
              'etwlogs',
              'none',
            ]),
            Config: z.object({}).passthrough().nullish(),
            // OtherProperties // ignored
          })
          .passthrough()
          .nullish(),
        RestartPolicy: z
          .object({
            Name: z.enum(['', 'always', 'unless-stopped', 'on-failure']),
            MaximumRetryCount: z.number().int(),
            // OtherProperties // ignored
          })
          .passthrough()
          .nullish(),
      })
      .nullish(),
    NetworkingConfig: z
      .object({
        EndpointsConfig: z.record(
          z
            .object({
              IPAMConfig: z
                .object({
                  IPv4Address: z.string().nullish(),
                  IPv6Address: z.string().nullish(),
                  LinkLocalIPs: z.array(z.string()).nullish(),
                })
                .strict()
                .nullish(),
              Links: z.array(z.string()).nullish(),
              Aliases: z.array(z.string()).nullish(),
              NetworkID: z.string().nullish(),
              EndpointID: z.string().nullish(),
              Gateway: z.string().nullish(),
              IPAddress: z.string().nullish(),
              IPPrefixLen: z.number().int().nullish(),
              IPv6Gateway: z.string().nullish(),
              GlobalIPv6Address: z.string().nullish(),
              GlobalIPv6PrefixLen: z.number().int().nullish(),
              MacAddress: z.string().nullish(),
              DriverOpts: z.object({}).passthrough().nullish(),
              // OtherProperties // ignored
            })
            .passthrough(),
        ),
        // OtherProperties // ignored
      })
      .passthrough()
      .nullish(),
  })
  .strict();

export default async function calculateRawConfig({ form = {} }) {
  const {
    hostName,
    endpointAliases,
    portBindings,
    tmpfs,
    shmSize,
    writableRootFS,
    createOptions = '{}',
    mountVolumes,
  } = form;
  try {
    if (!isEmpty(createOptions)) {
      JSON.parse(
        template(createOptions)({
          exposedUIPort: 9000, // placeholder port to test for template errors! Do not wire up!
          hostUIPort: 9000, // placeholder port to test for template errors! Do not wire up!
          id: '1234', // placeholder id to test for template errors! Do not wire up!
        }),
      );
    }
  } catch (err) {
    return {
      data: {},
      errors: [err.message],
    };
  }

  const parsed = isEmpty(createOptions) ? {} : JSON.parse(createOptions);
  const customHostConfig = parsed.HostConfig || {};

  const gpioDevice = form.gpio
    ? [
        {
          PathOnHost: '/dev/gpiochipUSR',
          PathInContainer: '/dev/gpiochipUSR',
          CgroupPermissions: 'rwm',
        },
      ]
    : [];

  const hdmi = form.devices?.hdmi ? ['DISPLAY=:0'] : [];

  // binds
  const dockerInDockerBinds = form.dockerInDocker
    ? ['/var/run/docker.sock:/var/run/docker.sock', '/usr/bin/docker:/usr/bin/docker']
    : [];
  const deviceBinds = form.addedDevice?.length > 0 ? ['/dev:/dev:ro'] : [];
  const cx2000IRRemoteBinds = form.cx2000IRRemote ? ['/dev:/dev:ro'] : [];
  const usbSerialConverterBinds = form.usbSerialConverter ? ['/dev:/dev:ro'] : [];
  const gpioBinds = form.gpio ? ['/dev:/dev:ro'] : [];
  const ledBinds = form.led
    ? [
        '/sys/devices/platform/leds/leds/player-red:/leds/player-red:rw',
        '/sys/devices/platform/leds/leds/player-green:/leds/player-green:rw',
        '/sys/devices/platform/leds/leds/player-blue:/leds/player-blue:rw',
        '/sys/devices/platform/leds/leds/status-red:/leds/status-red:rw',
        '/sys/devices/platform/leds/leds/status-green:/leds/status-green:rw',
        '/sys/devices/platform/leds/leds/status-blue:/leds/status-blue:rw',
        '/sys/devices/platform/leds/leds/setup-green:/leds/setup-green:rw',
      ]
    : [];
  const cx2000VideoAccelerationBinds = form.cx2000VideoAcceleration ? ['/dev:/dev:ro'] : [];
  const allBinds = union(
    (form.storage || [])
      .filter(b => !isEmpty(b))
      .map(({ key, value }) => {
        const binding = getBinding(`${key}:${value}`);
        return binding.normalized;
      }),
    form.devices?.sound ? ['/etc/asound.conf:/etc/asound.conf', '/dev:/dev:ro'] : [],
    form.devices?.hdmi ? ['/tmp/.X11-unix:/tmp/.X11-unix'] : [],
    form.removableMedia ? ['/run/removable_media:/media:shared'] : [],
    form.devices?.cameras ? ['/dev:/dev:ro', '/tmp/argus_socket:/tmp/argus_socket:rw'] : [],
    dockerInDockerBinds,
    (form.binds || [])
      .filter(b => !isEmpty(b))
      .map(bind => `${bind.hostPath}:${bind.containerPath}`),
    deviceBinds,
    cx2000IRRemoteBinds,
    usbSerialConverterBinds,
    gpioBinds,
    ledBinds,
    cx2000VideoAccelerationBinds,
    customHostConfig.Binds || [],
  );

  // Filter duplicates in Binds, but only based on name and path (ignore permissions)
  const Binds = allBinds.reduce((binds, bind) => {
    const binding = getBinding(bind);

    // If the bind name and path match an existing entry, replace it
    const hasDuplicates = binds.some(existingBind => {
      const otherBinding = getBinding(existingBind);
      return otherBinding.binding === binding.binding && otherBinding.path === binding.path;
    });
    if (hasDuplicates) return binds;

    return [...binds, binding.normalized];
  }, []);

  // CgroupRules
  const standardCgroupRules = ['c 10:59 rwm', 'c 10:60 rwm'];
  const soundRules = form.devices?.sound ? ['c 116:* rmw'] : [];
  const deviceCgroupRules = (form.addedDevice || [])
    .filter(device => _isFinite(device?.majorNumber))
    .map(device => `c ${device.majorNumber}:* rmw`);
  const cx2000IRRemoteRules = form.cx2000IRRemote ? ['c 13:* r'] : [];
  const usbSerialConverterRules = form.usbSerialConverter ? ['c 188:* rmw', 'c 189:* rmw'] : [];
  const cx2000VideoAccelerationRules = form.cx2000VideoAcceleration
    ? ['c 10:* rmw', 'c 29:* rmw', 'c 226:* rmw', 'c 242:* rmw', 'c 249:* rmw', 'c 252:* rmw']
    : [];
  const usbCameraRules = form.devices?.cameras ? ['c 81:* rmw'] : [];

  const dedupedCgroupRules = sortBy(
    uniqBy(
      [
        ...(customHostConfig.DeviceCgroupRules || []),
        ...standardCgroupRules,
        ...usbCameraRules,
        ...soundRules,
        ...deviceCgroupRules,
        ...cx2000IRRemoteRules,
        ...usbSerialConverterRules,
        ...cx2000VideoAccelerationRules,
      ],
      rule => {
        const { major, minor } = parseCGroupRule(rule);
        return `${major}:${minor}`;
      },
    ),
    rule => {
      const { major } = parseCGroupRule(rule);
      return parseInt(major);
    },
  );
  const DeviceCgroupRules = !isEmpty(dedupedCgroupRules) ? dedupedCgroupRules : undefined;

  const allEnv = uniq([...hdmi]);
  const Env = allEnv.length > 0 ? allEnv : undefined;

  const skillVersionPort = form.port;

  const calculatedCreateOptions = {
    ...(parsed || {}),
    HostConfig: {
      ...(customHostConfig || {}),
      Binds,
      DeviceCgroupRules,
      Privileged: form.privileged || undefined,
      CapAdd:
        form.capAdd?.length > 0
          ? form.capAdd.filter(c => c && c.capability?.trim()).map(c => c.capability)
          : undefined,
      CapDrop:
        form.capDrop?.length > 0
          ? form.capDrop.filter(c => c && c.capability?.trim()).map(c => c.capability)
          : undefined,
      Devices: unionBy(
        form.devices?.sound
          ? [
              {
                CgroupPermissions: 'rwm',
                PathInContainer: '/dev/snd',
                PathOnHost: '/dev/snd',
              },
            ]
          : [],
        [...gpioDevice],
        customHostConfig.Devices || [],
        d => `${d.PathInContainer}${d.PathOnHost}`,
      ),
      StorageOpt: writableRootFS ? { size: `${writableRootFS}k` } : undefined,
      PortBindings: {
        ...(customHostConfig.PortBindings || {}),
        ...(portBindings?.length > 0
          ? portBindings.reduce(
              (bindings, binding) => ({
                ...bindings,
                [`${binding.containerPort}/${binding.protocol}`]: [
                  { HostPort: `${binding.hostPort}` },
                ],
              }),
              {},
            )
          : {}),
        ...(!!skillVersionPort
          ? {
              [`${skillVersionPort}/tcp`]: [{ HostPort: '<User Selected Port>' }], // eslint-disable-line no-template-curly-in-string
            }
          : {}),
      },
      Mounts: unionBy(
        [
          ...(tmpfs || [])
            .filter(t => !isEmpty(t))
            .map(t => ({
              Type: 'tmpfs',
              Target: t.containerDevicePath,
              ...(t.sizeBytes ? { TmpfsOptions: { SizeBytes: t.sizeBytes } } : {}),
            })),
          ...(mountVolumes || [])
            .filter(t => !isEmpty(t))
            .map(volume => ({
              Type: 'volume',
              Source: volume.volumeName,
              Target: volume.mountPath,
              VolumeOptions: {
                DriverConfig: {
                  Options: {
                    size: volume.sizeLimit,
                  },
                },
              },
            })),
        ],
        customHostConfig.Mounts || [],
        m => `${m.Target}${m.Source}${m.Type}`,
      ),
      ...(shmSize ? { ShmSize: shmSize } : {}),
      ...(form.hostNetworking ? { NetworkMode: 'host' } : {}),
    }, // End HostConfig
    Env,
    ...(!isEmpty(hostName) ? { Hostname: hostName } : {}),
    ...(endpointAliases?.length > 0 || form.hostNetworking
      ? {
          NetworkingConfig: {
            EndpointsConfig: {
              ...(parsed?.NetworkingConfig?.EndpointsConfig || {}),
              ...(form.hostNetworking ? { host: {} } : {}),
              ...(endpointAliases?.length > 0
                ? {
                    'azure-iot-edge': {
                      Aliases: union(
                        endpointAliases.filter(t => !isEmpty(t)).map(a => a?.alias),
                        parsed?.NetworkingConfig?.EndpointsConfig?.['azure-iot-edge']?.Aliases ||
                          [],
                      ),
                    },
                  }
                : {}),
            },
          },
        }
      : {}),
  };

  return createOptionsSchema
    .safeParseAsync(calculatedCreateOptions)
    .then(({ error }) => ({
      data: calculatedCreateOptions,
      errors: error?.errors || [],
    }))
    .catch(err => ({
      data: calculatedCreateOptions,
      errors: [err?.message],
    }));
}
