import { Badge, Icon } from '@optra/kit';
import { creditsToFormattedString } from '@optra/shared/dist/utils/formatCredits';

export default function CreditsBadge({ numCredits = 0, size = 'sm', ...rest }) {
  return (
    <Badge size={size} variant="inverted" {...rest}>
      <Icon name="Coins" weight="fill" size={size} className="mr-1" />
      {creditsToFormattedString(numCredits)}
    </Badge>
  );
}
