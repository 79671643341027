import { Card, Icon, Text } from '@optra/kit';
import { parseWholeCredits } from '@optra/shared/dist/utils/formatCredits';

import dateLabel from 'lib/date-label';

export default function AdminGiftCardUsage({ giftCard }) {
  const amountRemaining = giftCard?.balance || 0;
  const getUsagePercentage = () => ((amountRemaining / giftCard?.amount) * 100).toFixed(2);

  return (
    <Card variant="primary" className="space-y-2">
      <div className="flex items-center justify-between">
        <div className="flex flex-row">
          {giftCard?.isRevoked ? (
            <>
              <Icon name="Prohibit" className="mr-2 mt-1" />
              <Text size="lg" color="muted" className="block font-mono pt-1">
                <span>Revoked on </span>
                <span className="italic">{dateLabel(giftCard?.revokedOn)}</span>
              </Text>
            </>
          ) : giftCard?.isRedeemed ? (
            <>
              <Icon name="CheckCircle" className="mr-2 mt-1" weight="fill" />
              <Text size="lg" color="muted" className="block font-mono pt-1">
                <span>Redeemed on </span>
                <span className="italic">{dateLabel(giftCard?.redeemedOn)}</span>
              </Text>
            </>
          ) : (
            <>
              <Icon name="Clock" className="mr-2 mt-1" />
              <Text size="lg" color="muted" className="block font-mono pt-1">
                Not yet redeemed
              </Text>
            </>
          )}
        </div>
      </div>

      <div className="shadow w-full bg-gray-800">
        <div
          className="bg-primary leading-none py-1 text-center"
          style={{ width: `${getUsagePercentage()}%` }}
        >
          <Text size="xs">{getUsagePercentage()}%</Text>
        </div>
      </div>

      <Text size="md" color="muted" className="block font-mono">
        {parseWholeCredits(amountRemaining)} of {parseWholeCredits(giftCard?.amount)} credits
        remaining
      </Text>
    </Card>
  );
}
