import { Button } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import RouteTitle from 'router/RouteTitle';

export default function ItemNotFound(props) {
  const {
    id,
    type,
    handleClose: _handleClose,
    handleBack: _handleBack,
    showBackButton: _showBackButton,
  } = props;
  const context = useModalContext();
  const handleBack = _handleBack || context.handleBack;
  const handleClose = _handleClose || context.handleClose;
  const showBackButton = _showBackButton || context.showBackButton;

  return (
    <RouteTitle title="Item Not Found">
      <ModalInner>
        <ModalTitle
          title="Item Not Found"
          icon="Question"
          handleClose={handleClose}
          handleBack={handleBack}
          showBackButton={showBackButton}
        />
        <ModalBody>
          <EmptyContentMessage
            title="Item Not Found"
            body={`No ${type} Found for ID “${id}”`}
            renderAction={() => <Button onClick={handleClose}>Close</Button>}
          />
        </ModalBody>
      </ModalInner>
    </RouteTitle>
  );
}
