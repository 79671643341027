import { isEmpty, keyBy, uniq } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Table, Text, StatusIndicator } from '@optra/kit';

import DeviceImage from 'components/device-image';
import { useFeature } from 'components/feature';
import { api, q } from 'config/api';
import useDevicesColumns from 'hooks/use-devices-columns';
import useIsVisible from 'hooks/use-is-visible';
import useMapStateOutput from 'hooks/use-mapState-output';
import { useDevice } from 'queries';

import DeviceListItemColumn from './device-list-item-column';

function DeviceListItemStatusIndicator(props) {
  const { deviceId, shouldFetch, autoClaim } = props;
  const [selectedOutput] = useMapStateOutput();
  const MapState = q.useQuery({
    queryKey: ['device', deviceId, 'mapState', selectedOutput.key],
    queryFn: () =>
      api(
        `query device($deviceId: ID!, $outputKey: String) {
          device(id: $deviceId) {
            mapState(outputKey: $outputKey)
          }
        }`,
        { deviceId, outputKey: selectedOutput.key },
      ),
    select: data => data?.device?.mapState,
    enabled: shouldFetch,
  });

  const status = autoClaim ? 'pending' : MapState.data;

  return <StatusIndicator status={status} size="md" />;
}

// TODO: Add soft loading indicator
export default function DeviceListItem(props) {
  const {
    device: initialDevice = {},
    selected,
    onSelect,
    selectable: _selectable = true,
    canEdit,
  } = props;
  const deviceId = initialDevice.id;
  const navigate = useNavigate();
  const deviceConnectionEnabled = useFeature('deviceConnection');
  const [visibleColumns] = useDevicesColumns();
  const [visibilityRef, isVisible] = useIsVisible();
  const visibleFields = uniq(
    visibleColumns
      .filter(columnDef => !isEmpty(columnDef.queryFragment))
      .map(columnDef => columnDef.queryFragment),
  ).join('\n');

  const Device = useDevice(
    deviceId,
    `
      id
      createdAt
      model
      autoClaim
      ${visibleFields}
    `,
    {
      initialData: { device: initialDevice },
      select: data => ({
        ...data.device,
        outputs: keyBy(data.device?.outputs, 'key'),
      }),
      enabled: isVisible,
    },
  );
  const device = Device.data;

  const selectable = device?.autoClaim ? _selectable : true;

  const onEdit = () => navigate(`/devices/${device?.id}`);
  const handleSelect = selectable
    ? e => {
        onSelect(device.id);
        e?.stopPropagation();
      }
    : null;

  return (
    <Table.Row
      caret={canEdit}
      hover={canEdit}
      disabledSelectable={!selectable}
      selectable={true}
      selected={selected}
      onSelect={handleSelect}
      onClick={canEdit ? onEdit : null}
      ref={visibilityRef}
    >
      <Table.TD collapsed>
        <DeviceImage model={device.model} size="sm" hideStatus />
      </Table.TD>
      {deviceConnectionEnabled && (
        <Table.TD collapsed>
          <DeviceListItemStatusIndicator
            deviceId={initialDevice.id}
            shouldFetch={isVisible}
            autoClaim={device?.autoClaim}
          />
        </Table.TD>
      )}
      <Table.TD className="lg:hidden">
        <Text size="sm">{device?.name}</Text>
      </Table.TD>
      {visibleColumns.map(column => (
        <Table.TD
          className="hidden lg:table-cell"
          style={{ minWidth: 100, maxWidth: 240 }}
          key={column.id}
        >
          <DeviceListItemColumn column={column} device={device} />
        </Table.TD>
      ))}
    </Table.Row>
  );
}
