import { flatMap, isEmpty } from 'lodash';
import { useState, Fragment } from 'react';

import DownloadLogsButton from 'components/download-logs-button';
import { useFeature } from 'components/feature';
import Label from 'components/label';
import LogsScroller from 'components/logs-scroller';
import ModalBody from 'components/modal-body';
import Select from 'components/select';
import SSHButton from 'components/ssh-button';
import { useSearchParams } from 'hooks';
import formatUserLog from 'lib/format-user-log';
import { useDeviceHardwareLogs, useDeviceTelemetryLogs, useDeviceWorkflowSkills } from 'queries';

export default function DeviceDetailLogs(props) {
  const { deviceId } = props;
  const [{ workflowSkillId }] = useSearchParams();
  const [selectedWorkflowSkillId, setSelectedWorkflowSkillId] = useState(workflowSkillId);
  const deviceTelemetryEnabled = useFeature('deviceTelemetry');
  const hardwareLogsEnabled = useFeature('hardwareLogs');
  const skillLogsEnabled = useFeature('skillLogs');

  // TODO: Lazy load all or implement some form of pagination
  const _workflowSkills = useDeviceWorkflowSkills(deviceId);
  const workflowSkills = {
    data: _workflowSkills.data?.device?.workflowSkills?.data || [],
    fetching: _workflowSkills.isLoading,
  };

  const viewingTelemetryLogs = isEmpty(selectedWorkflowSkillId);
  const telemetryLogs = useDeviceTelemetryLogs(deviceId, undefined, {
    enabled: viewingTelemetryLogs && deviceTelemetryEnabled,
  });
  const flatTelemetryLogs = flatMap(telemetryLogs.data?.pages, page => page?.logs);

  const isFetchingDockerCompose = selectedWorkflowSkillId?.includes('.');
  const skillIdToFetch = isFetchingDockerCompose
    ? selectedWorkflowSkillId.split('.')[0]
    : selectedWorkflowSkillId;
  const hardwareLogs = useDeviceHardwareLogs(
    {
      deviceId,
      workflowSkillId: skillIdToFetch === 'hardware' ? '' : skillIdToFetch,
      composeName: isFetchingDockerCompose ? selectedWorkflowSkillId.split('.')[1] : undefined,
    },
    { enabled: !viewingTelemetryLogs && hardwareLogsEnabled },
  );

  const handleChangeSkillId = event => {
    setSelectedWorkflowSkillId(event.target.value);
    hardwareLogs.setRange({ until: Date.now() });
  };

  return (
    <ModalBody size="lg">
      <div className="md:flex items-center justify-between mb-4 space-y-2">
        <div className="md:flex md:items-center md:justify-center md:space-x-2 space-y-2 md:space-y-0">
          <DownloadLogsButton
            loading={viewingTelemetryLogs ? telemetryLogs.isLoading : hardwareLogs.isLoading}
            logs={
              viewingTelemetryLogs
                ? flatTelemetryLogs.map(formatUserLog)
                : (hardwareLogs?.logs || []).map(log => log.message)
            }
          />
          <SSHButton deviceId={deviceId} />
        </div>
        <Label className="flex items-center justify-center space-x-2 w-60">
          Log
          <Select className="ml-2" onChange={handleChangeSkillId} value={selectedWorkflowSkillId}>
            {deviceTelemetryEnabled && <option value="">Telemetry Logs</option>}
            {hardwareLogsEnabled && <option value="hardware">Hardware Logs</option>}
            {skillLogsEnabled &&
              workflowSkills.data.map(ws => (
                <Fragment key={ws.id}>
                  <option value={ws.id}>{`${ws.name || ws.skill.name} Logs`}</option>
                  {ws.deviceComposeSkills?.map(cs => (
                    <option key={`${ws.id}.${cs.name}`} value={`${ws.id}.${cs.name}`}>
                      {`${cs.name} Logs`}
                    </option>
                  ))}
                </Fragment>
              ))}
          </Select>
        </Label>
      </div>

      {viewingTelemetryLogs && (
        <LogsScroller
          logs={flatTelemetryLogs}
          loading={telemetryLogs.isLoading}
          hasMore={telemetryLogs.hasNextPage}
          loadingMore={telemetryLogs.isFetchingNextPage}
          onLoadMore={telemetryLogs.fetchNextPage}
        />
      )}

      {!viewingTelemetryLogs && (
        <LogsScroller
          loading={hardwareLogs.isLoading}
          logs={hardwareLogs.logs}
          formatLog={log => log.message}
          endless
        />
      )}
    </ModalBody>
  );
}
