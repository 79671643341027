import * as date from 'date-fns';
import { flatMap } from 'lodash';
import { useParams } from 'react-router-dom';

import { Button, Icon, Text, StatusIndicator } from '@optra/kit';

import DeviceImage from 'components/device-image';
import { useFeature } from 'components/feature';
import LogsScroller from 'components/logs-scroller';
import SyncProgressDeviceSkillStatus from 'components/sync-progress-device-skill-status';
import { api, q } from 'config/api';
import { STATUS_MAP, STATUS_LABEL_MAP } from 'config/deployment-task-status-maps';
import { useDeploymentTaskLogs } from 'queries';
import { isComplete } from 'queries/use-deploy-workflow';

import { useHasRoles } from '../hooks';

export default function SyncProgressDeviceLogs({ handleBack, workflowId }) {
  const { taskId } = useParams();
  const skillStatusEnabled = useFeature('skillStatus');
  const [canDeploy] = useHasRoles(['admin', 'workflowDeployer']);

  const deploymentTask = q.useQuery({
    queryKey: ['workflowDeploymentExecutionTask', taskId],
    queryFn() {
      return api(
        `query workflowDeploymentExecutionTask($taskId: ID!) {
            task: workflowDeploymentExecutionTask(id: $taskId) {
              id
              status
              error
              completedAt
              skillsStatus
              deployment {
                id
              }
              device {
                id
                name
                model
              }
            }
          }`,
        { taskId },
      );
    },
    placeholderData: q.keepPreviousData,
    refetchInterval: 10000,
    enabled: !!taskId,
  });

  const deploymentTaskLogs = useDeploymentTaskLogs({
    taskId,
  });
  const flatLogs = flatMap(deploymentTaskLogs.data?.pages, page => page?.logs);

  const task = deploymentTask.data?.task;
  const device = task?.device;

  const qc = q.useQueryClient();
  const retryTask = q.useMutation({
    mutationFn: id =>
      api(
        `mutation retryWorkflowDeploymentTask($id: ID!) {
          retriedTask: retryWorkflowDeploymentTask(id: $id) {
            status
            error
            completedAt
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflowDeploymentExecutionTasks'] });
      qc.invalidateQueries({ queryKey: ['workflowDeploymentExecutionTask', taskId] });
    },
  });

  const retry = () => retryTask.mutate(taskId);

  return (
    <>
      <div className="flex justify-between mb-3">
        <div className="flex items-center">
          <div className="flex">
            <button
              type="button"
              onClick={handleBack}
              className="appearance-none select-none w-6 h-6 inline-flex items-center justify-center mr-2"
            >
              <Icon name="CaretLeft" weight="line" />
            </button>
          </div>
          <div className="flex items-center justify-between space-x-4">
            <DeviceImage model={device?.model} size="md" hideStatus />
            <div className="flex items-center flex-1">{device?.name || '(Deleted device)'}</div>
            {!!device && task?.status === 'complete' && skillStatusEnabled && (
              <SyncProgressDeviceSkillStatus taskId={task.id} />
            )}
          </div>
        </div>
        <div className="flex items-center justify-start space-x-3 px-4">
          {isComplete(task?.status) && !!device && (
            <Button
              disabled={!canDeploy}
              icon="ArrowsClockwise"
              size="xs"
              variant="secondary"
              onClick={retry}
            >
              Retry
            </Button>
          )}
          <div className="flex items-center flex-1">
            <StatusIndicator status={STATUS_MAP[task?.status]} size="md" className="mr-2" />
            <Text size="xs" color="muted" variant="label">
              {STATUS_LABEL_MAP[task?.status]}
              {isComplete(task?.status) && task?.completedAt && (
                <span>&nbsp; ({date.format(task?.completedAt, 'M/d/yy H:mm')})</span>
              )}
            </Text>
          </div>
        </div>
      </div>
      <LogsScroller
        logs={flatLogs}
        loading={deploymentTaskLogs.isLoading}
        hasMore={deploymentTaskLogs.hasNextPage}
        loadingMore={deploymentTaskLogs.isFetchingNextPage}
        onLoadMore={deploymentTaskLogs.fetchNextPage}
      />
    </>
  );
}
