import findDeep from 'deepdash/findDeep';

import { Card, Button, Heading } from '@optra/kit';

import FillParent from 'components/fill-parent';
import { useSearchParams } from 'hooks';
import Forward from 'router/forward';
import RestoreScroll from 'router/restore-scroll';

export default function AuthorizeActionPage() {
  const [authorization] = useSearchParams();
  const sideEffectUrl = findDeep(authorization, (v, k) => k === 'sideEffectUrl');

  if (sideEffectUrl)
    return (
      <Forward
        to={sideEffectUrl.value.replace(window.location.origin, '')}
        omit={sideEffectUrl.context.path}
      />
    );
  return (
    <>
      <RestoreScroll />
      <FillParent>
        <Card className="text-center" size="xl">
          <Heading variant="centered" icon="Warning">
            Error Logging In
          </Heading>

          <div className="my-4 space-y-2">
            <p>The action was unable to authenticate!</p>
            <p>Error: no sideEffectUrl parameter was returned.</p>
          </div>

          <Button to="/workflows">Back to Workflows</Button>
        </Card>
      </FillParent>
    </>
  );
}
