import * as date from 'date-fns';
import { get, truncate } from 'lodash';
import { Link } from 'react-router-dom';

import { Badge, Icon, Text, StatusIndicator } from '@optra/kit';

import FirmwareVersionLabel from 'components/firmware-version-label';
import SkillIcon from 'components/skill-icon';
import StatusLabel from 'components/status-label';
import { statLabel, statStatus, headphoneLabel, headphoneStatus } from 'lib/device-stats';

const columnComponents = {
  name: function NameColumn({ device }) {
    return <Text size="sm">{device?.name}</Text>;
  },
  tags: function TagsColumn({ device }) {
    return (
      <div className="flex flex-wrap">
        {device?.tags?.data?.map(tag => (
          <Badge size="xs" variant="primary" key={tag?.tag?.id} className="m-1">
            {tag?.tag?.name}
          </Badge>
        ))}
      </div>
    );
  },
  firmwareVersion: function FirmwareVersionColumn({ device }) {
    return (
      <FirmwareVersionLabel
        to={`/devices/${device?.id}/overview/firmware`}
        updateAvailable={device?.firmware?.updateAvailable}
        updating={device?.firmware?.isUpdating}
        version={device?.firmware?.version}
      />
    );
  },
  workflows: function WorkflowsColumn({ device }) {
    return (
      <div className="flex flex-wrap w-60">
        {device?.workflows?.data?.map(workflow => (
          <Link
            to={`/workflows?id=${workflow.id}`}
            key={workflow.id}
            className="flex items-center m-1 cursor-pointer hover:opacity-50"
            onClick={event => event.stopPropagation()}
          >
            <Icon name="GitBranch" color="gradient" size="xs" className="mr-1" />
            <Text size="xs" color="muted">
              {workflow?.name}
            </Text>
          </Link>
        ))}
      </div>
    );
  },
  skills: function SkillsColumn({ device }) {
    return (
      <div className="flex flex-wrap w-60">
        {device?.skills?.data?.map(skill => (
          <div key={skill.id} className="flex items-center m-1">
            <SkillIcon
              icon={skill?.skill?.icon}
              iconUrl={skill?.skill?.iconUrl}
              color={skill?.skill?.color}
              size="xs"
              className="mr-1"
            />
            <Text size="xs" color="muted">
              {skill.name
                ? `${truncate(skill.name, { length: 24 })} (${skill.version?.version})`
                : `${truncate(skill.skill?.name, { length: 24 })} (${skill.version?.version})`}
            </Text>
          </div>
        ))}
      </div>
    );
  },
  location: function LocationColumn({ device }) {
    return (
      <div>
        <Text size="xs" className="block">
          {device?.location?.name}
        </Text>
        <Text size="xs" color="muted" className="block">
          {device?.location?.address?.address}
        </Text>
        <Text size="xs" color="muted" className="block">
          {device?.location?.address?.locality}
        </Text>
      </div>
    );
  },
  downtime: function DowntimeColumn({ device }) {
    if (device.downtime?.down) {
      return (
        <Text size="xs" color="muted">
          {date.formatDistanceToNow(new Date(device.downtime?.downAt))}
        </Text>
      );
    }
    return (
      <Text size="xs" color="muted">
        -
      </Text>
    );
  },
  connectionStatus: function ConnectionStatusColumn({ device }) {
    return (
      <div className="flex items-center">
        <StatusIndicator size="sm" status={device.status} className="mr-2" />
        <StatusLabel status={device.status} />
      </div>
    );
  },
  diskStatus: function DiskStatusColumn({ device }) {
    return (
      <div className="flex items-center">
        <StatusIndicator size="sm" status={statStatus(device?.disk?.usage)} className="mr-2" />
        <Text size="xs" color="muted">
          {statLabel(device?.disk?.usage)}
        </Text>
      </div>
    );
  },
  memoryStatus: function MemoryStatusColumn({ device }) {
    return (
      <div className="flex items-center">
        <StatusIndicator size="sm" status={statStatus(device?.ram?.usage)} className="mr-2" />
        <Text size="xs" color="muted">
          {statLabel(device?.ram?.usage)}
        </Text>
      </div>
    );
  },
  cpuStatus: function CPUStatusColumn({ device }) {
    return (
      <div className="flex items-center">
        <StatusIndicator size="sm" status={statStatus(device?.cpu?.usage)} className="mr-2" />
        <Text size="xs" color="muted">
          {statLabel(device?.cpu?.usage)}
        </Text>
      </div>
    );
  },
  ip: function IPColumn({ device }) {
    return (
      <div className="flex items-center">
        <Text size="xs" color="muted">
          {device?.ip || '--'}
        </Text>
      </div>
    );
  },
  mac: function MACColumn({ device }) {
    return (
      <div className="flex items-center">
        <Text size="xs" color="muted">
          {device?.mac || '--'}
        </Text>
      </div>
    );
  },
  headphoneJack: function HeadphoneJackColumn({ device }) {
    return (
      <div className="flex items-center">
        <StatusIndicator
          size="sm"
          status={headphoneStatus(device?.headphoneJack)}
          className="mr-2"
        />
        <Text size="xs" color="muted">
          {headphoneLabel(device?.headphoneJack)}
        </Text>
      </div>
    );
  },
  internalTemperature: function InternalTemperatureColumn({ device }) {
    return (
      <div className="flex items-center">
        <StatusIndicator
          size="sm"
          status={statStatus(device?.internalTemperatureUsage)}
          className="mr-2"
        />
        <Text size="xs" color="muted">
          {statLabel(device?.internalTemperatureUsage)}
        </Text>
      </div>
    );
  },
  serialNumber: function SerialNumberColumn({ device }) {
    return (
      <Text size="xs" color="muted" className="uppercase font-mono">
        {device?.serialNumber}
      </Text>
    );
  },
  warrantyStartedAt: function WarrantyStartedAtColumn({ device }) {
    return (
      <Text size="xs" color="muted">
        {device?.provisionedDevice?.warrantyStartedAt
          ? date.format(new Date(device?.provisionedDevice?.warrantyStartedAt), 'M/d/yyyy')
          : '-'}
      </Text>
    );
  },
  default: function DefaultColumn({ device, column }) {
    return (
      <Text size="xs" color="muted">
        {get(device, `${column.key}.value`, 'No Value') ?? 'No Value'}
      </Text>
    );
  },
};

export default function DeviceListItemColumn(props) {
  const { column, device } = props;
  const ColumnComponent = columnComponents[column.id] || columnComponents.default;

  return <ColumnComponent device={device} column={column} />;
}
