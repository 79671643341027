import { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Icon, Text, Heading, UnreadDot } from '@optra/kit';

import FlexCenter from 'components/flex-center';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Textarea from 'components/textarea';
import { api, q } from 'config/api';
import { useHasRoles, useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useDevice, useCurrentUser } from 'queries';

export default function DeviceFirmware() {
  const { deviceId } = useParams();
  const [showUpdate, setShowUpdate] = useState(false);
  const { handleBack, showBackButton } = useModalContext();
  const [currentUser] = useCurrentUser();
  const [canUpdateFirmware] = useHasRoles([
    'admin',
    'deviceEnroller',
    'deviceTechnician',
    'workflowEditor',
    'workflowDeployer',
  ]);

  const {
    data,
    isLoading: fetching,
    error: fetchError,
  } = useDevice(
    deviceId,
    `
      id
      firmware {
        version
        releaseNotes
        updateAvailable
        isUpdating
        latestAvailableFirmware {
          version
          releaseNotes
        }
      }
    `,
    { refetchInterval: 20000 },
  );

  const qc = q.useQueryClient();
  const updateFirmware = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateDeviceFirmware($form: updateDeviceFirmwareForm!) {
          device: updateDeviceFirmware(form: $form) {
            firmware {
              isUpdating
            }
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
      qc.invalidateQueries({ queryKey: ['device', deviceId] });
    },
  });

  const successfulUpdate =
    updateFirmware.data?.device?.firmware?.isUpdating || data?.device.firmware.isUpdating;

  const handleUpdateFirmware = () => {
    if (successfulUpdate || updateFirmware.isPending || !data?.device?.firmware?.updateAvailable)
      return;
    updateFirmware.mutate({ deviceId });
  };

  const currentVersion = {
    version: data?.device?.firmware?.version,
    releaseNotes: data?.device?.firmware?.releaseNotes,
  };

  const newVersion = {
    version: data?.device?.firmware?.latestAvailableFirmware?.version,
    releaseNotes: data?.device?.firmware?.latestAvailableFirmware?.releaseNotes,
  };

  const itemNotFound = useItemNotFound({
    fetching,
    id: data?.device?.id,
  });

  if (itemNotFound) {
    return <ItemNotFound id={deviceId} type="Device" />;
  }

  return (
    <ModalInner>
      <ModalTitle
        title={showUpdate ? 'Update Firmware' : 'Firmware Version'}
        icon="Cpu"
        handleBack={() => {
          if (showUpdate) {
            setShowUpdate(!showUpdate);
          } else {
            handleBack();
          }
        }}
        showBackButton={showBackButton || showUpdate}
        loading={fetching}
        renderActions={() => {
          if (!currentUser?.isSysAdmin) return null;
          return (
            <Button
              icon="Wrench"
              size="xs"
              variant="secondary"
              to="override"
              state={{ fromModal: true }}
            >
              Override
            </Button>
          );
        }}
      />
      <ModalBody>
        {fetchError && (
          <Message variant="danger" title="Error Loading Firmware">
            {fetchError.message}
          </Message>
        )}

        {showUpdate && (
          <>
            <div className="mb-4 space-y-2">
              <Heading level={3}>v{newVersion?.version} Release Notes</Heading>
              <Textarea
                rows={10}
                className="text-sm"
                readOnly
                value={newVersion?.releaseNotes || 'No release notes.'}
              />
            </div>

            {successfulUpdate && (
              <Text size="sm" className="block text-center mb-3">
                Your new firmware is being installed. This may take several minutes. You may now
                close this window.
              </Text>
            )}

            <FlexCenter>
              <Button
                onClick={handleUpdateFirmware}
                loading={successfulUpdate || updateFirmware.isPending}
              >
                {`Update to ${newVersion?.version}`}
              </Button>
            </FlexCenter>
          </>
        )}

        {!showUpdate && (
          <>
            <div className="mb-4 space-y-2">
              <Heading level={3}>v{currentVersion?.version} Release Notes</Heading>
              <Textarea
                rows={10}
                className="text-sm"
                readOnly
                value={currentVersion?.releaseNotes || 'No release notes.'}
              />
            </div>

            {data?.device?.firmware?.updateAvailable && (
              <List>
                <ListItem
                  renderLeft={() => <Icon name="Lightning" color="gradient" />}
                  renderRight={() => canUpdateFirmware && <Icon name="CaretRight" weight="line" />}
                  onClick={() => canUpdateFirmware && setShowUpdate(true)}
                >
                  <div className="flex items-center">
                    <Text>{`Version ${newVersion?.version} Available`}</Text>
                    <UnreadDot className="relative ml-3" />
                  </div>
                </ListItem>
              </List>
            )}
          </>
        )}
      </ModalBody>
    </ModalInner>
  );
}
