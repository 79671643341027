import { isEmpty } from 'lodash';

import { api, q } from 'config/api';

export default function useDevices(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['devices', variables],
    queryFn({ pageParam }) {
      return api(
        `query devices($list: listFields) {
          list: devices(list: $list) {
            ${isEmpty(pageParam) ? 'count' : ''}
            data {
              id
              createdAt
              name
              model
              status
              autoClaim
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    select: data => ({
      count: data.pages[0]?.list?.count ?? 0,
      list: data.pages.flatMap(page => page.list.data),
    }),
    // refetchInterval: 60000,
    // select: data => {
    //   return {
    //     ...data,
    //     pages: data.pages.map(page => ({
    //       ...page,
    //       list: {
    //         ...page.list,
    //         data: page.list.data.map(datum => ({
    //           ...datum,
    //           outputs: keyBy(datum.outputs, 'key'),
    //         })),
    //       },
    //     })),
    //   };
    // },
    ...overrides,
  });
}
