import { isEmpty } from 'lodash';

import { api, q } from 'config/api';

export default function useChartPreview(query, overrides) {
  return q.useQuery({
    queryKey: ['chartPreview', query],
    queryFn: () => {
      if (isEmpty(query)) return null;
      return api(
        `query chartPreview($query: String!) {
          chartPreview: chartQuery(query: $query) {
            queryResult
            nextToken
          }
        }`,
        { query },
      );
    },
    enabled: !isEmpty(query),
    refetchOnWindowFocus: false,
    ...overrides,
  });
}
