import { Route, Routes } from 'react-router-dom';

import CreateLocation from 'components/create-location';
import EditDeviceLocations from 'components/edit-device-locations';
import EditLocation from 'components/edit-location';
import ModalInner from 'components/modal-inner';
import ManageLocationsOverview from 'modals/manage-locations-overview';
import RouteTitle from 'router/RouteTitle';

export default function ManageLocations() {
  return (
    <ModalInner>
      <Routes>
        <Route
          index
          element={
            <RouteTitle title="Manage Locations">
              <ManageLocationsOverview />
            </RouteTitle>
          }
        />
        <Route
          path="new"
          element={
            <RouteTitle title="New Location">
              <CreateLocation />
            </RouteTitle>
          }
        />
        <Route path=":locationId/*">
          <Route path="edit/*">
            <Route
              index
              element={
                <RouteTitle title="Edit Location">
                  <EditLocation />
                </RouteTitle>
              }
            />
            <Route
              path="devices"
              element={
                <RouteTitle title="Edit Device Location">
                  <EditDeviceLocations />
                </RouteTitle>
              }
            />
          </Route>
        </Route>
      </Routes>
    </ModalInner>
  );
}
