import { last, isEmpty } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useParams,
  Route,
  Routes,
  Navigate,
  Outlet,
  Link,
  useSearchParams,
} from 'react-router-dom';

import { Card, Button, Icon, Text, Heading } from '@optra/kit';

import Checkbox from 'components/checkbox';
import Label from 'components/label';
import Message from 'components/message';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillIcon from 'components/skill-icon';
import Tab from 'components/tab';
import Tabs from 'components/tabs';
import { api, q, useOnSuccess } from 'config/api';
import isFile from 'lib/is-file';
import useParseFileUrl from 'lib/use-parse-file-url';
import AdminMarketplaceEditOrganizationsAccessList from 'modals/admin-marketplace-edit-organizations-access-list';
import AdminMarketplaceEditUsersAccessList from 'modals/admin-marketplace-edit-users-access-list';
import MarketplaceDetailGallery from 'modals/marketplace-detail-gallery';
import PublishSkillCompatibility from 'modals/publish-skill-compatibility';
import PublishSkillDescription from 'modals/publish-skill-description';
import PublishSkillIcon from 'modals/publish-skill-icon';
import PublishSkillImages from 'modals/publish-skill-images';
import PublishSkillPreview from 'modals/publish-skill-preview';
import PublishSkillReview from 'modals/publish-skill-review';
import PublishSkillVersion from 'modals/publish-skill-version';
import { usePublishSkill, useSignedUpload } from 'queries';
import RouteTitle from 'router/RouteTitle';

const isPublishedVersion = v =>
  ['changesRequested', 'reviewing', 'published'].includes(v.publicationState);

export default function AdminMarketplaceEditListing() {
  const { skillId } = useParams();
  const [searchParams] = useSearchParams();
  const searchParamsTab = searchParams.get('tab');
  const [currentTab, setCurrentTab] = useState(searchParamsTab || 'version');

  const Form = useForm();
  const skillQuery = usePublishSkill(skillId);
  const { data: skillData, isLoading, isSuccess, error } = skillQuery;

  useOnSuccess(
    () => {
      const { skill, marketplaceSkill } = skillData;

      const validVersions = skill?.versions?.data?.filter(isPublishedVersion);

      let latestSkillVersion = validVersions.find(v =>
        ['changesRequested', 'reviewing'].includes(v.publicationState),
      );

      if (!latestSkillVersion) {
        latestSkillVersion = validVersions[0];
      }

      const msv = marketplaceSkill?.versions?.data?.find?.(
        v => v.originSkillVersionId === latestSkillVersion.id,
      );

      Form.reset({
        versionId: latestSkillVersion?.id,
        name: msv?.name || skill?.name,
        icon: {
          icon: msv?.icon || skill?.icon || 'action',
          color: msv?.color || skill?.color || '#00C425',
          iconUrl: msv?.iconUrl || skill?.iconUrl || null,
        },
        creditsCost: msv?.creditsCost ?? 0,
        summary: msv?.summary || '',
        description: msv?.description || '',
        releaseNotes: msv?.releaseNotes || '',
        compatibleFirmwareVersions: msv?.compatibleFirmwareVersions || '',
        compatibleDeviceModels: {
          vz1000: msv?.compatibleDeviceModels?.includes?.('vz1000') || false,
          vz5000: msv?.compatibleDeviceModels?.includes?.('vz5000') || false,
          compute550: msv?.compatibleDeviceModels?.includes?.('compute550') || false,
          cx1000: msv?.compatibleDeviceModels?.includes?.('cx1000') || false,
          cx2000: msv?.compatibleDeviceModels?.includes?.('cx2000') || false,
        },
        licenseUrl: msv?.licenseUrl || null,
        manualUrl: msv?.manualUrl || null,
        screenshotUrls: [
          msv?.screenshotUrls?.[0] || null,
          msv?.screenshotUrls?.[1] || null,
          msv?.screenshotUrls?.[2] || null,
        ],
        // This does not change across versions
        requiresContact: marketplaceSkill?.requiresContact,
        featured: marketplaceSkill?.featured,
        organizations: {},
      });
    },
    { isSuccess },
    [Form, skillData],
  );

  const formValues = Form.watch();
  const iconUrl = useParseFileUrl(formValues?.icon?.iconUrl);
  const licenseUrl = useParseFileUrl(formValues?.licenseUrl);
  const manualUrl = useParseFileUrl(formValues?.manualUrl);
  const screenshotUrls = [
    useParseFileUrl(formValues?.screenshotUrls?.[0]),
    useParseFileUrl(formValues?.screenshotUrls?.[1]),
    useParseFileUrl(formValues?.screenshotUrls?.[2]),
  ];
  const selectedMarketplaceSkillVersion = skillData?.marketplaceSkill?.versions?.data?.find?.(
    v => v.originSkillVersionId === formValues.versionId,
  );

  const proposedMarketplaceSkill = {
    ...formValues,
    ...formValues.icon,
    iconUrl,
    licenseUrl,
    manualUrl,
    latestVersion: {
      ...selectedMarketplaceSkillVersion,
      releaseNotes: formValues.releaseNotes,
      compatibleDeviceModels: Object.entries(formValues.compatibleDeviceModels || {})
        .filter(([k, v]) => v)
        .map(([k]) => k),
    },
    developerProfile:
      skillData?.marketplaceSkill?.developerProfile || skillData?.currentDeveloperProfile,
    screenshotUrls: screenshotUrls.filter(f => f),
  };

  const [uploadIcon] = useSignedUpload({ type: 'skillImage' });
  const [uploadLicense] = useSignedUpload({ type: 'marketplaceSkillLicense' });
  const [uploadManual] = useSignedUpload({ type: 'skillManual' });
  const [uploadScreenShot1] = useSignedUpload({ type: 'skillImage' });
  const [uploadScreenShot2] = useSignedUpload({ type: 'skillImage' });
  const [uploadScreenShot3] = useSignedUpload({ type: 'skillImage' });
  const [generalError, setGeneralError] = useState();

  const qc = q.useQueryClient();
  const updateMarketplaceSkill = q.useMutation({
    mutationFn: async form => {
      const {
        marketplaceSkillId,
        marketplaceSkillVersionId,
        skillVersionId,
        publicationState,
        ...fields
      } = form;

      if (['failed', 'publishable'].includes(publicationState)) {
        return api(
          `mutation publishMarketplaceSkill($form: publishMarketplaceSkillForm!) {
            marketplaceSkill: publishMarketplaceSkill(form: $form) {
              id
            }
          }`,
          {
            form: {
              ...fields,
              skillVersionId,
            },
          },
        );
      }

      const {
        tags,
        price,
        featured,
        requiresContact,
        allowedOrganizationConsumers,
        allowedUserConsumers,
        ...skillVersionProps
      } = fields;

      return api(
        `mutation updateMarketplaceSkill($skillForm: updateMarketplaceSkillForm!, $versionForm: updateMarketplaceSkillVersionForm!) {
          marketplaceSkill: updateMarketplaceSkill(form: $skillForm) {
            id
          }
          marketplaceSkillVersion: updateMarketplaceSkillVersion(form: $versionForm) {
            id
          }
        }`,
        {
          skillForm: {
            id: marketplaceSkillId,
            tags,
            price,
            featured,
            requiresContact,
            allowedOrganizationConsumers,
            allowedUserConsumers,
          },
          versionForm: {
            id: marketplaceSkillVersionId,
            ...skillVersionProps,
          },
        },
      );
    },
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
      qc.invalidateQueries({ queryKey: ['marketplaceSkills'] });
    },
    onError(err) {
      setGeneralError(err.message);
    },
  });

  const handleSubmit = Form.handleSubmit(async form => {
    setGeneralError(null);
    const formValid = await Form.trigger();
    if (!formValid) throw new Error('Form invalid!');

    // Upload set images in parallel
    const [icon, license, manual, screenshot1, screenshot2, screenshot3] = await Promise.all([
      isFile(form.icon?.iconUrl)
        ? uploadIcon(form.icon.iconUrl, {
            extension: last(form.icon.iconUrl.name.split('.')),
            name: skillId,
          })
        : { url: form.icon?.iconUrl },
      isFile(form.licenseUrl)
        ? uploadLicense(form.licenseUrl, {
            extension: last(form.licenseUrl.name.split('.')),
            name: form.licenseUrl.name.split('.').slice(0, -1).join('.'),
          })
        : { url: form.licenseUrl },
      isFile(form.manualUrl)
        ? uploadManual(form.manualUrl, {
            extension: last(form.manualUrl.name.split('.')),
            name: form.manualUrl.name.split('.').slice(0, -1).join('.'),
          })
        : { url: form.manualUrl },
      isFile(form.screenshotUrls?.[0])
        ? uploadScreenShot1(form.screenshotUrls[0], {
            extension: last(form.screenshotUrls[0].name.split('.')),
            name: `${skillId}-0`,
          })
        : { url: form.screenshotUrls?.[0] },
      isFile(form.screenshotUrls?.[1])
        ? uploadScreenShot2(form.screenshotUrls[1], {
            extension: last(form.screenshotUrls[1].name.split('.')),
            name: `${skillId}-1`,
          })
        : { url: form.screenshotUrls?.[1] },
      isFile(form.screenshotUrls?.[2])
        ? uploadScreenShot3(form.screenshotUrls[2], {
            extension: last(form.screenshotUrls[2].name.split('.')),
            name: `${skillId}-2`,
          })
        : { url: form.screenshotUrls?.[2] },
    ]);

    const accessModifiedOrgIds = Object.keys(Form.formState?.touchedFields?.organizations || {});
    const allowedOrganizationConsumers = {};
    Object.entries(form?.organizations || {})
      .filter(([k, v]) => accessModifiedOrgIds.includes(k))
      .forEach(([k, v]) => (allowedOrganizationConsumers[k] = v));

    const accessModifiedUserIds = Object.keys(Form.formState?.touchedFields?.users || {});
    const allowedUserConsumers = {};
    Object.entries(form?.users || {})
      .filter(([k, v]) => accessModifiedUserIds.includes(k))
      .forEach(([k, v]) => (allowedUserConsumers[atob(k)] = v));

    const compatibleDeviceModels = Object.entries(form.compatibleDeviceModels)
      .filter(([k, v]) => v)
      .map(([k]) => k);

    const preparedForm = {
      name: form.name,
      summary: form.summary,
      description: form.description,
      color: form.icon?.color,
      icon: form.icon?.icon,
      iconUrl: icon?.url,
      screenshotUrls: [screenshot1.url, screenshot2.url, screenshot3.url],
      licenseUrl: license.url,
      manualUrl: manual.url,
      releaseNotes: form.releaseNotes,
      compatibleFirmwareVersions: form.compatibleFirmwareVersions,
      compatibleDeviceModels,
      creditsCost: Number(form.creditsCost ?? 0),
      // SUPER USER
      featured: form.featured,
      requiresContact: form.requiresContact,
      allowedOrganizationConsumers,
      allowedUserConsumers,
      // tags,
      // price,
    };

    return updateMarketplaceSkill.mutateAsync({
      // shared
      ...preparedForm,
      publicationState: selectedMarketplaceSkillVersion.publicationState, // For processing, this will not write
      // for publish
      skillVersionId: form.versionId,
      // for update
      marketplaceSkillId: skillData.marketplaceSkill.id,
      marketplaceSkillVersionId: selectedMarketplaceSkillVersion.id,
    });
  });
  const outletContext = [Form, { ...skillData, isLoading }, proposedMarketplaceSkill];
  const submitBtnText = {
    publishable: 'Publish',
    failed: 'Retry Publish',
  };

  const deprecateMarketplaceSkill = q.useMutation({
    mutationFn: async form => {
      const { id, deprecated } = form;

      return api(
        `mutation deprecateMarketplaceSkillVersion($form: deprecateMarketplaceSkillVersionForm!) {
          marketplaceSkillVersion: deprecateMarketplaceSkillVersion(form: $form) {
            id
          }
        }`,
        {
          form: {
            id,
            deprecated,
          },
        },
      );
    },
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
      qc.invalidateQueries({ queryKey: ['marketplaceSkills'] });
    },
    onError(err) {
      setGeneralError(err.message);
    },
  });

  return (
    <Modal>
      <Routes>
        <Route
          element={
            <ModalInner as="form" onSubmit={handleSubmit}>
              <ModalTitle
                title="Marketplace Listing"
                icon="CloudArrowUp"
                loading={isLoading}
                renderActions={() => (
                  <Button size="xs" variant="secondary" icon="Eye" to="preview">
                    Preview
                  </Button>
                )}
              />
              <ModalBody>
                {error && (
                  <Message variant="danger" title="Couldn't Load Skill">
                    {error.message}
                  </Message>
                )}
                {!isEmpty(generalError) && (
                  <Message variant="danger" title="Failed">
                    {generalError}
                  </Message>
                )}

                {(skillData?.marketplaceSkill?.reviewing ||
                  skillData?.marketplaceSkill?.changesRequested) && (
                  <div className="bg-blue rounded-md p-4 my-2 flex items-center justify-between gap-3">
                    <Icon name="Info" className="text-blue-50 h-5 w-5" />
                    <h3 className="flex-1 text-blue-50 text-sm font-medium uppercase">
                      {skillData?.marketplaceSkill?.reviewing ? 'Pending Review' : 'Reviewed'}
                    </h3>
                    <Button
                      size="xs"
                      variant="secondary"
                      to={`versions/${selectedMarketplaceSkillVersion?.id}/review`}
                    >
                      {skillData?.marketplaceSkill?.reviewing ? 'Add Review' : 'Update Review'}
                    </Button>
                  </div>
                )}

                <div className="space-y-4">
                  <Card
                    variant="secondary"
                    noPadding
                    className="divide-y divide-light-fg-tertiary dark:divide-dark-fg-tertiary"
                  >
                    <div className="flex flex-col items-center justify-center py-5">
                      <SkillIcon
                        size="lg"
                        color={proposedMarketplaceSkill?.color}
                        icon={proposedMarketplaceSkill?.icon}
                        iconUrl={proposedMarketplaceSkill?.iconUrl}
                        className="mb-2"
                        fetching={isLoading}
                      />
                      <div className="text-center">
                        <Heading>{proposedMarketplaceSkill?.name || 'Loading…'}</Heading>
                      </div>
                      {proposedMarketplaceSkill?.developerProfile?.name && (
                        <div className="text-center">
                          <Text size="sm" color="muted">
                            by {proposedMarketplaceSkill?.developerProfile?.name}
                          </Text>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-1 items-center justify-between gap-3 px-4 py-5 relative">
                      <Icon name="PencilSimple" color="primary" />
                      <Text className="flex-1">Change Name or Icon</Text>
                      <Icon name="CaretRight" weight="line" />
                      <Link to="icon" className="absolute inset-0" />
                    </div>
                  </Card>

                  <Card variant="secondary" className="space-y-4">
                    <div className="flex flex-row items-center">
                      <Label className="flex-1">
                        <Checkbox
                          {...Form.register('requiresContact')}
                          className="mr-2"
                          readOnly={Form.isSubmitting}
                        />
                        Requires Contact
                      </Label>
                      <Button
                        size="xs"
                        variant="secondary"
                        icon="Cards"
                        disabled={!formValues.requiresContact}
                        to={formValues.requiresContact ? 'access-list/organizations' : null}
                      >
                        Access List
                      </Button>
                    </div>
                    <div className="space-y-2">
                      <Label className="flex-1">
                        <Checkbox
                          {...Form.register('featured')}
                          className="mr-2"
                          readOnly={Form.isSubmitting}
                        />
                        Featured
                      </Label>
                    </div>
                  </Card>

                  <Tabs>
                    <Tab
                      onClick={() => {
                        setCurrentTab('version');
                      }}
                      active={currentTab === 'version'}
                      className="justify-center"
                    >
                      Version
                    </Tab>
                    <Tab
                      onClick={() => setCurrentTab('description')}
                      active={currentTab === 'description'}
                      className="justify-center"
                    >
                      Description
                    </Tab>
                    <Tab
                      onClick={() => setCurrentTab('compatibility')}
                      active={currentTab === 'compatibility'}
                      className="justify-center"
                    >
                      Compatibility
                    </Tab>
                    <Tab
                      onClick={() => setCurrentTab('images')}
                      active={currentTab === 'images'}
                      className="justify-center"
                    >
                      Images
                    </Tab>
                  </Tabs>

                  <Outlet context={outletContext} />

                  {currentTab === 'version' && (
                    <PublishSkillVersion
                      form={Form}
                      isLoading={isLoading || deprecateMarketplaceSkill.isPending}
                      versions={skillData?.skill?.versions?.data?.filter(isPublishedVersion) || []}
                      marketplaceVersions={skillData?.marketplaceSkill?.versions?.data || []}
                      setError={setGeneralError}
                      onChangeVersion={e => {
                        const nextSelectedVersion = e.currentTarget.value;
                        const msv = skillData?.marketplaceSkill?.versions?.data?.find?.(
                          v => v.originSkillVersionId === nextSelectedVersion,
                        );

                        // NOTE: Same as defaults
                        Form.reset({
                          versionId: nextSelectedVersion,
                          name: msv?.name || skillData?.skill?.name,
                          icon: {
                            icon: msv?.icon || skillData?.skill?.icon || 'action',
                            color: msv?.color || skillData?.skill?.color || '#00C425',
                            iconUrl: msv?.iconUrl || skillData?.skill?.iconUrl || null,
                          },
                          creditsCost: msv?.creditsCost ?? 0,
                          summary: msv?.summary || '',
                          description: msv?.description || '',
                          releaseNotes: msv?.releaseNotes || '',
                          compatibleFirmwareVersions: msv?.compatibleFirmwareVersions || '',
                          compatibleDeviceModels: {
                            vz1000: msv?.compatibleDeviceModels?.includes?.('vz1000') || false,
                            vz5000: msv?.compatibleDeviceModels?.includes?.('vz5000') || false,
                            compute550:
                              msv?.compatibleDeviceModels?.includes?.('compute550') || false,
                            cx1000: msv?.compatibleDeviceModels?.includes?.('cx1000') || false,
                            cx2000: msv?.compatibleDeviceModels?.includes?.('cx2000') || false,
                          },
                          licenseUrl: msv?.licenseUrl || null,
                          manualUrl: msv?.manualUrl || null,
                          screenshotUrls: [
                            msv?.screenshotUrls?.[0] || null,
                            msv?.screenshotUrls?.[1] || null,
                            msv?.screenshotUrls?.[2] || null,
                          ],
                          deprecated: msv?.deprecated,
                        });
                      }}
                    />
                  )}
                  {currentTab === 'description' && (
                    <PublishSkillDescription form={Form} skill={skillQuery} />
                  )}
                  {currentTab === 'compatibility' && (
                    <PublishSkillCompatibility form={Form} skill={skillQuery} />
                  )}
                  {currentTab === 'images' && <PublishSkillImages form={Form} />}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button size="xl" type="submit" loading={Form.formState.isSubmitting}>
                  {submitBtnText[selectedMarketplaceSkillVersion?.publicationState] || 'Modify'}
                </Button>
              </ModalFooter>
            </ModalInner>
          }
        >
          <Route index element={null} />
          <Route
            element={
              <Modal closePath=".">
                <Outlet context={outletContext} />
              </Modal>
            }
          >
            <Route
              path="preview/*"
              element={
                <RouteTitle title="Skill Preview">
                  <PublishSkillPreview hideSubmitBtn />
                  <Outlet />
                </RouteTitle>
              }
            >
              <Route index element={null} />
              <Route
                path="gallery"
                element={
                  <RouteTitle title="Skill Preview Gallery">
                    <MarketplaceDetailGallery marketplaceSkill={proposedMarketplaceSkill} />
                  </RouteTitle>
                }
              />
            </Route>
            <Route
              path="icon"
              element={
                <RouteTitle title="Change Name or Icon">
                  <PublishSkillIcon />
                </RouteTitle>
              }
            />
            <Route
              path="versions/:marketplaceSkillVersionId/review"
              element={
                <RouteTitle title="Skill Review">
                  <PublishSkillReview />
                </RouteTitle>
              }
            />
            <Route path="access-list" element={<Navigate to="organizations" replace />} />
            <Route
              path="access-list/organizations"
              element={
                <RouteTitle title="Admin Marketplace Listing">
                  <AdminMarketplaceEditOrganizationsAccessList
                    Form={Form}
                    submitting={Form.formState.isSubmitting}
                    subtitle={proposedMarketplaceSkill?.name}
                  />
                </RouteTitle>
              }
            />
            <Route
              path="access-list/users"
              element={
                <RouteTitle title="Admin Marketplace Listing">
                  <AdminMarketplaceEditUsersAccessList
                    Form={Form}
                    submitting={Form.formState.isSubmitting}
                    subtitle={proposedMarketplaceSkill?.name}
                  />
                </RouteTitle>
              }
            />
          </Route>
        </Route>
      </Routes>
    </Modal>
  );
}
