import cx from 'classnames';
import { isNil, toString, isBoolean as _isBoolean } from 'lodash';
import set from 'lodash/set';
import uniqueId from 'lodash/uniqueId';
import { useState, useRef, forwardRef, Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { z, ZodError } from 'zod';

import { ButtonWrap, Card, Button, Icon, IconButton } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import ManageOutputsLink from 'components/manage-outputs-link';
import Select from 'components/select';
import ValidationError from 'components/validation-error';

const StatusMappingEntry = forwardRef((props, ref) => {
  const { focused, onFocus, onBlur, onChange, disabled, defaultValue, type, position } = props;
  const autoUpdateProps =
    position === 1 && type === 'boolean' ? { value: defaultValue, disabled: true } : {};
  return (
    <div className={cx('text-center', '-mt-5', type === 'number' ? 'w-20' : 'w-24')}>
      <Icon
        name="CaretUp"
        className={cx(
          '-mb-4',
          focused ? 'text-primary' : 'dark:text-gray-800 text-gray-200',
          autoUpdateProps.disabled && 'text-opacity-50',
        )}
      />
      {type === 'number' && (
        <Input
          type="text"
          className="bg-gray-200 border-gray-200 dark:bg-gray-800 dark:border-gray-800 focus:border-primary focus:ring-primary text-center"
          variant="none"
          onFocus={onFocus}
          onChange={e =>
            isNaN(parseFloat(e.target.value)) ? onChange(null) : onChange(+e.target.value)
          }
          onBlur={onBlur}
          ref={ref}
          disabled={disabled}
          defaultValue={toString(defaultValue)}
          onKeyPress={e => {
            if (!/[0-9.]/.test(e.key)) {
              e.preventDefault();
            }
          }}
        />
      )}
      {type === 'boolean' && (
        <Select
          onFocus={onFocus}
          onBlur={onBlur}
          variant="none"
          className="bg-gray-200 border-gray-200 dark:bg-gray-800 dark:border-gray-800 focus:border-primary focus:ring-primary text-center rounded-md"
          onChange={e => onChange(e.target.value === 'true')}
          disabled={disabled}
          defaultValue={toString(defaultValue)}
          ref={ref}
          {...autoUpdateProps}
        >
          <option value="" disabled></option>
          <option value="true">True</option>
          <option value="false">False</option>
        </Select>
      )}
    </div>
  );
});

const StatusMappingDirection = forwardRef((props, ref) => {
  const { direction, onClick, disabled } = props;
  const [hovered, setHovered] = useState(false);
  return (
    <>
      <ButtonWrap
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        type="button"
        onClick={onClick}
        disabled={disabled}
      >
        <Icon
          size="lg"
          weight="regular"
          variant="secondary"
          name={direction === 'asc' ? 'ArrowFatLinesRight' : 'ArrowFatLinesLeft'}
          className={cx('align-middle', hovered && 'opacity-60')}
        />
      </ButtonWrap>
      <Input type="text" className="hidden" ref={ref} value={direction} readOnly />
    </>
  );
});

const NumberStatusMapping = forwardRef(({ direction, onDirectionClick, disabled }, ref) => (
  <>
    <div
      className={cx(
        'flex-1',
        'h-3',
        'rounded-full',
        direction === 'desc' ? 'bg-green' : 'bg-red',
        disabled && 'opacity-60',
      )}
    />
    <div className={cx('flex-1', 'h-3', 'bg-yellow', 'rounded-full', disabled && 'opacity-60')} />
    <div
      className={cx(
        'flex-1',
        'h-3',
        'rounded-full',
        direction === 'desc' ? 'bg-red' : 'bg-green',
        disabled && 'opacity-60',
      )}
    />
    <StatusMappingDirection
      direction={direction}
      onClick={onDirectionClick}
      ref={ref}
      disabled={disabled}
    />
  </>
));

function BooleanStatusMapping({ disabled }) {
  return (
    <>
      <div className={cx('flex-1', 'h-3', 'rounded-full', 'bg-red', disabled && 'opacity-60')} />
      <div className={cx('flex-1', 'h-3', 'rounded-full', 'bg-green', disabled && 'opacity-60')} />
    </>
  );
}

const isBoolean = datum => datum.mapping?.type === 'boolean';
const isNumber = datum => datum.mapping?.type === 'number';
const hasStatusMapping = datum => !isNil(datum.mapping?.statusMapping);

const statusMappingSchema = z.object({
  upperThreshold: z.union([z.boolean(), z.number()]),
  lowerThreshold: z.union([z.boolean(), z.number()]),
  direction: z.enum(['asc', 'desc']).nullish(),
});

export default function SkillOutputFields(props) {
  const { visible, control, loading, errors } = props;
  const [focusedStatus, setFocusedStatus] = useState('');
  const statusMappedInputs = useRef({});

  return (
    <div className={visible ? 'block' : 'hidden'}>
      <Controller
        render={({ field }) => {
          const data =
            field?.value?.length > 0
              ? field.value
              : [
                  {
                    _id: uniqueId(),
                    key: '',
                    value: '',
                    mapping: {
                      type: 'string',
                    },
                    unhealthyTimeoutMS: 1000 * 60 * 60,
                  },
                ];

          const name = field?.name;
          const toggleStatusMapping = (datum, idx) => {
            if (!hasStatusMapping(datum)) {
              let upperThreshold = null;
              if (statusMappedInputs.current[`${idx}.1`]?.value) {
                upperThreshold = isNumber(datum)
                  ? +statusMappedInputs.current[`${idx}.1`].value
                  : statusMappedInputs.current[`${idx}.1`].value === 'true';
              }
              let lowerThreshold = null;
              if (statusMappedInputs.current[`${idx}.0`]?.value) {
                lowerThreshold = isNumber(datum)
                  ? +statusMappedInputs.current[`${idx}.0`].value
                  : statusMappedInputs.current[`${idx}.0`].value === 'true';
              }
              field?.onChange(
                set([...data], `[${idx}].mapping.statusMapping`, {
                  upperThreshold,
                  lowerThreshold,
                  direction:
                    statusMappedInputs.current[`${idx}.dir`]?.value ||
                    (isNumber(datum) ? 'asc' : null),
                }),
              );
            } else {
              field?.onChange(set([...data], `[${idx}].mapping.statusMapping`, undefined));
            }
          };

          const toggleStatusMappingDirection = (datum, idx) => {
            datum.mapping?.statusMapping?.direction === 'asc'
              ? field?.onChange(set([...data], `[${idx}].mapping.statusMapping.direction`, 'desc'))
              : field?.onChange(set([...data], `[${idx}].mapping.statusMapping.direction`, 'asc'));
          };

          return (
            <div className="space-y-3">
              <ManageOutputsLink />
              {errors.outputs && <ValidationError message={errors.outputs.message} />}
              {data?.map?.((datum, idx) => (
                <Fragment key={datum._id}>
                  <Card variant="secondary" className="space-y-2">
                    <div className="flex justify-end">
                      <IconButton
                        variant="secondary"
                        name="X"
                        disabled={data?.length === 1}
                        onClick={() => {
                          if (field?.disabled) return;
                          if (data?.length > 1) {
                            field?.onChange(data.filter((o, k) => k !== idx));
                          }
                        }}
                      />
                    </div>
                    <div className="flex-1 grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label htmlFor={`${name}[${idx}].key`}>Output Key</Label>
                        <Input
                          type="text"
                          name={`${name}[${idx}].key`}
                          disabled={loading || field?.disabled}
                          defaultValue={datum.key}
                          onChange={e => {
                            field?.onChange(set([...data], `[${idx}].key`, e.target.value));
                          }}
                        />
                      </div>

                      <div className="space-y-2">
                        <Label htmlFor={`${name}[${idx}].value`}>Output Label</Label>
                        <Input
                          type="text"
                          name={`${name}[${idx}].value`}
                          disabled={loading || field?.disabled}
                          defaultValue={datum.value}
                          onChange={e => {
                            field?.onChange(set([...data], `[${idx}].value`, e.target.value));
                          }}
                        />
                      </div>

                      <div className="space-y-2">
                        <Label htmlFor={`${name}[${idx}].unhealthyTimeoutMS`}>
                          Unhealthy After (ms)
                        </Label>
                        <Input
                          type="number"
                          name={`${name}[${idx}].unhealthyTimeoutMS`}
                          disabled={loading || field?.disabled}
                          defaultValue={datum.unhealthyTimeoutMS}
                          onChange={e => {
                            field?.onChange(
                              set([...data], `[${idx}].unhealthyTimeoutMS`, e.target.value),
                            );
                          }}
                        />
                      </div>

                      <div className={cx('space-y-2')}>
                        <Label htmlFor={`${name}[${idx}].key`}>Data Type</Label>
                        <Select
                          name={`${name}[${idx}].type`}
                          disabled={loading || field?.disabled}
                          defaultValue={datum.mapping?.type || ''}
                          onChange={e => {
                            let updatedData = set(
                              [...data],
                              `[${idx}].mapping.type`,
                              e.target.value,
                            );
                            updatedData = set(
                              [...updatedData],
                              `[${idx}].mapping.statusMapping`,
                              null,
                            );
                            field?.onChange(updatedData);
                          }}
                        >
                          <option value="" disabled>
                            Select Data Type...
                          </option>
                          <option value="string">String</option>
                          <option value="number">Number</option>
                          <option value="boolean">Boolean</option>
                        </Select>
                      </div>

                      <div className={cx('space-y-2 col-span-2 flex justify-end')}>
                        <Button
                          variant="secondary"
                          size="xs"
                          onClick={() => {
                            toggleStatusMapping(datum, idx);
                          }}
                          disabled={
                            !['boolean', 'number'].includes(datum.mapping?.type) ||
                            loading ||
                            field?.disabled
                          }
                        >
                          {hasStatusMapping(datum) ? 'Disable Status' : 'Enable Status'}
                        </Button>
                      </div>
                      <div
                        className={cx(
                          'col-span-2',
                          'space-y-2',
                          !hasStatusMapping(datum) && 'hidden',
                        )}
                      >
                        <div
                          className={cx(
                            'flex',
                            'items-center',
                            isNumber(datum) ? '-mb-4' : '-mb-2',
                          )}
                        >
                          {isNumber(datum) && (
                            <NumberStatusMapping
                              direction={datum.mapping?.statusMapping?.direction}
                              onDirectionClick={() => toggleStatusMappingDirection(datum, idx)}
                              ref={el => (statusMappedInputs.current[`${idx}.dir`] = el)}
                              disabled={loading || field?.disabled}
                            />
                          )}
                          {isBoolean(datum) && (
                            <BooleanStatusMapping disabled={loading || field?.disabled} />
                          )}
                        </div>

                        <div
                          className="flex justify-between"
                          style={
                            isNumber(datum)
                              ? {
                                  // Total width minus direction button width split into thirds and centered with half input width
                                  marginLeft: 'calc((100% - 32px) / 3 - 2.5rem)',
                                  // Total width plus twice direction button width split into thirds and centered with half input width
                                  marginRight: 'calc((100% + 64px) / 3 - 2.5rem)',
                                }
                              : {
                                  marginLeft: 'calc(100% / 4 - 3rem)',
                                  marginRight: 'calc(100% / 4 - 3rem)',
                                }
                          }
                        >
                          <StatusMappingEntry
                            key={`${idx}.0`}
                            onFocus={() => setFocusedStatus(`${idx}.0`)}
                            onBlur={() => setFocusedStatus(undefined)}
                            focused={`${idx}.0` === focusedStatus}
                            onChange={e => {
                              let updatedData = set(
                                [...data],
                                `[${idx}].mapping.statusMapping.lowerThreshold`,
                                e,
                              );
                              if (_isBoolean(e)) {
                                updatedData = set(
                                  [...updatedData],
                                  `[${idx}].mapping.statusMapping.upperThreshold`,
                                  !e,
                                );
                              }
                              field?.onChange(updatedData);
                            }}
                            ref={el => (statusMappedInputs.current[`${idx}.0`] = el)}
                            disabled={loading || field?.disabled}
                            defaultValue={datum.mapping?.statusMapping?.lowerThreshold}
                            type={datum.mapping?.type}
                            position={0}
                          />
                          <StatusMappingEntry
                            key={`${idx}.1`}
                            onFocus={() => setFocusedStatus(`${idx}.1`)}
                            onBlur={() => setFocusedStatus(undefined)}
                            focused={`${idx}.1` === focusedStatus}
                            onChange={e =>
                              field?.onChange(
                                set([...data], `[${idx}].mapping.statusMapping.upperThreshold`, e),
                              )
                            }
                            ref={el => (statusMappedInputs.current[`${idx}.1`] = el)}
                            disabled={loading || field?.disabled}
                            defaultValue={datum.mapping?.statusMapping?.upperThreshold}
                            type={datum.mapping?.type}
                            position={1}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                  {idx + 1 === data?.length && (
                    <div className="flex justify-center pt-2">
                      <Button
                        variant="secondary"
                        size="xs"
                        onClick={() => {
                          if (field?.disabled) return;
                          field?.onChange([
                            ...(data || []),
                            {
                              _id: uniqueId(),
                              key: '',
                              value: '',
                              mapping: {
                                type: 'string',
                              },
                            },
                          ]);
                        }}
                        icon="Plus"
                      >
                        Add Item
                      </Button>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          );
        }}
        name="outputs"
        control={control}
        loading={loading}
        rules={{
          validate: v => {
            try {
              v?.filter(o => o?.toggled && o?.mapping?.statusMapping).forEach(o => {
                statusMappingSchema.parse(o.mapping.statusMapping);
              });
            } catch (err) {
              if (err instanceof ZodError) {
                const { lowerThreshold, upperThreshold, ...otherFields } = err.format();
                if (
                  (lowerThreshold?._errors || []).length > 0 ||
                  (upperThreshold?._errors || []).length > 0
                ) {
                  return 'Both thresholds are required for status mapping';
                }
                return Object.entries(otherFields)
                  .filter(([field]) => field !== '_errors')
                  .map(([field, { _errors }]) => `${field}: ${_errors.join(', ')}`)
                  .join('');
              }
              return err.message;
            }
            return true;
          },
        }}
      />
    </div>
  );
}
