import { isBoolean, isEmpty, isFinite, uniqueId } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@optra/kit';

import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import RawConfig from 'components/raw-config';
import SkillContainerFields from 'components/skill-container-fields';
import SkillEnvFields from 'components/skill-env-fields';
import SkillFormTabs, { isActiveTab } from 'components/skill-form-tabs';
import SkillMetaFields from 'components/skill-meta-fields';
import SkillPrivilegesFields, {
  FIELD_NAMES as PRIVILEGES_FIELD_NAMES,
} from 'components/skill-privileges-fields';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import cleanInputArray from 'lib/clean-input-array';
import { useLatestInternalSkillVersion } from 'queries';

export default function AdminCreateInternalSkillVersion() {
  const { skillId } = useParams();
  const [tab, setTab] = useState('container');
  const [error, setError] = useState();
  const form = useForm();
  const {
    reset,
    register,
    control,
    formState: { errors },
    handleSubmit: onSubmit,
  } = form;

  const passwordPlaceholder = password => (password ? '             ' : '');
  const navigate = useNavigate();
  const qc = q.useQueryClient();
  const createVersion = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createInternalSkillVersion($form: createInternalSkillVersionForm!) {
      internalSkillVersion: createInternalSkillVersion(form: $form) {
        id
      }
    }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['internalSkill', skillId] });
      navigate('../edit');
    },
    onError(err) {
      setError(err);
    },
  });
  const {
    data,
    error: fetchError,
    isLoading: dataLoading,
    isSuccess,
  } = useLatestInternalSkillVersion(skillId);

  useOnSuccess(
    () => {
      const internalSkillVersion = data.internalSkill?.versions?.data?.[0];
      reset({
        version: internalSkillVersion?.version,
        repository: {
          uri: internalSkillVersion?.repository?.uri,
          username: internalSkillVersion?.repository?.username,
          password: passwordPlaceholder(internalSkillVersion?.repository?.password),
        },
        env: Object.entries(internalSkillVersion?.env || {}).map(([key, value]) => ({
          _id: uniqueId(),
          key,
          value,
        })),
        devices: {
          sound: internalSkillVersion?.devices?.sound,
          hdmi: internalSkillVersion?.devices?.hdmi,
          cameras: isFinite(internalSkillVersion?.devices?.cameras),
        },
        removableMedia: internalSkillVersion?.removableMedia,
        hostNetworking: internalSkillVersion?.hostNetworking,
        gpio: internalSkillVersion?.gpio,
        cx2000IRRemote: internalSkillVersion?.cx2000IRRemote,
        usbSerialConverter: internalSkillVersion?.usbSerialConverter,
        cx2000VideoAcceleration: internalSkillVersion?.cx2000VideoAcceleration,
        led: internalSkillVersion?.led,
        dockerInDocker: internalSkillVersion?.dockerInDocker,
        privileged: internalSkillVersion?.privileged,
        mountVolumes: (internalSkillVersion?.mountVolumes || []).map(size => ({
          _id: uniqueId(),
          ...size,
        })),
        binds: (internalSkillVersion?.binds || []).map(bind => ({
          _id: uniqueId(),
          ...bind,
        })),
        capAdd: (internalSkillVersion?.capAdd || []).map(cap => ({
          _id: uniqueId(),
          ...cap,
        })),
        capDrop: (internalSkillVersion?.capDrop || []).map(cap => ({
          _id: uniqueId(),
          ...cap,
        })),
        addedDevice: (internalSkillVersion?.addedDevice || []).map(device => ({
          _id: uniqueId(),
          ...device,
        })),
        writableRootFS: internalSkillVersion?.writableRootFS,
        ...(!isEmpty(internalSkillVersion?.createOptions)
          ? { createOptions: JSON.stringify(internalSkillVersion?.createOptions, null, 2) }
          : { createOptions: '' }),
        storage: Object.entries(internalSkillVersion?.storage || {}).map(([key, value]) => ({
          _id: uniqueId(),
          key,
          value,
        })),
        hostName: internalSkillVersion?.hostName,
        endpointAliases: internalSkillVersion?.endpointAliases?.map(alias => ({
          _id: uniqueId(),
          alias,
        })),
        port: internalSkillVersion?.port,
        portBindings: (internalSkillVersion?.portBindings || []).map(p => ({
          _id: uniqueId(),
          ...p,
        })),
        tmpfs: (internalSkillVersion?.tmpfs || []).map(t => ({
          _id: uniqueId(),
          ...t,
        })),
        shmSize: internalSkillVersion?.shmSize,
        ...(!isEmpty(internalSkillVersion?.createOptions)
          ? { createOptions: JSON.stringify(internalSkillVersion?.createOptions, null, 2) }
          : { createOptions: '' }),
      });
    },
    { isSuccess },
    [data, reset],
  );

  const handleSubmit = onSubmit(form => {
    setError(null);
    const env = {};
    cleanInputArray(form.env).forEach(v => {
      env[v.key] = v.value;
    });
    const storage = {};
    cleanInputArray(form.storage).forEach(v => {
      storage[v.key] = v.value;
    });
    const endpointAliases = cleanInputArray(form.endpointAliases).map(v => v.alias);
    const port = parseInt(form.port);
    const shmSize = parseInt(form.shmSize);
    const writableRootFS = parseInt(form.writableRootFS);
    createVersion.mutate({
      internalSkillId: skillId,
      version: form.version,
      env,
      storage,
      hostName: !isEmpty(form.hostName) ? form.hostName : null,
      endpointAliases,
      portBindings: cleanInputArray(form.portBindings),
      tmpfs: cleanInputArray(form.tmpfs),
      shmSize: isFinite(shmSize) ? shmSize : null,
      port: isFinite(port) ? port : null,
      removableMedia: isBoolean(form.removableMedia) ? form.removableMedia : null,
      hostNetworking: isBoolean(form.hostNetworking) ? form.hostNetworking : null,
      gpio: isBoolean(form.gpio) ? form.gpio : null,
      cx2000IRRemote: isBoolean(form.cx2000IRRemote) ? form.cx2000IRRemote : null,
      usbSerialConverter: isBoolean(form?.usbSerialConverter) ? form?.usbSerialConverter : null,
      cx2000VideoAcceleration: isBoolean(form?.cx2000VideoAcceleration)
        ? form?.cx2000VideoAcceleration
        : null,
      led: isBoolean(form?.led) ? form.led : null,
      dockerInDocker: isBoolean(form?.dockerInDocker) ? form.dockerInDocker : null,
      privileged: isBoolean(form?.privileged) ? form?.privileged : null,
      mountVolumes: cleanInputArray(form.mountVolumes),
      binds: cleanInputArray(form.binds),
      capAdd: cleanInputArray(form?.capAdd),
      capDrop: cleanInputArray(form?.capDrop),
      addedDevice: cleanInputArray(form?.addedDevice),
      writableRootFS: isFinite(writableRootFS) ? writableRootFS : null,
      devices: {
        ...form.devices,
        // For now, we force 5 cameras. In the future, we may allow the user to choose a number
        cameras: form.devices.cameras ? 5 : null,
      },
      repository: {
        ...form.repository,
        password:
          // Only update the password if it has changed
          form.repository.password !==
          passwordPlaceholder(data?.internalSkill?.versions?.data?.[0]?.repository?.password)
            ? form.repository.password
            : undefined,
      },
      ...(!isEmpty(form.createOptions) ? { createOptions: JSON.parse(form.createOptions) } : {}),
    });
  });
  const loading = dataLoading || createVersion.isPending;
  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Create Internal Skill Version" icon="Stack" loading={loading} />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Create Version">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Version">
            {fetchError.message}
          </Message>
        )}
        <div>
          <SkillMetaFields
            hideName
            hideIcon
            loading={loading}
            register={register}
            control={control}
            errors={errors}
          />
        </div>

        <div>
          <SkillFormTabs tab={tab} onChange={setTab} tabs={['container', 'env', 'privileges']} />
        </div>
        <div>
          <ValidationError name="repository.uri" errors={errors} />
          {Object.keys(errors).some(e => Object.keys(PRIVILEGES_FIELD_NAMES).includes(e)) && (
            <ValidationError
              message={`Missing or invalid fields: ${Object.keys(errors || [])
                .map(e => PRIVILEGES_FIELD_NAMES[e])
                .join(', ')}`}
            />
          )}
        </div>

        <div>
          <SkillContainerFields
            visible={isActiveTab(tab, 'container')}
            loading={loading}
            register={register}
          />
          <SkillEnvFields visible={isActiveTab(tab, 'env')} loading={loading} control={control} />
          <SkillPrivilegesFields
            visible={isActiveTab(tab, 'privileges')}
            loading={loading}
            form={form}
          />
        </div>
        <RawConfig form={form} setError={setError} loading={loading} />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
