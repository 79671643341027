import cx from 'classnames';
import { isNil, pick } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { useRef, useState, useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { Icon, Tooltip, Spinner, Text, SearchField } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';
import Input from 'components/input';
import ManageOutputsLink from 'components/manage-outputs-link';
import Select from 'components/select';
import SkillIcon from 'components/skill-icon';
import useDebouncedState from 'hooks/use-debounced-state';
import useOutputs from 'queries/use-outputs';

const operatorOptions = {
  string: (
    <>
      <option value="$eq">is</option>
    </>
  ),
  boolean: (
    <>
      <option value="$eq">is</option>
    </>
  ),
  number: (
    <>
      <option value="$eq">=</option>
      <option value="$ne">!=</option>
      <option value="$gt">&gt;</option>
      <option value="$lt">&lt;</option>
      <option value="$gte">&gt;=</option>
      <option value="$lte">&lt;=</option>
    </>
  ),
};

const noTypeTooltip =
  'This output does not have a type associated with it. To filter by an output please specify a type in the outputs section when editing a skill';

export default function DevicesFilterOutputs(props) {
  const { onFilter, filter } = props;
  const outputsFilter = filter?.outputs ?? {};
  const [selectedOutputs, setSelectedOutputs] = useState(Object.keys(outputsFilter));

  const [$search, setSearch] = useState(''); // SearchField is already debounced and the value does not need to be controlled
  const { data, isLoading } = useOutputs({ filter: { $search } });
  const outputs = data;

  function toggleOutput(key) {
    setSelectedOutputs(prev => {
      if (prev.includes(key)) return prev.filter(o => o !== key);
      return [...prev, key];
    });
  }

  const lastToggledOutput = selectedOutputs.at(-1);
  useEffect(() => {
    if (!isEmpty(lastToggledOutput)) {
      document.getElementById(`outputs.${lastToggledOutput}`)?.focus();
    }
  }, [lastToggledOutput]);

  // NOTE: Synchronization paradigm to debounce values, this is still not great though, the logic feels like it belongs outside of this component
  const [internalFilter, setInternalFilter] = useDebouncedState(outputsFilter);
  const nextFilter = pick(internalFilter, selectedOutputs);
  const internalFilterDidChange = useRef(false);
  useDeepCompareEffect(() => {
    if (internalFilterDidChange.current) {
      // only apply filters when it's the side effect of an update
      internalFilterDidChange.current = false;
      onFilter(prev => ({
        ...prev,
        outputs: nextFilter,
      }));
    }
  }, [nextFilter]);

  useDeepCompareEffect(() => {
    // sync values, we purposefully retain the internalFilter values to more easily restore the filter when one is selected
    setSelectedOutputs(Object.keys(outputsFilter));
  }, [outputsFilter]);

  if (isLoading) {
    return (
      <DevicesFilterListItem image={<Spinner size="sm" color="gradient" />} label="Loading…" />
    );
  }

  return (
    <div className="space-y-4">
      <SearchField
        searching={false}
        onChange={setSearch}
        placeholder="Search Outputs…"
        className="m-2"
      />
      <ManageOutputsLink noRoutes />
      <div className="space-y-1">
        {outputs?.map(output => {
          const selected = selectedOutputs.includes(output.key);
          const [currentFilterOp, currentFilterValue] = Object.entries(
            internalFilter[output.key] ?? {},
          )?.[0] ?? ['$eq', null];
          const disabled = !output.mapping?.type;
          const active = !isNil(currentFilterValue) && selectedOutputs.includes(output.key);

          return (
            <div
              key={output.key}
              className={cx(
                'space-y-2 p-2',
                'rounded-md',
                disabled ? 'cursor-default' : 'cursor-pointer',
                selected
                  ? 'bg-gray-500/10 dark:bg-black-300/10'
                  : !disabled && 'dark:hover:bg-black-900/40',
              )}
            >
              <div
                className="flex items-center justify-between space-x-2"
                onClick={() => {
                  internalFilterDidChange.current = true;
                  toggleOutput(output.key);
                }}
              >
                <div className={cx('flex-0', disabled && 'opacity-50')}>
                  <SkillIcon
                    icon={output.skill?.icon}
                    iconUrl={output.skill?.iconUrl}
                    color={output.skill?.color}
                    size="xs"
                  />
                </div>
                <div className={cx('flex-1 flex flex-col items-start', disabled && 'opacity-50')}>
                  <Tooltip label={output.key} disabled={output.key <= 23}>
                    <Text size="sm" className="leading-tight">
                      {truncate(output.key, { length: 23 })}
                    </Text>
                  </Tooltip>
                  <Tooltip label={output.skill?.name} disabled={output.skill?.name <= 23}>
                    <Text size="xs" className="opacity-70 leading-tight">
                      {truncate(output.skill?.name, { length: 23 })}
                    </Text>
                  </Tooltip>
                </div>
                {disabled ? (
                  <div className="flex-0 self-start">
                    <Tooltip label={noTypeTooltip}>
                      <Icon name="Info" weight="duotone" size="sm" />
                    </Tooltip>
                  </div>
                ) : (
                  active && (
                    <div className="flex-0 self-start">
                      <Icon name="CheckCircle" size="sm" color="primary" />
                    </div>
                  )
                )}
              </div>
              {!disabled && (
                <div className={cx('flex', 'items-center', !selected && 'hidden')}>
                  <Select
                    className="basis-[30%] text-xs text-right pl-0 pr-8 font-bold rounded-l-md rounded-r-none border-r-0"
                    onChange={e => {
                      internalFilterDidChange.current = true;
                      setInternalFilter(prev => ({
                        ...prev,
                        [output.key]: { [e.target.value]: currentFilterValue },
                      }));
                    }}
                    defaultValue={currentFilterOp}
                  >
                    {operatorOptions[output.mapping.type]}
                  </Select>
                  {output.mapping.type === 'boolean' ? (
                    <Select
                      id={`outputs.${output.key}`}
                      className="basis-[70%] text-xs rounded-l-none rounded-r-md"
                      defaultValue={currentFilterValue ?? ''}
                      onChange={e => {
                        internalFilterDidChange.current = true;
                        setInternalFilter(prev => ({
                          ...prev,
                          [output.key]: { [currentFilterOp]: e.target.value },
                        }));
                      }}
                    >
                      <option value=""></option>
                      <option value={true}>true</option>
                      <option value={false}>false</option>
                    </Select>
                  ) : (
                    <Input
                      id={`outputs.${output.key}`}
                      type={output.mapping.type === 'number' ? 'number' : 'text'}
                      className="basis-[70%] text-xs rounded-l-none rounded-r-md"
                      defaultValue={currentFilterValue ?? ''}
                      placeholder="Enter value..."
                      onChange={e => {
                        internalFilterDidChange.current = true;
                        setInternalFilter(prev => ({
                          ...prev,
                          [output.key]: { [currentFilterOp]: e.target.value },
                        }));
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
