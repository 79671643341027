import { isEmpty } from 'lodash';

import { Avatar, Button, Spinner, PageWrapper, SearchField } from '@optra/kit';

import AdminUsersList from 'components/admin-users-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import OrganizationSelect from 'components/organization-select';
import PinPageButton from 'components/pin-page-button';
import { api, q } from 'config/api';
import { useImpersonation, useSearchParams } from 'hooks';
import useDebouncedState from 'hooks/use-debounced-state';
import { useCurrentUser } from 'queries';

function useAllUsers(variables) {
  return q.useInfiniteQuery({
    queryKey: ['users', 'admin', variables],
    queryFn({ pageParam }) {
      return api(
        `query allUsers($list: listFields) {
          list: userProfiles(list: $list) {
            data {
              id
              email
              name
              image
              auth0UserId
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            ...variables,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 60000,
  });
}

export default function AdminUsers() {
  const [searchParams, , createSearchParamSetter] = useSearchParams({ organizationId: 'all' });
  const { organizationId } = searchParams;
  const setOrganizationId = createSearchParamSetter('organizationId');

  const { stopImpersonation, assumedUser } = useImpersonation();
  const [currentUser] = useCurrentUser();

  const [userSearch, setUserSearch, currentUserSearch] = useDebouncedState('', {
    storeInRoute: 'search',
  });
  const AllUsers = useAllUsers({
    filter: {
      organizationId: organizationId === 'all' ? '' : organizationId,
      $search: userSearch || '',
    },
  });

  const users = AllUsers.data?.pages?.flatMap?.(page => page.list?.data);

  return (
    <PageWrapper
      icon="UserCircle"
      title="Users | Admin"
      heading="Users"
      inline={true}
      loading={AllUsers.isLoading}
      error={AllUsers.error}
      components={{
        actions: <PinPageButton />,
        controls: (
          <div className="flex items-center justify-between">
            <SearchField
              searching={AllUsers.isRefetching && !AllUsers.isFetchedAfterMount}
              value={currentUserSearch}
              onChange={setUserSearch}
              onClear={() => setUserSearch('')}
              placeholder="Search Users…"
              className="w-full max-w-sm"
            />

            <div className="flex flex-col md:flex-row items-center justify-start md:justify-end mt-4 md:mt-0 space-y-3 md:space-x-3 md:space-y-0 w-full">
              <OrganizationSelect
                onChange={setOrganizationId}
                value={organizationId}
                filters={{ all: true }}
              />
            </div>

            {!isEmpty(assumedUser.data?.id) && (
              <Button
                className="ml-2"
                variant="secondary"
                onClick={() => stopImpersonation.mutate()}
                disabled={stopImpersonation.isLoading}
              >
                <div className="flex items-center">
                  <Avatar
                    src={currentUser?.image}
                    alt={currentUser?.name}
                    size="xs"
                    className="mr-2"
                  />
                  {stopImpersonation.isLoading ? <Spinner size="xs" /> : 'Reset Identity'}
                </div>
              </Button>
            )}
          </div>
        ),
      }}
    >
      <AdminUsersList fetching={AllUsers.isLoading} error={AllUsers.error} users={users} />
      <IntersectionLoadMore
        onVisible={AllUsers.fetchNextPage}
        disabled={AllUsers.isFetchingNextPage || !AllUsers.hasNextPage}
      />
    </PageWrapper>
  );
}
