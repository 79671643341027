import { isFinite as _isFinite, groupBy, isNil, parseInt } from 'lodash';
import { useState, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button } from '@optra/kit';

import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import WorkflowSkillFormFields from 'components/workflow-skill-form-fields';
import { disabledReasonTooltipText } from 'components/workflow-skill-list-item';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus, useItemNotFound } from 'hooks';
import ItemDeleted from 'modals/item-deleted';
import ItemNotFound from 'modals/item-not-found';
import { useWorkflowSkill, useNextAvailablePort } from 'queries';

export default function EditWorkflowSkill() {
  const { workflowSkillId } = useParams();
  const { handleClose } = useModalContext();
  const { data: portData } = useNextAvailablePort();
  const { nextAvailablePort } = portData || {};
  const {
    handleSubmit: onSubmit,
    control,
    reset,
    register,
    watch,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      automaticPort: true,
      skillVersionId: '',
      exposeToNetwork: true,
    },
  });

  useInputFocus(setFocus, 'name');

  const { fields: inputFields } = useFieldArray({
    control,
    name: 'inputs',
  });
  const { fields: outputFields } = useFieldArray({
    control,
    name: 'outputs',
  });
  const automaticPortSelection = watch('automaticPort');
  const selectedSkillVersionId = watch('skillVersionId');

  const [error, setError] = useState();

  const {
    data,
    error: fetchError,
    isLoading,
    isSuccess,
  } = useWorkflowSkill(workflowSkillId, {
    enabled: !!workflowSkillId,
  });

  useOnSuccess(
    () => {
      const { workflowSkill } = data;
      const currentSkillVersion = workflowSkill?.version;
      const versionHasExposedPort = _isFinite(currentSkillVersion?.port);
      const wsInputsByKey = groupBy(workflowSkill?.inputs, 'key');
      const wsOutputsById = groupBy(workflowSkill?.outputs, 'id');

      reset({
        name: workflowSkill?.name,
        skillVersionId: currentSkillVersion?.id || '',
        inputs: currentSkillVersion?.inputs?.map((input, index) => ({
          key: input?.binding,
          value: wsInputsByKey[input?.binding]?.[0]?.value || '',
          label: currentSkillVersion?.inputs?.[index]?.label,
          type: input.type,
        })),
        outputs: currentSkillVersion?.outputs?.map(output => ({
          id: output?.id,
          enabled: !wsOutputsById[output?.id]?.[0]?.disabled,
          label: output?.label,
        })),
        port: versionHasExposedPort ? workflowSkill?.port : '',
        automaticPort: workflowSkill?.automaticPort || false,
        exposeToNetwork: isNil(workflowSkill?.exposeToNetwork)
          ? true
          : workflowSkill.exposeToNetwork,
      });
    },
    { isSuccess },
    [data, reset],
  );

  // TODO: Move this to a mutations hook
  const qc = q.useQueryClient();
  const updateWorkflowSkill = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflowSkill($form: updateWorkflowSkillForm!) {
          workflowSkill: updateWorkflowSkill(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r.workflowSkill?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowSkill', workflowSkillId] });
    },
  });

  const removeWorkflowSkill = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeWorkflowSkill($id: ID!) {
          workflowSkill: removeWorkflowSkill(id: $id) {
            id
            workflow {
              id
            }
          }
        }`,
        { id },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r.workflowSkill?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowSkill', workflowSkillId] });
    },
  });

  const selectedSkillVersion = useMemo(() => {
    return selectedSkillVersionId
      ? data?.workflowSkill?.skill?.versions?.data?.find(v => v.id === selectedSkillVersionId)
      : data?.workflowSkill?.version;
  }, [
    selectedSkillVersionId,
    data?.workflowSkill?.skill?.versions?.data,
    data?.workflowSkill?.version,
  ]);

  const handleSubmit = onSubmit(async form => {
    setError(null);
    const portInt = parseInt(form.port);

    await updateWorkflowSkill.mutateAsync(
      {
        ...form,
        port: !Number.isNaN(portInt) ? portInt : null,
        inputs: form.inputs?.map?.(i => ({
          key: i?.key,
          value: i?.value,
        })),
        outputs: form.outputs?.map?.(o => ({
          ...o,
          enabled: undefined,
          disabled: !o.enabled,
        })),
        id: workflowSkillId,
      },
      {
        onSuccess() {
          handleClose();
        },
        onError(err) {
          setError(err);
        },
      },
    );
  });

  const handleRemove = async event => {
    event.stopPropagation();
    event.preventDefault();
    if (window.confirm('Are you sure you want to remove this skill from its workflow?')) {
      await removeWorkflowSkill.mutateAsync(workflowSkillId, {
        onSuccess() {
          handleClose();
        },
      });
    }
  };

  const handleToggleAutomaticPortSelection = selectPortAutomatically => {
    // Reset
    setValue('automaticPort', selectPortAutomatically);

    // Only try to insert a new available port if it wasn't already automatically generated
    const doApplyNextAvailablePort =
      selectPortAutomatically &&
      (Number.isNaN(parseInt(data?.workflowSkill?.port)) || !data?.workflowSkill?.automaticPort);

    const newValue = doApplyNextAvailablePort ? nextAvailablePort : data?.workflowSkill?.port;

    setValue('port', _isFinite(newValue) ? newValue : '');

    if (!selectPortAutomatically) setFocus('port');
  };

  const loading = fetchError || isLoading || updateWorkflowSkill.isPending;

  const itemNotFound = useItemNotFound({
    fetching: isLoading,
    id: data?.workflowSkill?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={workflowSkillId} type="Skill" />;
  }

  if (data?.workflowSkill?.deleted) {
    return <ItemDeleted id={workflowSkillId} type="Skill" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Edit Skill"
        icon="Cube"
        loading={loading}
        renderActions={() => (
          <Button
            onClick={handleRemove}
            loading={removeWorkflowSkill.isPending}
            variant="secondary"
            size="xs"
            icon="Trash"
          >
            Remove
          </Button>
        )}
      />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Save Skill">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Skill">
            {fetchError.message}
          </Message>
        )}
        {data?.workflowSkill?.disabled?.disabled && (
          <Message variant="warning" title="Cannot Deploy">
            {disabledReasonTooltipText(data?.workflowSkill?.disabled?.reasons)}
          </Message>
        )}

        <WorkflowSkillFormFields
          errors={errors}
          inputFields={inputFields}
          loading={loading}
          outputFields={outputFields}
          skill={data?.workflowSkill?.skill}
          register={register}
          setValue={setValue}
          workflowSkill={data?.workflowSkill}
          versions={data?.workflowSkill?.skill?.versions?.data}
          selectedSkillVersion={selectedSkillVersion}
          showExposedUIPortField={
            _isFinite(selectedSkillVersion?.port) && !data?.workflowSkill?.skill?.isDockerCompose
          }
          automaticPortSelection={automaticPortSelection}
          onToggleAutomaticPortSelection={handleToggleAutomaticPortSelection}
        />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
