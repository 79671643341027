import { useState, useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { api, q } from 'config/api';

import useCurrentUser from './use-current-user';

export default function useUnseenNotifications() {
  const [currentUser] = useCurrentUser();
  const toastNotificationsEnabled = currentUser?.profile?.toastNotificationsEnabled ?? true;

  const [notifications, setNotifications] = useState([]);

  const [range, setRange] = useState({ after: Date.now(), before: Date.now() });
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRange(prevRange => ({ after: prevRange.before + 1, before: Date.now() }));
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const added = q.useQuery({
    retry: 0,
    enabled: toastNotificationsEnabled,
    queryKey: ['newNotification', range.after, range.before],
    queryFn: () =>
      api(
        `query recentNotifications($list: listFields) {
          list: notifications(list: $list) {
            data {
              id
              read
              referrerId
              type
              payload
              createdAt
            }
          }
        }`,
        {
          list: {
            filter: { createdAfter: range.after, createdBefore: range.before },
            sort: { direction: 'asc' },
          },
        },
      ),
  });

  useDeepCompareEffect(() => {
    if (added.data?.list?.data?.length > 0) {
      setNotifications(prev => [...prev, ...added.data.list.data]);
    }
  }, [setNotifications, added.data?.list?.data]);

  return [notifications, added];
}
