import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Card, Button } from '@optra/kit';

import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import WorkflowFormFields from 'components/workflow-form-fields';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus, useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useWorkflow } from 'queries';

export default function EditWorkflow() {
  const { workflowId } = useParams();
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    reset,
    setFocus,
  } = useForm({
    defaultValues: {
      name: '',
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');

  const { isLoading: fetching, isSuccess, error: fetchError, data } = useWorkflow(workflowId);

  const qc = q.useQueryClient();
  const updateWorkflow = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflow($form: updateWorkflowForm!) {
          updateWorkflow(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    updateWorkflow.mutate({
      id: workflowId,
      ...form,
    });
  });

  useOnSuccess(
    () => {
      reset({
        name: data?.workflow?.name,
      });
    },
    { isSuccess },
    [data, reset],
  );

  const loading = fetchError || fetching || updateWorkflow.isPending;

  const itemNotFound = useItemNotFound({
    fetching,
    id: data?.workflow?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={workflowId} type="Workflow" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Rename Workflow" icon="Pencil" loading={loading} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Save Workflow">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Workflow">
            {fetchError.message}
          </Message>
        )}
        <Card variant="secondary">
          <WorkflowFormFields register={register} errors={errors} loading={loading} />
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
