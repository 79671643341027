import { useLocation } from 'react-router-dom';

import { Card, Button, Heading, Icon, Text } from '@optra/kit';
import { creditsToFormattedString } from '@optra/shared/dist/utils/formatCredits';

export default function CreditsRequiredBanner(props) {
  const { required, balance, isFetching } = props;
  const { pathname } = useLocation();
  const hasSufficientCredits = balance >= required;

  return (
    <Card variant="secondary" className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center space-x-4">
        <div className="relative flex items-center justify-center w-12 h-12 rounded-full bg-light-bg-secondary dark:bg-dark-bg-secondary">
          <Icon name="Coins" size="xl" />
          <Icon
            name={hasSufficientCredits ? 'CheckCircle' : 'Warning'}
            color={hasSufficientCredits ? 'primary' : 'red'}
            weight="fill"
            className="absolute -bottom-1 -right-1"
          />
        </div>
        <Heading level={2}>{hasSufficientCredits ? 'Sufficient' : 'Insufficient'} Credits</Heading>
      </div>
      <div className="flex flex-row items-center space-x-8">
        <div className="flex flex-col items-start">
          <Text
            size="lg"
            variant="bold"
            color={hasSufficientCredits ? 'primary' : 'danger'}
            isLoading={isFetching}
          >
            {creditsToFormattedString(required)} Credits
          </Text>
          <Text>Required per Month</Text>
        </div>
        <div className="flex flex-col items-start">
          <Text size="lg" variant="bold">
            {creditsToFormattedString(balance)} Credits
          </Text>
          <Text>Balance</Text>
        </div>
      </div>
      <Button to={`/profile/add-credits?returnTo=${pathname}`} variant="tertiary" icon="Coins">
        Add Credits
      </Button>
    </Card>
  );
}
